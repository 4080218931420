import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../Shared/Tabs/TabPanel';
import DiariesList from './DiariesList';


import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import NoRights from '../../Shared/Layouts/NoRights';
import DiaryRecords from './DiaryRecords';
import StyledTabs from '../../Shared/Tabs/StyledTabs';
import { useMediaPredicate } from 'react-media-hook';
import CellTowerIcon from '@mui/icons-material/CellTower';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { StavarioModule } from '../../Shared/Layouts/StavarioModule';
import { DiaryTabsProvider, useDiaryTabsContext } from '../../../Contexts/Providers/DiaryTabsProvider';


interface DiariesTabsProps {
    selected?: number;
    fromExternal?: boolean;
    isSubdodavatel?: boolean;
}

export default function DiaryTabs(props: DiariesTabsProps) {
    const { t, i18n } = useTranslation();
    const { selected, fromExternal, isSubdodavatel } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const { permissions, refreshPermissions } = useDiaryTabsContext();
    // const [permissions, setPermissions] = useState<GetDiaryPermissionsResponse>();

    // const [permissions, setPermissions] = useState<GetDiaryPermissionsResponse>();
    const params = useParams();
    useEffect(() => {
        if (selected !== undefined)
            setSelectedTabIndex(selected);
    }, [selected]);
    const { setActiveModule } = useUserLayoutContext();
    const navigate = useNavigate();
    const redirect = (index: number) => {
        console.log('redirect', index, params['lang'], params['domain']);
        if (isSubdodavatel) {
            if (index === 0) {
                // setActiveModule(StavarioModule.DiaryRecordsList);
                console.log('1');
                navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/main/diaries');
                // history.pushState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diaries");
            }
            else if (index === 1) {
                console.log('2');
                // setActiveModule(StavarioModule.DiariesList);
                navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/main/diariesDiary');
                // history.pushState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diariesDiary");
            }
        }
        else {
            if (index === 0) {
                // setActiveModule(StavarioModule.DiaryRecordsList);
                // history.pushState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/userlayout/diaryvov");
                navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryvov');
            }
            else if (index === 1) {
                // setActiveModule(StavarioModule.DiariesList);

                // history.pushState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/userlayout/diaries");
                navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaries');
            }
            else if (index === 2) {
                navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/eyetower');
            }
        }
    }
    // const history = useHistory();
    useEffect(() => {

    }, [selectedTabIndex]);
    useEffect(() => {

        if (permissions === null) {
            refreshPermissions(isSubdodavatel === undefined ? false : isSubdodavatel);
        }
    }, [permissions, isSubdodavatel, refreshPermissions]);
    useEffect(() => {
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }



    }, [params, i18n]);

    // useEffect(() => {
    //     if (permissions === undefined)
    //         loadDiaryStore(isSubdodavatel === undefined ? false : isSubdodavatel).then((d) => {
    //             load(d);
    //         })
    // });


    const tabs = isSubdodavatel ? [
        {
            icon: <AutoStoriesIcon style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.dailyRecord')
        },
        {
            icon: <LibraryBooksIcon style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.diaryList')
        }
    ] : [
        {
            icon: <AutoStoriesIcon style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.dailyRecord')
        },
        {
            icon: <LibraryBooksIcon style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: t('diary.diaryList')
        },
        {
            icon: <CellTowerIcon style={{ width: biggerThan700 ? '16px' : '24px' }} />,
            label: 'EYETOWERS'
        }
    ];

    if (permissions)
        return (
            <Box sx={{ margin: 3 }}>
                <StyledTabs
                    tabs={tabs}
                    selectedTabIndex={selectedTabIndex}
                    onTabChange={(index) => redirect(index)}
                />
                <TabPanel value={selectedTabIndex} index={0}>
                    <div>
                        {permissions.canViewDiaryRecordsList && <DiaryRecords isSubdodavatel={isSubdodavatel} fromExternal={fromExternal} />}
                        {!permissions.canViewDiaryRecordsList && <NoRights title={t('noViewingPermissions')} />}

                    </div>

                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <div>
                        {permissions.canViewDiaryList && <DiariesList isSubdodavatel={isSubdodavatel} fromExternal={fromExternal} />}
                        {!permissions.canViewDiaryList && <NoRights title={t('noViewingPermissions')} />}

                    </div>
                </TabPanel>
                {!isSubdodavatel && <TabPanel value={selectedTabIndex} index={2}>
                    <Box sx={{ borderRadius: '10px', padding: '20px', backgroundColor: '#FFFFFF', boxShadow: 3 }}>
                        <iframe style={{ borderRadius: '10px' }} width={'100%'} height={document.body.scrollHeight / 1.4} src='https://portal.eyetowers.io/portal/login?embedded=true&mode=light' />
                    </Box>
                </TabPanel>}
            </Box>);
    else return <div>{t('loading...')}</div>;
}
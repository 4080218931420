import { Link, Tooltip } from '@mui/material';
import { ColumnDirective, ColumnsDirective, GridComponent, Group, Inject, Sort, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, FilterSettingsModel, RecordDoubleClickEventArgs } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InitSfGridLocale from '../../Localization/SfGridLanguageInitializer';
import withLoading, { WithLoadingProps } from '../Shared/Loader';
import './TodoScene.css';
import { TodoGanttDTO, TodoListDTO } from '../../ApiOld/ApiServerVov';
import { APITodoOldHelper } from '../../ApiOld/ApiToDoOldHelper';
import UpperMenu from '../Shared/Layouts/UpperMenu';
import { GridType } from '../../Api/UsersMicroserviceClient';
import TodoGanttOld from './ToDoOld/TodoGanttOld';
import { useNavigate, useParams } from 'react-router-dom';
import { TrashType } from '../Shared/Trash/TrashType';
import TrashGridDialog from '../Shared/Trash/TrashGridDialog';

InitSfGridLocale();


interface TodoScenePropsOld extends WithLoadingProps {
}

function TodoSceneOld(props: TodoScenePropsOld) {
    const { fireLoading, stopLoading } = props;
    const params = useParams();
    const [data, setData] = useState<TodoListDTO[]>();
    const [ganttData, setGanttData] = useState<TodoGanttDTO[]>();
    const [newOpen, setNewOpen] = useState(false);
    const [selectedView, setSelectedView] = useState<number>(1);
    const [selectedTodo, setSelectedTodo] = useState<TodoListDTO | undefined>();
    const [trashOpen, setTrashOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const { t, i18n } = useTranslation();
    const grid = useRef<GridComponent>(null);
    const navigate = useNavigate();

    useEffect(() => {
        /* if (params['todoId'])
            refreshData(parseInt(params['todoId']));
        else */
        refreshData();
    }, []);

    /* useEffect(() => {
        if (selectedTodo !== undefined) {
            setNewOpen(true);
        }
    }, [selectedTodo]); */

    /* useEffect(() => {
        if (params['todoId'])
            refreshData(parseInt(params['todoId']));
        else
            refreshData();
    }, [params]); */

    useEffect(() => {
        /* if (selectedView === 2)
            new APITodoOldHelper().GetGanttTodos().then((result) => {
                setGanttData(result);
            }); */
    }, [selectedView]);

    useEffect(() => {
        if (refresh) {
            refreshData();
            setRefresh(false);
        }
    }, [refresh]);
    let groupOptions = {

        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    function gridTemplate(props: TodoListDTO) {
        return (<div>
            <Link component="button" onClick={event => goToTodo(event, props)} >{props.name}</Link>
        </div>);
    }

    function gridAssigneesTemplate(props: TodoListDTO) {
        return (<div>
            <Tooltip title={props.assignees} >
                <div>
                    {props.assignees}
                </div>
            </Tooltip>
        </div>);
    }

    function gridStateTemplate(props: TodoListDTO) {
        return (<div>
            <Tooltip title={props.assignees} >
                <div>
                    {props.state === 0 && <div> {t('singleTodo.new')} </div>}
                    {props.state === 1 && <div> {t('singleTodo.active')} </div>}
                    {props.state === 2 && <div> {t('singleTodo.resolved')} </div>}
                </div>
            </Tooltip>
        </div>);
    }

    const goToTodo = (event: any, param: TodoListDTO) => {
        navigate('/' + params['lang']! + '/' + params['domain']! + '/todos/' + param.id);
    };
    function refreshData() {
        fireLoading!();
        new APITodoOldHelper().GetTodos().then((result) => {
            setData(result);
            /* if (selectedId && result) {
                setSelectedTodo(result.find(p => p.id === selectedId));
            } */
            console.log('loading top');
            stopLoading!();
        });
    }



    function toolbarClick1(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }
    function deleteTodos(event: any) {
        var rowsSelected = grid.current!.getSelectedRecords();
        let result: number[] = rowsSelected.map(a => (a as TodoListDTO).id!);
        new APITodoOldHelper().DeleteTodos(result).then(e => setRefresh(true));
    }
    function createTodo(event: any) {
        /* let todo: TodoListDTO = new TodoListDTO({ id: 0, name: "", state: 0 });
        setSelectedTodo(todo); */
        navigate('/' + params['lang']! + '/' + params['domain']! + '/todos/0');
    }

    function onViewChange(e: number) {
        setSelectedView(e);
    }

    function newProjectClosed() { setNewOpen(false); setSelectedTodo(undefined); }


    return (
        <div>
            <div style={{ display: "flex", flexDirection: 'column', margin: '24px' }}>
                <UpperMenu /*items={[{ data: 1, caption: t('todo.table') } , { data: 2, caption: "Gantt" }, { data: 3, caption: t('todo.dashboard') }, { data: 4, caption: t('todo.drawingMap') } ]}*/
                    moduleName={t('todo.tasks')}
                    canAdd
                    canDelete
                    canTrash
                    onCreate={createTodo}
                    onDelete={deleteTodos}
                    onThrashClicked={() => { setTrashOpen(true); }}
                    onViewChange={onViewChange}
                    grid={grid} gridId='grid-component-todo-old' menuName={t('View')}
                    gridType={GridType.Todos}
                    canChangeEyeView={false} canChangeGroup={selectedView === 1} canToolbarGrid={selectedView === 1}
                    canExcelExport={selectedView === 1}
                ></UpperMenu>
                <div>
                    {selectedView === 1 &&
                        <GridComponent ref={grid} id="grid-component-todo-old" allowSorting={true}
                            allowReordering={true} allowResizing={true} showColumnChooser={true}
                            allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                            locale={i18n.language} enablePersistence={false} recordDoubleClick={(e: RecordDoubleClickEventArgs) => { goToTodo(e, e.rowData as TodoListDTO) }}
                            filterSettings={filterOptions} toolbarClick={toolbarClick1}
                            //  columnDataStateChange={(e) => { console.log(e) }}
                            selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                            allowFiltering={true} groupSettings={groupOptions} dataSource={data}>
                            <ColumnsDirective>
                                <ColumnDirective type='checkbox' width='50' />
                                <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} showInColumnChooser={false} />
                                <ColumnDirective field='name' template={gridTemplate} headerText={t('Name')} width='120' />
                                <ColumnDirective field='buildingName' headerText={t('Projekt')} width='100' />
                                <ColumnDirective field='responsiblePerson' headerText={t('Responsibility')} width='100' />
                                <ColumnDirective field='creatorName' headerText={t('CreatedBy')} width='100' />
                                <ColumnDirective field='assignees' template={gridAssigneesTemplate} headerText={t('todo.assignees')} width='100' />
                                <ColumnDirective field='state' template={gridStateTemplate} width='80' headerText={t('state')} />
                                <ColumnDirective field='dateTimeCreated' width='160' type='dateTime' format='dd.MM.yyyy HH:mm' headerText={t('Created')} />
                                <ColumnDirective field='dateTimeDeadline' width='160' type='dateTime' format='dd.MM.yyyy HH:mm' headerText={t('Deadline')} />

                            </ColumnsDirective>
                            <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser]} />
                        </GridComponent>}
                    {selectedView === 2 && <TodoGanttOld tasks={data} tasksGantt={ganttData} />}
                    {/*  {selectedView === 3 && <KanbanStavarioOld onRefresh={refreshData} tasks={data} />}
                    {selectedView === 4 && data &&
                        <ImageMarkerMapOld tasks={data} onRefreshData={(e) => { setData(e) }}></ImageMarkerMapOld>} */}

                    <TrashGridDialog isSubdodavatel={false} trashType={TrashType.Todos} open={trashOpen} onClose={(e) => { console.log(e); setTrashOpen(false); if (e) refreshData(); }} />
                </div>
            </div>
        </div >
    );
};
export default withLoading(TodoSceneOld);


import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { StavarioModule } from '../../Components/Shared/Layouts/StavarioModule';
import { DiaryCommonVovClient, DiaryVovClient, GetDailyRecordsForUserDTO, GetDiariesForUserDTO, GetDiaryPermissionsResponse, SubcontractorVovClient } from '../../ApiOld/Api/ApiServerVov';
import { useApi } from '../../ApiOld/Api/useApi';
import { ApiDiaryBaseHelper } from '../../Api/ApiDiaryBaseHelper';
import { GetDiaryPermissionsInvestorResponse, GetSubcontractorDiaryPermissionsResponse } from '../../ApiOld/ApiServerVov';
import { APIVovInvestorHelper } from '../../ApiOld/ApiInvestorOldClient';


interface DiaryTabsContextProps {
    records: GetDailyRecordsForUserDTO[] | null;
    setRecords: React.Dispatch<React.SetStateAction<GetDailyRecordsForUserDTO[] | null>>;
    diaries: GetDiariesForUserDTO[] | null;
    setDiaries: React.Dispatch<React.SetStateAction<GetDiariesForUserDTO[] | null>>;
    refresh: () => void;
    refreshDailyRecords: () => void;
    refreshDiaries: () => void;
    loadingDiaries: boolean;
    loadingDailyRecords: boolean;
    permissions: GetDiaryPermissionsResponse | GetSubcontractorDiaryPermissionsResponse | null;
    setPermissions: React.Dispatch<React.SetStateAction<GetDiaryPermissionsResponse | GetSubcontractorDiaryPermissionsResponse | null>>;
    refreshPermissions: (isSubdodavatel: boolean) => void;

    investorPermissions: GetDiaryPermissionsInvestorResponse | null;
    setInvestorPermissions: React.Dispatch<React.SetStateAction<GetDiaryPermissionsInvestorResponse | null>>;
    refreshInvestorPermissions: () => void;
}

const DiaryTabsContext = createContext<DiaryTabsContextProps | undefined>(undefined);

export const DiaryTabsProvider: React.FC<React.PropsWithChildren<{ isInvestor: boolean, isSubdodavatel: boolean }>> = ({ children, isInvestor, isSubdodavatel }) => {
    const [records, setRecords] = useState<GetDailyRecordsForUserDTO[] | null>(null);
    const [diaries, setDiaries] = useState<GetDiariesForUserDTO[] | null>(null);
    const [loadingDiaries, setLoadingDiaries] = useState<boolean>(false);
    const [loadingDailyRecords, setLoadingDailyRecords] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<GetDiaryPermissionsResponse | GetSubcontractorDiaryPermissionsResponse | null>(null);
    const [investorPermissions, setInvestorPermissions] = useState<GetDiaryPermissionsInvestorResponse | null>(null);
    const { authorizedApi } = useApi(DiaryVovClient);
    const { authorizedSubdodavatelApi } = useApi(SubcontractorVovClient);
    useEffect(() => {
        if ((records === null || diaries === null) && !isInvestor)
            refresh();

    }, []);

    const refreshPermissions = useCallback(async () => {
        console.log('load permissions', isSubdodavatel);
        const perms = await new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetPermissions();
        setPermissions(perms);
    }, []);

    const refreshInvestorPermissions = useCallback(async () => {
        const perms = await new APIVovInvestorHelper().GetPermissions();
        setInvestorPermissions(perms);
    }, []);
    // useEffect(() => {
    //     refreshPermissions(false);
    // }, [refreshPermissions])
    const refreshDailyRecords = useCallback(async () => {
        setLoadingDailyRecords(true);
        setRecords(null);
        const response = isSubdodavatel ? await authorizedSubdodavatelApi.getDailyRecordsForSubcontractorUser(false) : await authorizedApi.getDailyRecordsForUser(false)
        // .then((response) => {
        setRecords(response.diaries ?? null);
        setLoadingDailyRecords(false);
        // });
    }, [authorizedApi, setLoadingDailyRecords, isSubdodavatel]);
    const refreshDiaries = useCallback(async () => {
        setLoadingDiaries(true);
        const response = isSubdodavatel ? await authorizedSubdodavatelApi.getDiariesForSubcontractorUser(false, false, 0) : await authorizedApi.getDiariesForUser(false, false, 0)
        // .then((response) => {
        setDiaries(response.diaries ?? null);
        setLoadingDiaries(false);
        // });
    }, [authorizedApi, setLoadingDiaries, isSubdodavatel]);
    const refresh = () => {
        refreshDailyRecords();
        refreshDiaries();
    };


    return (
        <DiaryTabsContext.Provider value={{
            records, setRecords, diaries, setDiaries, refresh, refreshDailyRecords, refreshDiaries, loadingDiaries, loadingDailyRecords, permissions, refreshPermissions, setPermissions, investorPermissions, setInvestorPermissions, refreshInvestorPermissions
        }}>
            {children}
        </DiaryTabsContext.Provider>
    );
};

export const useDiaryTabsContext = () => {
    const context = useContext(DiaryTabsContext);
    if (context === undefined) {
        throw new Error('useDiaryTabsContext must be used within a DiaryTabsProvider');
    }
    return context;
};
import { Button, IconButton, Tooltip, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WindowScroller } from 'react-virtualized';
import { useMediaPredicate } from 'react-media-hook';
import { AddAPhoto, AutoFixHighOutlined, Delete, Update } from '@mui/icons-material';
import ImageGallery from '../../Shared/FileViewers/ImageGallery';
import DeleteDialog from '../../Shared/Layouts/DeleteDialog';
import { ImageDiaryDTO } from '../../../ApiOld/ApiServerVov';
import ts from 'typescript';
import i18n from '../../../Localization/i18nInitializer';

interface ImageWindowScrollerProps {
    width?: number;
    canDelete?: boolean;
    images: ImageDiaryDTO[];
    buildingId: number;

    /** Callback function (eg. Redux action-creator) responsible for loading the next page of items */
    onDelete?: (value: ImageDiaryDTO) => void;
    onNewRequest: (value: ImageDiaryDTO) => void;
}

export default function ImageWindowScroller(props: ImageWindowScrollerProps) {
    const { images, canDelete, onDelete, onNewRequest, buildingId } = props;
    const [selected, setSelected] = useState<number | undefined>();
    const [toDelete, setToDelete] = useState<ImageDiaryDTO | undefined>();
    const { t } = useTranslation();

    useEffect(() => {
        console.log(images.length);
    }, [images]);

    function handleDelete(image: ImageDiaryDTO) {
        if (image) {
            if (onDelete) onDelete(image);
        }
    }

    function handleNewRequestClick(item: ImageDiaryDTO) {
        setSelected(undefined);
        onNewRequest(item);
    }

    return (
        <div>
            <ImageGallery
                canSaveChanged={false}
                canCreateTask
                diaryImages={images}
                selected={selected}
                open={selected !== undefined}
                buildingId={buildingId}
                onClose={() => { setSelected(undefined); }}
                newRequestButton={true}
                onNewRequest={handleNewRequestClick}
            />
            <WindowScroller>
                {({ height, isScrolling, onChildScroll, scrollTop, registerChild, width }) => (
                    //@ts-ignore
                    <div ref={registerChild}>
                        <Grid container spacing={2} style={{ padding: '16px' }}>
                            {images.map((image, index) => (
                                <Grid item key={index} xs={12} sm={12} md={12} lg={6} xl={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div
                                        className='parent'
                                        id='parent'
                                        style={{
                                            padding: '4px',
                                            position: 'relative'
                                        }}
                                    >
                                        {canDelete && (
                                            <Tooltip title={t('removeImage')}>
                                                <IconButton
                                                    onClick={() => setToDelete(image)}
                                                    className='hidden-child'
                                                    color='secondary'
                                                    sx={{ position: 'absolute', top: '8px', right: '5px' }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        <img
                                            onClick={() => setSelected(images.findIndex(x => x.url === image.url))}
                                            src={image.url}
                                            height={260}
                                            width={280}
                                            loading='lazy'
                                            style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '10px' }}
                                        />
                                        <Grid container spacing={1} style={{ padding: '4px' }}>
                                            <Grid item xs={6}>
                                                <Typography variant='caption'>{image.created ? image.created.toLocaleDateString(i18n.language) : ''}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant='caption'>{image.creator ? image.creator : ''}</Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                )}
            </WindowScroller>

            <DeleteDialog data={toDelete} onDeleteAgree={(data) => {
                handleDelete(data);
                setToDelete(undefined);
            }} onClose={() => {
                setToDelete(undefined);
            }} open={toDelete !== undefined} />
        </div>
    );
}
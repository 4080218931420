import { useCallback, useEffect, useState } from "react";
import { useUserLayoutContext } from "../../../Contexts/Providers/UserLayoutMenuProvider";
import { StavarioModule } from "../../Shared/Layouts/StavarioModule";
import { AccordionDetails, Box, Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Stack, styled, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import BuildingSelect from "../../Shared/BuildingSelect";
import DiarySelect from "./Widgets/DiarySelect";
import { DatePicker, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import LocalizationProviderWithLanguages from "../../Shared/Localization/LocalizationProviderWithLanguages";
import { useTranslation } from "react-i18next";
import { HtmlEditor, Image as RTImage, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, ToolbarSettingsModel } from '@syncfusion/ej2-react-richtexteditor';
import { CreateDailyRecordRequest, DiaryCommonVovClient, DiaryVovClient, GetMachinesForRecordTableDTO, GetMaterialsForRecordTableDTO, GetWeatherForRecordDTO, GetWorkerForRecordDTO, MobileDiaryRecordDTO, SaveMobileDiaryRecordRequest } from "../../../ApiOld/Api/ApiServerVov";
import { useApi } from "../../../ApiOld/Api/useApi";
import { DiaryTimelineModel } from "./Models/DiaryTimelineModel";
import moment from "moment";
import { Dayjs } from "dayjs";
import { Accordion, AccordionSummary } from "./Styles/AccordionStyles";
import { AddCircleOutline, Check, Cloud, Construction, Engineering, LocalShipping, NotInterested, QuestionMark, Save, Widgets, Lock } from "@mui/icons-material";
import noDiaryPic from '../../../Assets/No_data.jpg';
import { useDiaryTabsContext } from "../../../Contexts/Providers/DiaryTabsProvider";
import DiaryMobileList from "./DiaryMobileList";
import AddIcon from '@mui/icons-material/Add';
import DiaryWeatherInputDialog from "./Widgets/DiaryWeatherInputDialog";
import EmployeesWithGroupsDialog from "../Employees/EmployeesWithGroupsChooseDialog";
import { EmployeeGroupDTO, EmployeeInGroupDTO } from "../../../ApiOld/ApiServerVov";
import { ApiDiaryBaseHelper } from "../../../Api/ApiDiaryBaseHelper";
import WealthForDiaryDialog from "../Wealth/WealthForDiaryDialog";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router";
import ComputerIcon from '@mui/icons-material/Computer';
import { useMediaPredicate } from "react-media-hook";

export default function DiaryMainSceneMobile() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const { setActiveModule, setMenuButtons, setBackMenuFunction } = useUserLayoutContext();
    const { permissions, refreshPermissions } = useDiaryTabsContext();
    const { authorizedApi } = useApi(DiaryCommonVovClient);
    const { authorizedApi: authorizedApi2 } = useApi(DiaryVovClient);
    const [diaryId, setDiaryId] = useState<number | null>(null);
    const [diaryName, setDiaryName] = useState<string | undefined>();
    const [buildingId, setBuildingId] = useState<number | null>(null);
    const [buildingName, setBuildingName] = useState<string | undefined>();
    const [tempDate, setTempDate] = useState<Date | null>(null);
    const [date, setDate] = useState<Date | null>(null);
    const [records, setRecords] = useState<DiaryTimelineModel[]>([]);
    const [buildingDiaryRecord, setBuildingDiaryRecord] = useState<MobileDiaryRecordDTO | undefined>();
    const [saving, setSaving] = useState(false);
    const [weatherInputDialogOpen, setWeatherInputDialogOpen] = useState(false);
    const [workersDialogOpen, setWorkersDialogOpen] = useState(false);
    const [machinesDialogOpen, setMachinesDialogOpen] = useState(false);
    const [loadingDiaryRecord, setLoadingDiaryRecord] = useState(false);
    const [weatherMenuAnchor, setWeatherMenuAnchor] = useState<null | HTMLElement>(null);
    const [workersMenuAnchor, setWorkersMenuAnchor] = useState<HTMLElement | null>(null);
    const [materialsMenuAnchor, setMaterialsMenuAnchor] = useState<HTMLElement | null>(null);
    const [machinesMenuAnchor, setMachinesMenuAnchor] = useState<HTMLElement | null>(null);
    const [expandedAccordions, setExpandedAccordions] = useState<{ [key: string]: boolean }>({
        completedWorks: true,
        weather: false,
        workers: false,
        materials: false,
    });

    useEffect(() => {
        const storedBuildingId = localStorage.getItem('buildingId');
        const storedDiaryId = localStorage.getItem('diaryId');
        const storedDate = localStorage.getItem('date');
        if (storedBuildingId) {
            setBuildingId(Number(storedBuildingId));
        }
        if (storedDiaryId) {
            setDiaryId(Number(storedDiaryId));
        }
        if (storedDate) {
            setDate(new Date(storedDate));
            setTempDate(new Date(storedDate));
        }
    }, []);

    const handleAccordionChange = (accordionKey: string, isExpanded: boolean) => {
        setExpandedAccordions((prev) => ({
            ...prev,
            [accordionKey]: isExpanded,
        }));
    };

    const loadDiaryRecord = useCallback(async () => {
        if (diaryId && date) {
            setLoadingDiaryRecord(true);
            await authorizedApi.getMobileDiaryRecord(diaryId, date)
                .then((d) => {
                    setBuildingDiaryRecord(d.diaryRecord);
                    setLoadingDiaryRecord(false);
                })
                .catch((e) => {
                    setLoadingDiaryRecord(false);
                });
        }
    }, [authorizedApi, date, diaryId]);

    const saveDiaryRecord = useCallback(async () => {
        setSaving(true);
        try {
            await authorizedApi.saveMobileDiaryRecord(new SaveMobileDiaryRecordRequest({ diaryRecord: buildingDiaryRecord as MobileDiaryRecordDTO })).then(() => {
                loadDiaryRecord();
            }).finally(() => {
                setSaving(false);
            });
        }
        catch (e) {
            setSaving(false);
            console.error(e);
        }
    }, [authorizedApi, buildingDiaryRecord, loadDiaryRecord]);

    useEffect(() => {
        if (refreshPermissions && !permissions)
            refreshPermissions(false);
        setBackMenuFunction(undefined);
        setMenuButtons(
            <Stack spacing={2} mr={2} direction={'row'} alignItems='center'>
                <Tooltip title={t('SwitchView')} enterTouchDelay={0}>
                    <Button size="small" variant="contained" onClick={() => navigate(`/${i18n.language}/${params['domain']!}/userlayout/diary/diaryvov`)}><ComputerIcon /></Button>
                </Tooltip>
                {(permissions && permissions.canEditDiaryRecord && !buildingDiaryRecord?.isLocked && !buildingDiaryRecord?.isReadOnly) &&
                    <LoadingButton variant="contained" size="small" startIcon={<Save />} loading={saving}
                        onClick={() => {
                            saveDiaryRecord();
                        }}
                    >{t('save')}</LoadingButton>
                }
                {(!buildingDiaryRecord?.isLocked && (permissions === undefined || permissions?.canEditDiaryRecord === undefined || permissions.canEditDiaryRecord === false)) || (buildingDiaryRecord?.isReadOnly && <NotInterested style={{ width: '18px', height: '18px', marginTop: '-4px' }} />)}
                {buildingDiaryRecord?.isLocked && <Lock sx={{ marginTop: '4px' }} />}
            </Stack>
        );
        setActiveModule(StavarioModule.DiaryRecordDetail);
    }, [setActiveModule, refreshPermissions, permissions, setMenuButtons, buildingName, diaryName, buildingDiaryRecord?.isLocked, buildingDiaryRecord?.isReadOnly, t, saving, saveDiaryRecord, navigate, i18n.language, params, setBackMenuFunction]);

    var toolbarSettings: ToolbarSettingsModel = {
        items: [
        ],
        enable: false
    }

    interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
        isFilled: boolean;
    }
    const CustomPickersDay = styled(PickersDay, {
        shouldForwardProp: (prop) =>
            prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
    })<CustomPickerDayProps>(({ theme, isFilled }) => ({
        ...(isFilled && {
            borderRadius: '50%',
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.dark,
            },
        }),
        ...(!isFilled && {
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.dark,
            },
        }),
    })) as React.ComponentType<CustomPickerDayProps>;

    const renderWeekPickerDay = (
        date: Dayjs,
        selectedDates: Array<Dayjs | null>,
        pickersDayProps: PickersDayProps<Dayjs>,
    ) => {
        var item = records.find(x => x.date === date.format('MM-DD-YYYY'));
        if (item === undefined) {
            return <PickersDay day={pickersDayProps.day}
                onDaySelect={pickersDayProps.onDaySelect} outsideCurrentMonth={pickersDayProps.outsideCurrentMonth} />;
        }
        else if (item.recordId === undefined) {
            return <PickersDay {...pickersDayProps} />;
        }

        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                isFilled={item.isFilled}
            />
        );
    };

    const loadRecords = useCallback(async () => {
        if (diaryId)
            await authorizedApi.getDailyRecordsForUserAndDiary(diaryId).then((d) => {
                if (d.viewerRecords !== undefined && d.viewerRecords !== null) {
                    var newRecords: DiaryTimelineModel[] = [];
                    d.viewerRecords.reverse().forEach((element: any) => {
                        newRecords.push({
                            date: moment(element.date).format('MM-DD-YYYY'), dateOrigin: element.date, isFilled: element.isFilled,
                            hasCompletedWorks: element.hasCompletedWorksRecords, hasPhotos: element.hasPhotos, hasWeathers: element.hasWeatherRecords,
                            hasWorkers: element.hasWorkerRecords, signatureType: element.signatureType, recordId: element.id, isLocked: element.isLocked
                        });
                    });
                    setRecords(newRecords);
                }
            });
    }, [diaryId, authorizedApi]);

    useEffect(() => {
        if (diaryId)
            loadRecords();
    }, [diaryId, loadRecords]);

    useEffect(() => {
        if (diaryId && date)
            loadDiaryRecord();
        else setBuildingDiaryRecord(undefined);
    }, [diaryId, date, loadDiaryRecord]);

    return (
        <Box sx={{ m: 3, background: 'white', boxShadow: 3, p: 3, borderRadius: 3 }}>
            <Box>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        <BuildingSelect
                            label={t('Projekt')}
                            selectedProjectId={buildingId}
                            onChange={(e) => {
                                if (e?.id) {
                                    setBuildingName(e?.name);
                                    setBuildingId(e?.id);
                                    localStorage.setItem('buildingId', e?.id.toString());
                                }
                                else {
                                    setBuildingName(undefined);
                                    setBuildingId(null);
                                    localStorage.setItem('buildingId', '');
                                }
                                setDiaryName(undefined);
                                setDiaryId(null);
                                setDate(null);
                                setTempDate(null);
                                localStorage.setItem('diaryId', '');
                                localStorage.setItem('date', '');
                            }} />
                    </Grid>
                    <Grid item xs={12}>
                        <DiarySelect
                            disabled={!buildingId}
                            buildingId={buildingId ?? undefined}
                            selectedDiaryId={diaryId}
                            onChange={(e) => {
                                if (e?.id) {
                                    setDiaryName(e.name);
                                    setDiaryId(e?.id);
                                    localStorage.setItem('diaryId', e?.id.toString());
                                }
                                else {
                                    setDiaryName(undefined);
                                    setDiaryId(null);
                                    localStorage.setItem('diaryId', '');
                                }
                                setDate(null);
                                setTempDate(null);
                                localStorage.setItem('date', '');
                            }} />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProviderWithLanguages>
                            <DatePicker
                                disabled={!diaryId && !buildingId}
                                renderDay={renderWeekPickerDay}
                                value={tempDate}
                                onAccept={(e) => {
                                    setDate(e?.toDate() ?? null);
                                    localStorage.setItem('date', e?.toDate()?.toString() ?? '');
                                }}
                                onChange={(e) => { setTempDate(e?.toDate() ?? null); }}
                                renderInput={(params) =>
                                    <TextField
                                        placeholder={t('Date')}
                                        fullWidth
                                        size='small'
                                        {...params}
                                        style={{ backgroundColor: '#FFFFFF', float: 'right' }} />} />
                        </LocalizationProviderWithLanguages>
                    </Grid>
                </Grid>
                {(!buildingId || !diaryId || !date) ? <></> : (
                    loadingDiaryRecord ? <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box> :
                        buildingDiaryRecord && buildingDiaryRecord.id ? (
                            <Box>
                                {date && <DiaryWeatherInputDialog diaryRecordId={buildingDiaryRecord.id}
                                    open={weatherInputDialogOpen} onClose={() => { setWeatherInputDialogOpen(false); loadDiaryRecord() }} date={date} />}
                                <EmployeesWithGroupsDialog
                                    buildingName={buildingName}
                                    date={date !== undefined ? date.toLocaleDateString(i18n.language) : ''}
                                    open={workersDialogOpen}
                                    recordId={buildingDiaryRecord.id}
                                    onClose={(emps) => {
                                        if (emps && buildingDiaryRecord.id) {
                                            let array = (emps as EmployeeGroupDTO[]);
                                            let newWorkers = array.map((e) => { if (e.employees) return e.employees.filter(x => x.isSelected !== undefined && x.isSelected !== false).map((emp) => { return emp }) });
                                            let newWorkers2 = newWorkers.flat().filter(x => x !== undefined).map((e) => { return e as EmployeeInGroupDTO });
                                            new ApiDiaryBaseHelper(false, false).AddEmpsToDiary(buildingDiaryRecord.id, newWorkers2).then((r) => {
                                                if (r) loadDiaryRecord();
                                                setWorkersDialogOpen(false);
                                            });
                                        }
                                        else setWorkersDialogOpen(false);
                                    }}
                                />
                                <WealthForDiaryDialog
                                    open={machinesDialogOpen}
                                    recordId={buildingDiaryRecord.id}
                                    onClose={(wealthsForDiaryDialog) => {
                                        if (wealthsForDiaryDialog) loadDiaryRecord();
                                        setMachinesDialogOpen(false);
                                    }}
                                />
                                <Accordion sx={{ margin: 0 }} className='expander' expanded={expandedAccordions.completedWorks} onChange={(e, ex) => { handleAccordionChange("completedWorks", ex) }}>
                                    <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel2d-content" id="panel2d-header">
                                        <Construction color='secondary' className='accordion-icon' />
                                        <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.completedWorks')}</Typography>
                                        {(buildingDiaryRecord?.completedWorks === undefined || buildingDiaryRecord.completedWorks === '') && <QuestionMark color='disabled' sx={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} />}
                                        {(buildingDiaryRecord?.completedWorks !== undefined && buildingDiaryRecord?.completedWorks !== '') && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <RichTextEditorComponent toolbarSettings={toolbarSettings} saveInterval={100} locale={i18n.language} value={buildingDiaryRecord?.completedWorks}
                                            change={(e) => {
                                                setBuildingDiaryRecord((prev) => {
                                                    if (prev) {
                                                        return new MobileDiaryRecordDTO({
                                                            ...prev,
                                                            completedWorks: e.value,
                                                        });
                                                    }
                                                    return prev;
                                                })
                                            }}
                                        >
                                            <Inject services={[Toolbar, RTImage, Link, HtmlEditor, QuickToolbar]} />
                                        </RichTextEditorComponent>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ margin: 0 }} className='expander' expanded={expandedAccordions.weather} onChange={(e, ex) => { handleAccordionChange("weather", ex) }}>
                                    <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel1d-content" id="panel1d-header">
                                        <Cloud color='secondary' className='accordion-icon' />
                                        <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.weather')}</Typography>
                                        {buildingDiaryRecord?.weathers && <Typography color={theme.palette.text.primary} sx={{ marginLeft: '10px' }} fontWeight='bold'>{' (' + buildingDiaryRecord.weathers.filter(x => !x.isDeleted).length + ')'}</Typography>}
                                        <IconButton
                                            sx={{ ml: 'auto' }}
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setWeatherMenuAnchor(e.currentTarget)
                                            }}
                                        >
                                            <AddIcon color="primary" />
                                        </IconButton>
                                        <Menu
                                            anchorEl={weatherMenuAnchor}
                                            open={Boolean(weatherMenuAnchor)}
                                            onClose={() => { setWeatherMenuAnchor(null) }}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        >
                                            <MenuItem onClick={(e) => {
                                                buildingDiaryRecord?.weathers?.push(new GetWeatherForRecordDTO({
                                                    id: -1,
                                                    description: '',
                                                    time: new Date(),
                                                    temperature: '',
                                                    humidity: '',
                                                    precipitation: '',
                                                    wind: '',
                                                    isShared: false,
                                                    isDeleted: false
                                                }));
                                                setWeatherMenuAnchor(null);
                                                e.stopPropagation();
                                                setExpandedAccordions((prev) => ({ ...prev, weather: true }));
                                            }}
                                            >{t('Add')}</MenuItem>
                                            <MenuItem onClick={(e) => {
                                                setWeatherInputDialogOpen(true);
                                                setWeatherMenuAnchor(null);
                                                e.stopPropagation();
                                                setExpandedAccordions((prev) => ({ ...prev, weather: true }));
                                            }}
                                            >{t('loadFromSystem')}</MenuItem>
                                        </Menu>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DiaryMobileList
                                            data={(buildingDiaryRecord?.weathers ?? []).map((weather) => ({
                                                ...weather,
                                                id: weather.id ?? -1,
                                                isDeleted: weather.isDeleted,
                                            }))}
                                            fields={[
                                                { key: 'description', label: 'Description', type: 'text', isEditable: true },
                                                { key: 'time', label: 'diary.time', type: 'time', isEditable: true },
                                                { key: 'isShared', label: 'diary.sharedEntry', type: 'boolean', isEditable: true },
                                                { key: 'temperature', label: 'diary.temperature', type: 'number', isEditable: true },
                                                { key: 'humidity', label: 'diary.humidity', type: 'number', isEditable: true },
                                                { key: 'precipitation', label: 'diary.precipitation', type: 'number', isEditable: true },
                                                { key: 'wind', label: 'diary.wind', type: 'number', isEditable: true },
                                                { key: 'authorName', label: 'CreatedBy', type: 'text', isEditable: false },
                                            ]}
                                            headerFields={['description', 'time']}
                                            onDataChange={(data) => {
                                                setBuildingDiaryRecord((prev) => {
                                                    if (prev) {
                                                        return new MobileDiaryRecordDTO({
                                                            ...prev,
                                                            weathers: data.map((item) => new GetWeatherForRecordDTO({
                                                                id: item.id,
                                                                description: item.description,
                                                                time: item.time,
                                                                temperature: (item.temperature ?? '').toString(),
                                                                humidity: (item.humidity ?? '').toString(),
                                                                precipitation: (item.precipitation ?? '').toString(),
                                                                wind: (item.wind ?? '').toString(),
                                                                isShared: item.isShared,
                                                                authorName: item.authorName,
                                                                authorId: item.authorId,
                                                                created: item.created,
                                                                lastUpdate: item.lastUpdate,
                                                                selected: item.selected,
                                                                isDeleted: item.isDeleted,
                                                            })),
                                                        });
                                                    }
                                                    return prev;
                                                });
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ margin: '0px' }} className='expander' expanded={expandedAccordions.workers} onChange={(e, ex) => { handleAccordionChange("workers", ex) }}>
                                    <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel2d-content" id="panel2d-header">
                                        <Engineering color='secondary' className='accordion-icon' />
                                        <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('Workers')}</Typography>
                                        {buildingDiaryRecord?.workers && <Typography color={theme.palette.text.primary} sx={{ marginLeft: '10px' }} fontWeight='bold'>{' (' + buildingDiaryRecord.workers.filter(x => !x.isDeleted).length + ')'}</Typography>}
                                        <IconButton
                                            sx={{ ml: 'auto' }}
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setWorkersMenuAnchor(e.currentTarget)
                                            }}
                                        >
                                            <AddIcon color="primary" />
                                        </IconButton>
                                        <Menu
                                            anchorEl={workersMenuAnchor}
                                            open={Boolean(workersMenuAnchor)}
                                            onClose={() => setWorkersMenuAnchor(null)}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        >
                                            <MenuItem onClick={(e) => {
                                                buildingDiaryRecord?.workers?.push(new GetWorkerForRecordDTO({
                                                    id: -1,
                                                    name: '',
                                                    workHours: 0,
                                                    isShared: false,
                                                    authorId: 0,
                                                    authorName: '',
                                                    created: new Date(),
                                                    lastUpdate: new Date(),
                                                    isDeleted: false
                                                }));
                                                setWorkersMenuAnchor(null);
                                                e.stopPropagation();
                                                setExpandedAccordions((prev) => ({ ...prev, workers: true }));
                                            }}>{t('Add')}</MenuItem>
                                            <MenuItem onClick={(e) => {
                                                setWorkersDialogOpen(true);
                                                setWorkersMenuAnchor(null);
                                                e.stopPropagation();
                                                setExpandedAccordions((prev) => ({ ...prev, workers: true }));
                                            }}>{t('diary.AddWorkers')}</MenuItem>
                                        </Menu>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DiaryMobileList
                                            data={(buildingDiaryRecord?.workers ?? []).map((worker) => ({
                                                ...worker,
                                                id: worker.id ?? -1,
                                                isDeleted: worker.isDeleted,
                                            }))}
                                            fields={[
                                                { key: 'name', label: 'Name', type: 'text', isEditable: true },
                                                { key: 'workHours', label: 'WorkHours', type: 'number', isEditable: true },
                                                { key: 'isShared', label: 'diary.sharedEntry', type: 'boolean', isEditable: true },
                                                { key: 'authorName', label: 'CreatedBy', type: 'text', isEditable: false },
                                                { key: 'created', label: 'Created', type: 'datetime', isEditable: false },
                                                { key: 'lastUpdate', label: 'Updated', type: 'datetime', isEditable: false },
                                            ]}
                                            headerFields={['name']}
                                            onDataChange={(data) => {
                                                setBuildingDiaryRecord
                                                    ((prev) => {
                                                        if (prev) { }
                                                        return new MobileDiaryRecordDTO({
                                                            ...prev,
                                                            workers: data.map((item) => new GetWorkerForRecordDTO({
                                                                id: item.id,
                                                                name: item.name,
                                                                workHours: item.workHours,
                                                                isShared: item.isShared,
                                                                authorId: item.authorId,
                                                                authorName: item.authorName,
                                                                created: item.created,
                                                                lastUpdate: item.lastUpdate,
                                                                isDeleted: item.isDeleted,
                                                            })),
                                                        })
                                                    });
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion sx={{ margin: 0 }} className='expander' expanded={expandedAccordions.materials} onChange={(e, ex) => { handleAccordionChange("materials", ex) }}>
                                    <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel4d-content" id="panel4d-header">
                                        <Widgets color='secondary' className='accordion-icon' />
                                        <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('log.materials')}</Typography>
                                        {buildingDiaryRecord?.materials && <Typography color={theme.palette.text.primary} sx={{ marginLeft: '10px' }} fontWeight='bold'>{' (' + buildingDiaryRecord.materials.filter(x => !x.isDeleted).length + ')'}</Typography>}
                                        <IconButton
                                            sx={{ ml: 'auto' }}
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setMaterialsMenuAnchor(e.currentTarget);
                                            }}
                                        >
                                            <AddIcon color="primary" />
                                        </IconButton>
                                        <Menu
                                            anchorEl={materialsMenuAnchor}
                                            open={Boolean(materialsMenuAnchor)}
                                            onClose={() => setMaterialsMenuAnchor(null)}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        >
                                            <MenuItem onClick={(e) => {
                                                buildingDiaryRecord?.materials?.push(new GetMaterialsForRecordTableDTO({
                                                    id: -1,
                                                    name: '',
                                                    amount: 0,
                                                    unit: '',
                                                    isShared: false,
                                                    adminCreatorId: 0,
                                                    adminCreatorName: '',
                                                    created: new Date(),
                                                    lastUpdate: new Date(),
                                                    isDeleted: false
                                                }));
                                                setMaterialsMenuAnchor(null);
                                                e.stopPropagation();
                                                setExpandedAccordions((prev) => ({ ...prev, materials: true }));
                                            }}>{t('Add')}</MenuItem>
                                        </Menu>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DiaryMobileList
                                            data={(buildingDiaryRecord?.materials ?? []).map((material) => ({
                                                ...material,
                                                id: material.id ?? -1,
                                                isDeleted: material.isDeleted,
                                            }))}
                                            fields={[
                                                { key: 'name', label: 'Name', type: 'text', isEditable: true },
                                                { key: 'amount', label: 'Amount', type: 'number', isEditable: true },
                                                { key: 'unit', label: 'Unit', type: 'text', isEditable: true },
                                                { key: 'isShared', label: 'diary.sharedEntry', type: 'boolean', isEditable: true },
                                                { key: 'adminCreatorName', label: 'CreatedBy', type: 'text', isEditable: false },
                                                { key: 'created', label: 'Created', type: 'datetime', isEditable: false },
                                                { key: 'lastUpdate', label: 'Updated', type: 'datetime', isEditable: false },
                                            ]}
                                            headerFields={['name']}
                                            onDataChange={(data) => {
                                                setBuildingDiaryRecord((prev) => {
                                                    if (prev) {
                                                        return new MobileDiaryRecordDTO({
                                                            ...prev,
                                                            materials: data.map((item) => new GetMaterialsForRecordTableDTO({
                                                                id: item.id,
                                                                name: item.name,
                                                                amount: item.amount,
                                                                unit: item.unit,
                                                                isShared: item.isShared,
                                                                diaryDate: date,
                                                                adminCreatorId: item.adminCreatorId,
                                                                adminCreatorName: item.adminCreatorName,
                                                                created: item.created,
                                                                lastUpdate: item.lastUpdate,
                                                                isDeleted: item.isDeleted,
                                                                selected: item.selected,
                                                            })),
                                                        });
                                                    }
                                                    return prev;
                                                });
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion sx={{ margin: 0 }} className='expander' expanded={expandedAccordions.machines} onChange={(e, ex) => { handleAccordionChange("machines", ex) }}>
                                    <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel4d-content" id="panel4d-header">
                                        <LocalShipping color='secondary' className='accordion-icon' />
                                        <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.machines')}</Typography>
                                        {buildingDiaryRecord?.machines && <Typography color={theme.palette.text.primary} sx={{ marginLeft: '10px' }} fontWeight='bold'>{' (' + buildingDiaryRecord.machines.filter(x => !x.isDeleted).length + ')'}</Typography>}
                                        <IconButton
                                            sx={{ ml: 'auto' }}
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setMachinesMenuAnchor(e.currentTarget)
                                            }}
                                        >
                                            <AddIcon color="primary" />
                                        </IconButton>
                                        <Menu
                                            anchorEl={machinesMenuAnchor}
                                            open={Boolean(machinesMenuAnchor)}
                                            onClose={() => setMachinesMenuAnchor(null)}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        >
                                            <MenuItem onClick={(e) => {
                                                buildingDiaryRecord?.machines?.push(new GetMachinesForRecordTableDTO({
                                                    id: -1,
                                                    code: '',
                                                    name: '',
                                                    isShared: false,
                                                    created: new Date(),
                                                    isDeleted: false
                                                }));
                                                setMachinesMenuAnchor(null);
                                                e.stopPropagation();
                                                setExpandedAccordions((prev) => ({ ...prev, machines: true }));
                                            }}>{t('Add')}</MenuItem>
                                            <MenuItem onClick={(e) => {
                                                setMachinesDialogOpen(true); setMachinesMenuAnchor(null)
                                                e.stopPropagation();
                                                setExpandedAccordions((prev) => ({ ...prev, machines: true }));
                                            }}>{t('loadFromSystem')}</MenuItem>
                                        </Menu>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DiaryMobileList
                                            data={(buildingDiaryRecord?.machines ?? []).map((machine) => ({
                                                ...machine,
                                                id: machine.id ?? -1,
                                                isDeleted: machine.isDeleted,
                                            }))}
                                            fields={[
                                                { key: 'code', label: 'code', type: 'text', isEditable: true },
                                                { key: 'name', label: 'Name', type: 'text', isEditable: true },
                                                { key: 'isShared', label: 'diary.sharedEntry', type: 'boolean', isEditable: true },
                                                { key: 'created', label: 'Created', type: 'datetime', isEditable: false },
                                            ]}
                                            headerFields={['code', 'name']}
                                            onDataChange={(data) => {
                                                setBuildingDiaryRecord((prev) => {
                                                    if (prev) {
                                                        return new MobileDiaryRecordDTO({
                                                            ...prev,
                                                            machines: data.map((item) => new GetMachinesForRecordTableDTO({
                                                                id: item.id,
                                                                code: item.code,
                                                                name: item.name,
                                                                isShared: item.isShared,
                                                                created: item.created,
                                                                isDeleted: item.isDeleted,
                                                            })),
                                                        });
                                                    }
                                                    return prev;
                                                });
                                            }
                                            }
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        ) :
                            <Box>
                                <img
                                    src={noDiaryPic}
                                    width={'100%'}
                                    height={'100%'}
                                    alt={t('diary.noRecordForChosenDate')}
                                    style={{
                                        border: '2px solid',
                                        borderColor: '#C3C3C3',
                                        borderRadius: '16px',
                                    }}
                                />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: 16, my: 2, textAlign: 'center' }} variant="h6">
                                        {t('diary.noRecordForChosenDate')}
                                    </Typography>
                                    <Typography>
                                        {t('diary.site')}: <strong>{buildingName}</strong>
                                    </Typography>
                                    <Typography>
                                        {t('diary.diary')}: <strong>{diaryName}</strong>
                                    </Typography>
                                    <Typography>
                                        {t('log.date')}: <strong>{moment(date).format('DD.MM.YYYY')}</strong>
                                    </Typography>

                                    {permissions && permissions.canCreateDiaryRecord && <Button sx={{ margin: '20px' }} startIcon={<AddCircleOutline />} onClick={(e) => {
                                        if (diaryId && date)
                                            authorizedApi2.createDiaryRecord(new CreateDailyRecordRequest({ diaryId: diaryId, date: date })).then(() => {
                                                loadDiaryRecord();
                                                loadRecords();
                                            });

                                    }} variant='contained'>{t('Create')}</Button>}
                                </Box>
                            </Box>
                )}
            </Box>
        </Box>
    );
}
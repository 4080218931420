import { ColumnsDirective, GridComponent, Group, RecordDoubleClickEventArgs, Sort, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, ColumnDirective, Inject, FilterSettingsModel, Page, ExcelQueryCellInfoEventArgs, Column, GridModel, GridColumnModel, ColumnModel, TextAlign } from '@syncfusion/ej2-react-grids';
import * as React from 'react';
import { useRef, forwardRef } from 'react';
import UpperMenu from '../Layouts/UpperMenu';
import { useTranslation } from 'react-i18next';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { VovClient } from '../../../ApiOld/ApiServerVov';
import { useApi } from '../../../ApiOld/Api/useApi';
import delay from '../Functions/Delay';
import { ContentPasteOffSharp } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { compile } from '@syncfusion/ej2-base';
import { GridSortModel } from '@mui/x-data-grid';

// export interface IGridComponentStatefullProps<T> extends GridModel {
export interface IGridComponentStatefullProps<T> extends Omit<GridModel, 'columns'> {
    gridType: GridType;
    canAdd: boolean;
    children: React.ReactNode;
    id: string;
    style?: React.CSSProperties;
    data: T[];
    dataLoader?: () => Promise<T[]>;
    templateColumns?: { field: string, template: (props: any) => JSX.Element | undefined }[];
    columnsXX: {
        field: string, headerText: string, width?: string,
        minWidth?: string, template?: (props: any) => JSX.Element | undefined,
        visible?: boolean, allowFiltering?: boolean, type?: string, textAlign?: TextAlign,
        showInColumnChooser?: boolean
    }[],


}

const GridComponentStatefull = forwardRef(<T,>(props: IGridComponentStatefullProps<T>, ref: React.Ref<GridComponent>) => {
    const innerRef = useRef<GridComponent>(null);
    const [columnsLocal, setColumnsLocal] = React.useState<React.ReactNode | null>(null);
    const { t } = useTranslation();
    const isRestoringState = useRef(false);
    React.useImperativeHandle(ref, () => innerRef.current!);

    const saveGridSettings = () => {
        let persistedGridSettings;
        // if (isRestoringState.current) return;
        persistedGridSettings = JSON.parse(innerRef?.current!.getPersistData());
        console.log('persistedGridSettings', persistedGridSettings);
        var gridColumns = innerRef.current!.getColumns();
        persistedGridSettings.columns.forEach((persistedColumn) => {
            const column = gridColumns.find((col) => col.field === persistedColumn.field);
            if (column) {
                persistedColumn.headerText = column.headerText;
                persistedColumn.width = column.width;
                persistedColumn.template = column.template;
                persistedColumn.minWidth = column.minWidth;
                persistedColumn.visible = column.visible;
            }
        });
        window.localStorage.setItem('grid-component-projects_list_extended', JSON.stringify(persistedGridSettings));
        console.log('saving grid settings', persistedGridSettings);
    };
    const onceLoaded = useRef(false);
    React.useEffect(() => {
        if (onceLoaded.current) return;
        onceLoaded.current = true;
        const savedSettings = window.localStorage.getItem("grid-component-projects_list_extended");
        if (savedSettings) {
            isRestoringState.current = true;
            console.log('restoring grid settings', JSON.parse(savedSettings));
            const settings = JSON.parse(savedSettings);
            let columnsNew: {
                field: string, headerText: string, width?: string,
                minWidth?: string, template?: (props: any) => JSX.Element | undefined,
                visible?: boolean, allowFiltering?: boolean, type?: string, textAlign?: TextAlign,
                showInColumnChooser?: boolean
            }[] = [];
            for (let col of props.columnsXX) {

                let clNew = settings.columns.find((t: any) => t.field === col.field);

                if (clNew === undefined) {
                    columnsNew.push(col);
                }
                // else { columnsNew.push(col); }
            }
            for (let col of settings.columns) {
                let clNew = props.columnsXX.find((t: any) => t.field === col.field);
                if (clNew !== undefined) {
                    columnsNew.push(col);
                }
            }
            setColumnsLocal(<ColumnsDirective>
                {columnsNew.map((colInner, index) => (
                    <ColumnDirective key={colInner.field} {...colInner} width={colInner.width} template={props.templateColumns?.find(x => x.field === colInner.field)?.template} />
                ))}
            </ColumnsDirective>);
            delay(200).then(() => {
                innerRef.current!.sortSettings = settings.sortSettings;
                innerRef.current!.searchSettings = settings.searchSettings;
                innerRef.current!.groupSettings = settings.groupSettings;
                innerRef.current!.filterSettings = settings.filterSettings;
                delay(1000).then(() => {
                    isRestoringState.current = false;
                    // innerRef.current!.refreshColumns();
                });
            });

        }
        else setColumnsLocal(<ColumnsDirective>
            {/* <ColumnsDirective> */}
            {props.columnsXX.map((col, index) => (
                <ColumnDirective key={col.field} {...col} width={col.width} template={col.template} />
            ))}
            {/* </ColumnsDirective> */}
        </ColumnsDirective>);

    }, [props.columnsXX, props.templateColumns]);


    React.useEffect(() => {

        if (innerRef.current) {
            innerRef.current.dataSource = props.data ?? [];
            innerRef.current.refresh();
        }
    }, [props.data]);

    const [filtered, setFiltered] = React.useState(false);

    return (
        <div>
            <UpperMenu
                moduleName={t('buildings.projects')}
                gridType={props.gridType}
                canAdd={props.canAdd}
                filtered={filtered}
                canTrash
                onFilterCleared={() => { saveGridSettings(); }}
                grid={innerRef}
                gridId='grid-component-projects_list_extended'
                canChangeGroup
                canToolbarGrid
                menuName={t('View')}
                searchLabel={t('SearchInProjects')}
                canExcelExport
            />
            {columnsLocal && <GridComponent
                {...props}


                id={props.id}
                style={{ ...props.style, minHeight: '400px' }}
                ref={innerRef}
                resizeStop={(e) => {
                    if (!isRestoringState.current)
                        saveGridSettings();
                }}
                actionComplete={(e) => {
                    if (isRestoringState.current) return;
                    // console.log(e);
                    console.log('actionComplete', e);
                    if (e.requestType === 'columnstate' || e.requestType === 'sorting' || e.requestType === 'filtering' || e.requestType === 'paging' || e.requestType === 'reorder') {
                        saveGridSettings();
                        if (e.requestType === 'filtering') {
                            setFiltered(true);
                            delay(200).then(() => {
                                setFiltered(false);
                            });
                        }
                    }

                }}
            >
                {columnsLocal}
                {props.children}

            </GridComponent>}
        </div>
    );
}) as <T>(
    props: IGridComponentStatefullProps<T> & { ref?: React.Ref<GridComponent> }
) => JSX.Element;

export default GridComponentStatefull;
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageDiaryDTO, PromptDTO } from "../../../../../ApiOld/ApiServerVov";
import { Box, Button, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { ApiDiaryBaseHelper } from "../../../../../Api/ApiDiaryBaseHelper";
import { AddAPhoto, Help, Psychology } from "@mui/icons-material";
import GptImageChatScene from "../../../GptChat/GptImageChatScene";
import ChoosePhotoDialog from "../ChoosePhotoDialog";
import ChooseProjectPhotos from "../ChooseProjectPhotos";
import NoDataImageHolder from "../../../../Shared/Layouts/NoDataImageHolder";
import { useDiaryTabsContext } from "../../../../../Contexts/Providers/DiaryTabsProvider";

interface PhotoWidgetSceneProps {
    recordId?: number;
    domain?: string;
    isLocked: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    recordDate: Date | undefined;
    onCopy: (text: string) => void;
}

export default function PhotoWidgetScene(props: PhotoWidgetSceneProps) {
    const { t, i18n } = useTranslation();
    const { recordId, domain, isLocked, isInvestor, isSubdodavatel, recordDate, onCopy } = props;

    const [isUploadingPhoto, setIsUploadingPhoto] = useState<boolean>(false);
    const [aiHelperOpen, setAiHelperOpen] = useState<boolean>(false);
    const [choosePhotoOpen, setChoosePhotoOpen] = useState<boolean>(false);
    const [images, setImages] = useState<ImageDiaryDTO[]>([]);
    const [selectedImages, setSelectedImages] = useState<ImageDiaryDTO[]>([]);
    const [prompts, setPrompts] = useState<PromptDTO[]>([]);
    const [selectedPhotosUrl, setSelectedPhotosUrl] = useState<string[]>([]);
    const [autoMessage, setAutoMessage] = useState<string>('');
    const biggerThan700 = useMediaQuery('(min-width:1100px)');
    const theme = useTheme();
    const { permissions } = useDiaryTabsContext();
    function reloadData(onlyPrompts: boolean) {
        if (recordId)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetImagesForRecord(recordId).then((d) => {
                if (d) {
                    if (!onlyPrompts) {
                        if (d.images)
                            setImages(d.images);
                        else setImages([]);
                    }

                    if (d.prompts)
                        setPrompts(d.prompts);
                    else setPrompts([]);
                }
            });
    }
    useEffect(() => {
        reloadData(false);
    }, [recordId]);

    useEffect(() => {
        setSelectedImages([]);
    }, []);

    useEffect(() => {
        if (autoMessage && autoMessage.length > 0)
            setAiHelperOpen(true);
    }, [autoMessage]);

    return (
        <Box sx={{ marginX: '40px', background: 'white', padding: '30px', borderRadius: '15px', minHeight: 500 }}>
            <div style={{ display: 'flex' }}>
                {(permissions && permissions.canEditDiaryRecord || isInvestor) && !isLocked && <Button sx={{ marginLeft: '20px', marginTop: '-2px', height: '24px', padding: '16px' }}
                    onClick={(e) => {

                        e.preventDefault();
                        e.stopPropagation();
                        setChoosePhotoOpen(true);
                    }}
                    variant='contained' size='small' startIcon={biggerThan700 ? <AddAPhoto /> : undefined}>{biggerThan700 ? t('files.addPhotos') : <AddAPhoto sx={{ height: '20px' }} />}</Button>}
                {isInvestor !== true && <div style={{ display: 'flex', marginRight: '10px', marginLeft: 'auto' }}>
                    <Tooltip title={t('diary.imageAiTooltip')}>
                        <span>
                            <Button disabled={!selectedImages || selectedImages === null || selectedImages.length === 0} color={selectedImages && selectedImages.length > 0 ? "success" : "info"} startIcon={<Help />} onClick={(e) => { if (selectedImages && selectedImages.length > 0) setAutoMessage(t('diary.diaryAiDefaultPrompt')); }}>{t('diary.AiPopis')}</Button>
                        </span>
                    </Tooltip>
                    <Button color={aiHelperOpen ? "success" : "info"} startIcon={<Psychology />} onClick={e => setAiHelperOpen(!aiHelperOpen)}></Button>
                </div>}
            </div>
            {(images.length === 0 || !images) && <NoDataImageHolder title={t('NoPhotosFound')} />}
            {aiHelperOpen &&
                <div style={{ width: '100%' }}>
                    <GptImageChatScene refresh={() => reloadData(true)} prompts={prompts} recordId={recordId} isSubdodavatel={isSubdodavatel === true} autoMessage={autoMessage} selectedImagesUrl={selectedImages ? selectedImages.map(p => p.url!) : []} onCopy={(text) => {
                        if (onCopy)
                            onCopy(text);
                    }} onClearAutoMessage={() => setAutoMessage('')} /></div>}
            <ChoosePhotoDialog
                onClose={(e) => {
                    if (e)
                        reloadData(false);

                    setChoosePhotoOpen(false);
                }}
                open={choosePhotoOpen}
                isInvestor={isInvestor}
                isSubdodavatel={isSubdodavatel}
                onUploaded={(e) => {
                    if (domain !== undefined)
                        setImages([...images, new ImageDiaryDTO({
                            thumbnailUrl: "https://" + domain + ".vimovsem.cz/" + e,
                            url: "https://" + domain + ".vimovsem.cz/" + e
                        })]);
                }}
                isUploading={(e) => { setIsUploadingPhoto(e) }}
                recordId={recordId} />
            {images !== undefined && <ChooseProjectPhotos isProjectChoosing={false} fullWidth isLocked={isLocked} needRefresh={() => { reloadData(false); }} onRefresh={(filename, deleted) => {
                if (deleted)
                    setImages(images.filter(x => x.fileName !== filename));
            }} recordId={recordId} canDelete={true} canUpdate={false} canSelect={true} images={images} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} imageSingleSelectedChanged={(v) => {
                let tmp = [...images];
                let item = tmp.find(p => p.fileName === v.fileName);
                if (item)
                    item.isSelected = v.isSelected
                setImages(tmp);
            }} imagesSelectedChanged={(imgs) => { console.log('imgs:', imgs); setSelectedImages(imgs); }}></ChooseProjectPhotos>}
        </Box>
    )
}
import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import Login from './Components/Forms/LoginScene';
import DiaryMainScene from './Components/Scenes/DiaryMainScene';
import { ThemeProvider } from '@mui/material';
import Recomendations from './Components/Scenes/Sales/RecomendationScene';
import createThemeStavario from './Components/Shared/Themes/CreateTheme';
import AcceptInvite from './Components/Scenes/Sales/AcceptInvite';
import LoginOldVov from './Components/Forms/LoginOldScene';
import DiaryTabs from './Components/Scenes/DiaryComponents/DiaryTabs';
import DiaryPrint from './Components/Scenes/DiaryComponents/DiaryPrints/DiaryPrint';
import DiaryPager from './Components/Scenes/DiaryComponents/DiaryPrints/DiaryPager';
import DiaryDetail from './Components/Scenes/DiaryComponents/DiaryDetail';
import { useTranslation } from 'react-i18next';
import LoginInvestorScene from './Components/Forms/LoginInvestorScene';
import InvestorTabs from './Components/Investor/InvestorTabs';
import DiaryInvestorPager from './Components/Scenes/DiaryComponents/DiaryPrints/DiaryInvestorPager';
import ResetPassword from './Components/Shared/Users/ResetPassword';
import ProtectedInvestorRoute from './Components/Shared/Auth/ProtectedInvestorRoute';
import WealthList from './Components/Scenes/Wealth/WealthList';
import WealthTabs from './Components/Scenes/Wealth/WealthTabs';
import TasksList from './Components/Scenes/Tasks/TasksList';
import GptChatScene from './Components/Scenes/GptChat/GptChatScene';
import ExternalEntryPoint from './Components/Scenes/DiaryComponents/Externals/ExternalEntryPoint';
import LoginSubcontractorScene from './Components/Forms/LoginSubcontractorScene';
import ProtectedSubcontractorRoute from './Components/Shared/Auth/ProtectedSubcontractorRoute';
import SubcontratorMainScene from './Components/Subcontractor/SubcontractorMainScene';
import ProjectsList from './Components/Scenes/ProjectComponents/ProjectsList';
import AdminMainScene from './Components/Scenes/Admin/KrosAdminMainScene';
import AdminUserDetailScene from './Components/Scenes/Admin/Details/KrosAdminUserDetailScene';
import LoginKrosAdminScene from './Components/Forms/LoginKrosAdminScene';
import ProtectedKrosAdminRoute from './Components/Shared/Auth/ProtectedKrosAdminRoute';
import KrosAdminApplicationTabScene from './Components/Scenes/Admin/KrosAdminApplicationTabScene';
import UploadSnackbar from './Components/Shared/UploadSnackbar';
import { useUploadFileStore } from './Contexts/UploadFileContext';
import AiRecognitionList from './Components/Scenes/AiRecognition/AiRecognitionList';

import { jwtDecode } from 'jwt-decode';

import ToDoSceneOld from './Components/Scenes/ToDoSceneOld';
import SingleTodoTabSceneOld from './Components/Scenes/ToDoOld/SingleTodoTabSceneOld';


import EmployeesList from './Components/Scenes/Employees/EmployeesList';
import EmployeeDetail from './Components/Scenes/Employees/EmployeeDetail';
import WealthRentalTabs from './Components/Scenes/Wealth/WealthRentalTabs';
import Invoices from './Components/Scenes/InvoiceComponents/Invoices';
import Projects from './Components/Scenes/ProjectComponents/Projects';
import { UserLayoutProvider } from './Contexts/Providers/UserLayoutMenuProvider';
import { BuildingsPermissionProvider } from './Contexts/Permissions/BuildingsPermissionContext';
import WealthTabsContext from './Components/Scenes/Wealth/WealthTabsContext';
import MobileExternal from './Components/Scenes/DiaryComponents/Externals/DiaryMobileExternal';
import DiaryMainSceneWrapper from './Components/Scenes/DiaryMainSceneWrapper';
import { DiaryTabsProvider } from './Contexts/Providers/DiaryTabsProvider';
import DiaryLayout from './Components/Shared/Layouts/DiaryLayout';
import JokesAdminScene from './Components/Shared/Layouts/JokesAdminScene';
import HintsAdminScene from './Components/Shared/Layouts/HintsAdminScene';
import ProtectedRoute from './Components/Shared/ProtectedRoute';
import MobileEntry from './Components/Shared/Layouts/MobileEntry';
import DiaryMainSceneMobile from './Components/Scenes/DiaryComponents/DiaryMainSceneMobile';
import UserLayout from './Components/Shared/Layouts/UserLayout';
import InvestorLayout from './Components/Shared/Layouts/InvestorLayout';

function App() {
  const { uploadingFiles, showSnackbar, setShowSnackBar } = useUploadFileStore();
  window.addEventListener('unhandledrejection', function (event: PromiseRejectionEvent) {

  });



  useEffect(() => {
    const path = new URL(window.location.href).pathname;
    const appName = path.split('/')[2];
    let token: string | null = "";
    let isSubcontractor: boolean = false;
    let isInvestor: boolean = false;
    if (path.length > 2 && path.split('/')[3] === 'subcontractor') {
      token = localStorage.getItem('subcontractor_token_old');
      isSubcontractor = true;
    }
    else if (path.length > 2 && path.split('/')[3] === 'investor') {
      token = localStorage.getItem('investor_token_old');
      isInvestor = true;
    }
    else {
      token = localStorage.getItem('old_bearer');
    }

    if (token) {
      let decodedToken;

      try {
        decodedToken = jwtDecode(token!);
      } catch (e) {
        console.error(e);
      }

      if (appName && decodedToken && decodedToken.ApplicationName.toLowerCase() !== appName.toLowerCase()) {
        console.log('token application missmatched');
        if (isSubcontractor)
          localStorage.removeItem('subcontractor_token_old');
        else if (isInvestor)
          localStorage.removeItem('investor_token_old');
        else
          localStorage.removeItem('old_bearer');
      }

    }

  }, []);


  const theme = createThemeStavario();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <React.Fragment>
        <Route path="/" element={<ThemeProvider theme={theme}><Login /></ThemeProvider>} />
        <Route path=":lang/:domain" element={<ThemeProvider theme={theme}><LoginOldVov /></ThemeProvider>} />
        <Route path=":lang/:domain/olderpentry/:route/:token/:adminUserId" element={<ThemeProvider theme={theme}><ExternalEntryPoint /></ThemeProvider>} />
        <Route path=":lang/:domain/resetpassword" element={<ThemeProvider theme={theme}><ResetPassword /></ThemeProvider>} />
        <Route path=':lang/:domain/userlayout' element={<ThemeProvider theme={theme}><UserLayoutProvider isSubdodavatel={false}><UserLayout /></UserLayoutProvider></ThemeProvider>}>
          <Route path="jokes" element={<ThemeProvider theme={theme}><JokesAdminScene /></ThemeProvider>}></Route>
          <Route path="hints" element={<ThemeProvider theme={theme}><HintsAdminScene /></ThemeProvider>}></Route>
          <Route path="mobileentry/:module" element={<ThemeProvider theme={theme}><MobileEntry /></ThemeProvider>}></Route>

          <Route path="diary" element={<DiaryTabsProvider isInvestor={false} isSubdodavatel={false} ><ThemeProvider theme={theme}><DiaryLayout /></ThemeProvider></DiaryTabsProvider>} >
            <Route path="diaryvov" element={<DiaryTabs selected={0} />} />
            <Route path="diaries" element={<DiaryTabs selected={1} />} />
            <Route path="eyetower" element={<DiaryTabs selected={2} />} />
            <Route path="diaryvovdetail/:recordId" element={<DiaryMainSceneWrapper />} />
            <Route path="diaryDetail/:id" element={<DiaryDetail />}></Route>
            <Route path="diarymobile" element={<DiaryTabsProvider isInvestor={false} isSubdodavatel={false}><DiaryMainSceneMobile /></DiaryTabsProvider>}></Route>
          </Route>
          <Route path="todos" element={<ThemeProvider theme={theme}><ToDoSceneOld /></ThemeProvider>} />
          <Route path="todos/:id" element={<ThemeProvider theme={theme}><SingleTodoTabSceneOld /></ThemeProvider>}></Route>

          <Route path="wealths" element={<ThemeProvider theme={theme}><WealthList /></ThemeProvider>}></Route>
          <Route path="wealthdetail/:id" element={<ThemeProvider theme={theme}><WealthTabsContext /></ThemeProvider>}></Route>
          <Route path="rental/new/:id" element={<ThemeProvider theme={theme}><WealthRentalTabs /></ThemeProvider>}></Route>
          <Route path="rental/edit/:id" element={<ThemeProvider theme={theme}><WealthRentalTabs /></ThemeProvider>}></Route>

          <Route path="tasks" element={<ThemeProvider theme={theme}><TasksList /></ThemeProvider>}></Route>
          <Route path="projects" element={<ThemeProvider theme={theme}><BuildingsPermissionProvider><ProjectsList /></BuildingsPermissionProvider></ThemeProvider>} />
          <Route path="projectDetail/:id" element={<ThemeProvider theme={theme}><Projects /></ThemeProvider>}></Route>
          <Route path="projectDetail/" element={<ThemeProvider theme={theme}><Projects createProject /></ThemeProvider>}></Route>

          <Route path="invoices" element={<ThemeProvider theme={theme}><Invoices type="list" /></ThemeProvider>} />
          <Route path="invoiceDetail/:invoiceId" element={<ThemeProvider theme={theme}><Invoices type="detail" /></ThemeProvider>} />
          <Route path="invoiceDetailBuilding/:invoiceId" element={<ThemeProvider theme={theme}><Invoices type="detailBuilding" /></ThemeProvider>} />
          <Route path="invoices/new" element={<ThemeProvider theme={theme}><Invoices type="new" /></ThemeProvider>} />


        </Route>

        <Route path="recommendations/:token/:id/:domain/:language" element={<Recomendations />}></Route>
        <Route path="acceptInvite/:token/:id/:domain/:language" element={<AcceptInvite />}></Route>

        <Route path=":lang/:domain/ai-photos" element={<AiRecognitionList />}></Route>

        <Route path="kros" element={<ThemeProvider theme={theme}><LoginKrosAdminScene /></ThemeProvider>} />

        <Route path=":lang/:domain/admin/users" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><AdminMainScene selected={0} /></ThemeProvider></ProtectedKrosAdminRoute>} />
        <Route path=":lang/:domain/admin/users/:id" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><AdminUserDetailScene /></ThemeProvider></ProtectedKrosAdminRoute>} />
        <Route path=":lang/:domain/admin/applications" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><AdminMainScene selected={1} /></ThemeProvider></ProtectedKrosAdminRoute>} />
        <Route path=":lang/:domain/admin/applications/:id" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><KrosAdminApplicationTabScene selected={0} /></ThemeProvider></ProtectedKrosAdminRoute>} />
        {/* <Route path=":lang/:domain/admin/applications/:id/permissions" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><KrosAdminApplicationTabScene selected={1} /></ThemeProvider></ProtectedKrosAdminRoute>} /> */}
        <Route path=":lang/:domain/admin/stats" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><AdminMainScene selected={2} /></ThemeProvider></ProtectedKrosAdminRoute>} />


        {/* <Route path=":lang/:domain/projects" element={<ThemeProvider theme={theme}><Projects /></ThemeProvider>} /> */}

        {/* <Route path=":lang/:domain/diaryexternal/:token/:adminUserId" element={<ThemeProvider theme={theme}><DiaryExternal /></ThemeProvider>} /> */}
        {/*hlavní vstup*/}
        {/* <Route path=":lang/:domain/diaryolderpnew" element={<ThemeProvider theme={theme}><DiaryExternalWithMenu /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryolderpnew/:recordId/" element={<ThemeProvider theme={theme}><DailyRecordsExternalWithMenu /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryolderpnewdetail/:id/" element={<ThemeProvider theme={theme}><DiaryDetailExternalWithMenu /></ThemeProvider>} />
 */}

        {/* <Route path=":lang/:domain/diaryexternal/dailyrecord/:token/:adminUserId/:recordId/" element={<ThemeProvider theme={theme}><DailyRecordsExternal /></ThemeProvider>} /> */}
        {/* <Route path=":lang/:domain/diaryexternal/diary/:token/:adminUserId/:iddiary" element={<ThemeProvider theme={theme}><DiaryExternalDetail /></ThemeProvider>} /> */}


        <Route path=":lang/:domain/mobile/:employeeId/:token/:adminUserId/:module" element={<ThemeProvider theme={theme}><MobileExternal /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryMobile/:employeeId/:token/:adminUserId/" element={<ThemeProvider theme={theme}><MobileExternal /></ThemeProvider>} />



        {/* <Route path=":lang/:domain/diaryvov" element={<ThemeProvider theme={theme}><DiaryTabs /></ThemeProvider>} /> */}

        {/* <Route path=":lang/:domain/tasks/:id" element={<ThemeProvider theme={theme}><ToDoSceneOld /></ThemeProvider>}></Route> */}

        < Route path=":lang/:domain/investor" element={< ThemeProvider theme={theme} > <LoginInvestorScene /> </ThemeProvider >}></Route>
        < Route path=":lang/:domain/investor/main" element={< ProtectedInvestorRoute > <DiaryTabsProvider isInvestor={true} isSubdodavatel={false}><InvestorLayout /></DiaryTabsProvider></ProtectedInvestorRoute>}>

          < Route path="ai" element={<ThemeProvider theme={theme}><GptChatScene /></ThemeProvider>} />
          < Route path="tabs/tasks" element={<ThemeProvider theme={theme}><InvestorTabs selectedIndex={0} /></ThemeProvider>} />
          < Route path="tabs/photos" element={<ThemeProvider theme={theme}><InvestorTabs selectedIndex={1} /></ThemeProvider>} />
          < Route path="tabs/diary" element={<ThemeProvider theme={theme}><InvestorTabs selectedIndex={2} /></ThemeProvider>} />
          < Route path="tabs/dochazka" element={<ThemeProvider theme={theme}><InvestorTabs selectedIndex={3} /></ThemeProvider>} />
          < Route path="tabs/documents" element={<ThemeProvider theme={theme}><InvestorTabs selectedIndex={4} /></ThemeProvider>} />

          < Route path="tabs/diary/:recordId" element={<ThemeProvider theme={theme}><InvestorTabs selectedIndex={5} /></ThemeProvider>} />
          {/* < Route path="tabs/diary/:diaryId/:recordId" element={<ThemeProvider theme={theme}><DiaryInvestorPager /></ThemeProvider>} /> */}
        </Route>
        < Route path=":lang/:domain/investor/resetpassword" element={< ThemeProvider theme={theme} > <ResetPassword isInvestor /></ThemeProvider >} />
        < Route path=":lang/:domain/subcontractor" element={< ThemeProvider theme={theme} > <LoginSubcontractorScene /> </ThemeProvider >} />
        <Route path=":lang/:domain/subcontractor/main" element={<ThemeProvider theme={theme}>< ProtectedSubcontractorRoute > <UserLayoutProvider isSubdodavatel={true}><DiaryTabsProvider isInvestor={false} isSubdodavatel={true}><UserLayout /></DiaryTabsProvider></UserLayoutProvider></ProtectedSubcontractorRoute></ThemeProvider>} >
          < Route path="diaries" element={<SubcontratorMainScene><DiaryTabs selected={0} isSubdodavatel={true} /></SubcontratorMainScene>} />
          < Route path="diariesDiary" element={<SubcontratorMainScene><DiaryTabs selected={1} isSubdodavatel={true} /></SubcontratorMainScene>} />
          < Route path="diaries/:recordId" element={<SubcontratorMainScene><DiaryMainSceneWrapper isSubdodavatel={true} /></SubcontratorMainScene>} />
          < Route path="diaryDetail/:id" element={<SubcontratorMainScene><DiaryDetail isSubdodavatel={true} /></SubcontratorMainScene>}></Route >
        </Route>
        <Route path=":lang/:domain/subcontractor/resetpassword" element={<ResetPassword isSubdodavatel />} />
        <Route path=":lang/:domain/diary/print/:id/:token/:userId" element={<ThemeProvider theme={theme}><DiaryPrint isInvestor={false} /></ThemeProvider>}></Route>
        <Route path=":lang/:domain/diaryviewer/:id/:token/:userId" element={<ThemeProvider theme={theme}><DiaryPager /></ThemeProvider>}></Route>

        <Route path=":lang/:domain/employees" element={<ThemeProvider theme={theme}><EmployeesList /></ThemeProvider>}></Route>
        <Route path=":lang/:domain/employees/:id" element={<ThemeProvider theme={theme}><EmployeeDetail /></ThemeProvider>}></Route>
      </React.Fragment >
    ));
  return (
    <div>

      <RouterProvider router={router} />

      <UploadSnackbar isOpen={showSnackbar} onClose={() => { setShowSnackBar(false) }} />
    </div>
  );
}

export default App;
import { Box, Button, Checkbox, CircularProgress, Grid, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { CopyCutPasteType, DeleteImagesBuildingRequest, FileDirectoryDTO, FileParameter, ImageDiaryDTO, VovClient } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import './ProjectDocumentTreeScene.css'
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import { useApi } from '../../../../ApiOld/Api/useApi';
import { DataGrid, GridColDef, GridRowModesModel, GridRowSelectionModel, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import { csCZ } from '@mui/x-data-grid/locales';
import RenameCategoryDialogDialog from '../Dialogs/RenameImageDialog';
import MuiDatagrid from '../../../Shared/MuiDatagrid';
interface ProjectPhotosListSceneProps {
    buildingId: number;
    images: ImageDiaryDTO[];
    selectedImagesIds: number[];
    loading: boolean;
    onSelect: (ids: number[] | undefined) => void;
    setImages: (data: ImageDiaryDTO[]) => void;
    onImageDoubleClick?: (index: number | undefined) => void;
    onNameChange: (value: string, id: number) => void;
    onCategoryChange: () => void;
}

export default function ProjectPhotosListScene(props: ProjectPhotosListSceneProps) {
    const { buildingId, onSelect, images, selectedImagesIds, setImages, onImageDoubleClick, onNameChange, onCategoryChange } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [selectionModel, setSelectionModel] = useState<any[]>([]);
    const [renameImage, setRenameImage] = useState<ImageDiaryDTO>();
    const [renameOpen, setRenameOpen] = useState<boolean>(false);

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    useEffect(() => {
        if (images && images.length > 0) {
            //console.log('images - ', selectedImagesIds);
            setSelectionModel(selectedImagesIds);
        }
    }, [selectedImagesIds, images]);

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
        record: ImageDiaryDTO;
    } | null>(null);

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('files.photoName'),
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'created',
            headerName: t('files.datetimeCreated'),
            type: 'dateTime',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'creator',
            headerName: t('files.creator'),
            flex: 1,
            minWidth: 100,
        },
    ];

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (!event.currentTarget) {
            return;
        }
        const rowId = Number((event.currentTarget as HTMLDivElement).getAttribute('data-id'));

        const record = images.find((row) => row.id === rowId);

        if (!record) {
            return;
        }

        setRenameImage(record);
        // open a context menu
        setContextMenu(
            contextMenu === null ? { mouseX: event.clientX, mouseY: event.clientY, record } : null
        );
    };

    const apiRef = useGridApiRef();

    if (props.loading)
        return <CircularProgress />
    else
        return <Grid item xs={12} lg={12}>
            <MuiDatagrid
                apiRef={apiRef}
                columns={columns}
                rows={images || []}
                getRowId={(row) => row.id}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                checkboxSelection
                rowModesModel={rowModesModel}
                onRowSelectionModelChange={(ids) => {
                    setSelectionModel(ids);
                    onSelect(ids.map(p => p as number));
                    //console.log('ids - ', ids);
                }}
                rowSelectionModel={selectionModel}
                onRowDoubleClick={(row) => { if (onImageDoubleClick) onImageDoubleClick(images.indexOf(row.row)) }}
                onRowModesModelChange={handleRowModesModelChange}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                    },
                    row: {
                        onContextMenu: (e) => handleRowContextMenu(e),
                        style: { cursor: 'context-menu' },
                    },
                }}
            />
            <Menu
                open={contextMenu !== null}
                onClose={handleCloseContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem
                    disabled={!permissions || !permissions.buildingsEdit || selectedImagesIds.length < 1}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (renameImage) {
                            setRenameOpen(true);
                        }
                    }}
                >
                    {t('rename')}
                </MenuItem>
                <MenuItem
                    disabled={!permissions || !permissions.buildingsEdit || selectedImagesIds.length < 1}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onCategoryChange();
                    }}
                >
                    {t('buildings.moveToFolder')}
                </MenuItem>
            </Menu>
            <RenameCategoryDialogDialog oldName={renameImage && renameImage.name ? renameImage.name : ''} open={renameOpen} onClose={() => { setRenameImage(undefined); setRenameOpen(false); setContextMenu(null); }} imageId={renameImage ? renameImage.id : undefined} onConfirm={(id, name) => {
                onNameChange(name, id);
            }} />
        </Grid>;
}

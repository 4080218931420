import React, { useEffect } from "react";
import { Autocomplete, FormControl, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserGroupDTO } from "../../ApiOld/ApiServerVov";
import { SolveSubcontractorGroupContext, SolveUserGroupContext, StavarioOldGlobalContext, StavarioOldUpdateContext } from "../../Contexts/LoginOldContext";
import { useMediaPredicate } from "react-media-hook";

export interface GroupListProps {
    selectedGroupId?: number | null;
    onChange: (event: UserGroupDTO | null) => void;
    isMandatory?: boolean;
    isSubdodavatel?: boolean;
    readOnly?: boolean;
    alertFontSize?: number;
    minWidth?: number;
}
export default function GroupSelect(props: GroupListProps) {
    const { onChange, selectedGroupId, isMandatory, isSubdodavatel, readOnly, alertFontSize, minWidth } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedGroup, setSelectedGroup] = React.useState<UserGroupDTO | null>(null);
    const [groups, setGroups] = React.useState<UserGroupDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 1100px)");
    const globalContext = React.useContext(StavarioOldGlobalContext);
    const setGlobal = React.useContext(StavarioOldUpdateContext)!;

    const handleChange = (event: SelectChangeEvent<typeof selectedGroup>) => {
        if (event.target.value !== undefined) {
            setSelectedGroup(event.target.value as (UserGroupDTO | null));

            onChange(event.target.value as (UserGroupDTO | null));
        }

    };
    useEffect(() => {
        if (isSubdodavatel !== undefined && isSubdodavatel === true)
            SolveSubcontractorGroupContext(globalContext, setGlobal).then((res) => {
                setGroups(res)
            });
        else
            SolveUserGroupContext(globalContext, setGlobal).then((res) => {
                setGroups(res)
            });

    }, []);
    useEffect(() => {
        if (groups !== undefined && groups !== null && groups.length > 0
            && selectedGroupId !== undefined &&
            selectedGroupId !== null && selectedGroupId > 0) {
            let q = groups.find(x => x.id == selectedGroupId);
            if (q !== undefined)
                setSelectedGroup(q);
        }

    }, [groups]);
    return (
        <div>

            <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedGroup(v);
                    onChange(v);
                }}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedGroup}
                options={groups}
                readOnly={readOnly}
                fullWidth={biggerThan700}
                sx={{ minWidth: minWidth ? minWidth : 288 }}
                renderInput={(params) => <TextField error={isMandatory === true && selectedGroup === null}  {...params} label="Skupina"

                />}
            />
            {selectedGroup === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '2px', marginTop: '14px', color: theme.palette.error.main, fontSize: alertFontSize ? alertFontSize : 14, fontFamily: 'Roboto' }}>{t('FieldRequired')}</p>}
        </div>
    );
}

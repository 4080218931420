import { ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import * as React from 'react';
import { StavarioModule } from './StavarioModule';
import { useNavigate } from 'react-router';
import { BookOnlineOutlined, ConstructionOutlined, PrecisionManufacturingOutlined } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

export interface IModuleMenuItemProps {
    route: string;
    selectedModule: StavarioModule;
    moduleType: StavarioModule;
    selectableModules: StavarioModule[];
    label: string;
    topMenu?: boolean;
    minWidth?: number | string;
}

export default function ModuleMenuItem(props: IModuleMenuItemProps) {
    const { selectedModule, route, moduleType, selectableModules, label, topMenu, minWidth } = props;
    const [hovered, setHovered] = React.useState(false);
    const navigate = useNavigate();
    const getIconByModuleType = () => {
        switch (moduleType) {
            case StavarioModule.ProjectList:
                return <ConstructionOutlined fontSize={topMenu ? 'small' : 'medium'} color={selectableModules.includes(selectedModule) ? 'primary' : 'action'} />;
            case StavarioModule.DiaryRecordsList:
                return <BookOnlineOutlined fontSize={topMenu ? 'small' : 'medium'} color={selectableModules.includes(selectedModule) ? 'primary' : 'action'} />;
            case StavarioModule.WealthList:
                return <PrecisionManufacturingOutlined fontSize={topMenu ? 'small' : 'medium'} color={selectableModules.includes(selectedModule) ? 'primary' : 'action'} />;
            default:
                return <div></div>;
        }
    }
    const biggerThan1250 = useMediaQuery('(min-width:1250px)');
    return (
        <ListItem sx={topMenu ? { height: '28px', minWidth: (biggerThan1250 || selectableModules.includes(selectedModule)) ? minWidth : undefined, p: -1 } : undefined} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} key={route} disablePadding>
            <ListItemButton
                sx={{
                    fontWeight: selectableModules.includes(selectedModule) ? 'bold' : 'normal',

                }}
                style={{ background: selectableModules.includes(selectedModule) ? '#555555' : (hovered ? '#C3C3C3' : 'inherit') }}
                selected={selectableModules.includes(selectedModule)}
                onClick={() => {
                    if (!selectableModules.includes(selectedModule))
                        navigate(route);
                    // else window.location.href = 'https://stavariokluc.vimovsem.cz/admin/wealth/';
                }}
            >
                <ListItemIcon sx={{ mr: topMenu ? -3 : 0 }}>
                    {getIconByModuleType()}
                </ListItemIcon>
                {(selectableModules.includes(selectedModule) || biggerThan1250 || !topMenu) && <Typography fontSize={topMenu ? 'small' : 'medium'} color={selectableModules.includes(selectedModule) ? 'primary' : 'black'} sx={{ fontWeight: selectableModules.includes(selectedModule) ? 'bold' : 'normal' }}>{label}</Typography>}
            </ListItemButton>
        </ListItem >
    );
}

import { ArrowDropDown, ArrowRight, Folder, FolderSpecial, InsertDriveFile } from '@mui/icons-material';
import { Button, Checkbox, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { DrawNodeEventArgs, FieldSettingsModel, NodeCheckEventArgs, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import React, { useCallback, useEffect, useState } from 'react';
import { CopyCutPasteType, FileDirectoryDTO, FileParameter } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import DeleteDialog from '../../../Shared/Layouts/DeleteDialog';
import CreateFolderDialog from './CreateFolderDialog';
import { APIOldBuildingsClient } from '../../../../ApiOld/ApiOldBuildingsClient';
import './ProjectDocumentTreeScene.css'
import { UploadFileDC, useUploadFileStore } from '../../../../Contexts/UploadFileContext';
import { makeRandomKey } from '../../../Shared/MakeRandomKey';
import { fileListToObject } from '../../../Shared/Functions/FileListToObjects';
import { getFilesFromDataTransferItems } from 'datatransfer-files-promise';
import { ContainsFolder } from '../../../Shared/Functions/ContainsFolder';
import CircularProgressDialog from '../../../Shared/CircularProgressDialog';
import { CopyPasteContext } from '../Contexts/CopyPasteContext';
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';

interface ProjectDocumentTreeSceneProps {
    files: FileDirectoryDTO[];
    projectId: number;
    selected?: FileDirectoryDTO;
    onSelect: (data: any) => void;
    onRefresh: () => void;
    setFiles: React.Dispatch<React.SetStateAction<FileDirectoryDTO[] | null>>;
}

export default function ProjectDocumentTreeScene(props: ProjectDocumentTreeSceneProps) {
    const { onSelect, onRefresh, files, selected, projectId, setFiles } = props;
    const { t, i18n } = useTranslation();
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
    const [copyCutPasteType, setCopyCutPasteType] = React.useState<CopyCutPasteType>();
    const [copyCutFolderId, setCopyCutFolderId] = React.useState<number>();
    const [createFolderDialogOpen, setCreateFolderDialogOpen] = React.useState(false);
    const theme = useTheme();
    const { uploadingFiles, addFile, uploadFiles } = useUploadFileStore();

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent, data: FileDirectoryDTO) => {
        event.preventDefault();
        event.stopPropagation();
        onSelect(data);
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null
        );
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    useEffect(() => {
        //console.log('a:', selected);

    }, [selected]);

    function onDelete() {
        if (selected && selected?.id)
            new APIOldBuildingsClient().DeleteProjectFile(selected.id).then(p => {
                setDeleteDialogOpen(false);
                onRefresh();
            });
    }

    const FileTreeView = ({ files }) => (
        <div style={{ maxHeight: '90%', minHeight: '120px', overflow: 'auto' }}>
            {files !== null &&
                files.map((file, index) => (
                    <FileNode key={index} file={file} setFiles={setFiles} setCopyCutFolderId={setCopyCutFolderId} setCopyCutPasteType={setCopyCutPasteType} index={index} level={1} />
                ))}
        </div>
    );

    /* const uploadFile = async (selectedFile: File, documentId: number) => {
        console.log('Selected file:', selectedFile);

        const chunkSize = 1024 * 1024 * 2; // 1 MB
        const chunks: Blob[] = [];
        let offset = 0;

        while (offset < selectedFile.size) {
            const chunk = selectedFile.slice(offset, offset + chunkSize);
            chunks.push(chunk);
            offset += chunkSize;
        }

        let key = makeRandomKey(16, []);
        for (let i = 0; i < chunks.length; i++) {
            const fileData = new File([chunks[i]], selectedFile.name);
            let fileParameter: FileParameter = { data: chunks[i], fileName: selectedFile.name };
            await new APIOldBuildingsClient().UploadProjectDocumentChunk(selectedFile.name, fileParameter, i, chunks.length, key, documentId);
        }
    } */

    const FileNode = (props) => {
        const { file, setFiles, index, level } = props;
        const contextValue = React.useContext(CopyPasteContext);

        useEffect(() => {
            /* console.log('b:', contextValue); */
        }, [contextValue]);

        const toggleExpanded = (files: FileDirectoryDTO[], fileId: number): FileDirectoryDTO[] => {
            return files.map((item) => {
                if (item.id === fileId) {
                    return new FileDirectoryDTO({ ...item, isExpanded: !item.isExpanded });
                } else if (item.children && item.children.length > 0) {
                    return new FileDirectoryDTO({ ...item, children: toggleExpanded(item.children, fileId) });
                }
                return item;
            });
        };

        const handleToggleExpand = (e: any, fileId: number) => {
            e.preventDefault(); e.stopPropagation();
            setFiles((prev) => (prev ? toggleExpanded(prev, fileId) : prev));
        };

        const hasChildren = () => {
            return file && file.children && file.children.length > 0;
        }

        const [isDragging, setIsDragging] = useState(false);
        const handleDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();
            setIsDragging(true);
        }, []);

        const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();
            setIsDragging(true);
        }, []);

        const handleDragLeave = useCallback(() => {
            setIsDragging(false);
        }, []);

        const handlePaste = useCallback((targetFileId: number) => {
            if (contextValue !== null && contextValue.copyPasteId && targetFileId && contextValue.copyCutType !== undefined && contextValue.copyCutType !== null) {
                new APIOldBuildingsClient().CopyCutPasteProjectFile(contextValue.copyPasteId, targetFileId, contextValue.copyCutType as CopyCutPasteType, []).then(p => {
                    onRefresh();
                });
            }
        }, [onRefresh, contextValue]);


        const handlePasteFiles = useCallback((targetFileId: number) => {
            if (contextValue !== null && contextValue.copyPasteFileIds && targetFileId && contextValue.copyCutType !== undefined && contextValue.copyCutType !== null) {
                new APIOldBuildingsClient().CopyCutPasteProjectFile(undefined, targetFileId, contextValue.copyCutType as CopyCutPasteType, contextValue.copyPasteFileIds).then(p => {
                    onRefresh();
                });
            }
        }, [onRefresh, contextValue]);

        const handleDrop = useCallback(
            async (event: React.DragEvent<HTMLDivElement>) => {
                event.preventDefault();
                event.stopPropagation();
                setIsDragging(false);

                const { files, types } = event.dataTransfer;
                if (files.length > 0) {
                    let hasFolder = ContainsFolder(event);

                    if (hasFolder) {
                        var allFiles = await getFilesFromDataTransferItems(event.dataTransfer.items);
                        //let filesObject = await fileListToObject(files);
                        setCreateFolderDialogOpen(true);
                        new APIOldBuildingsClient().CreateProjectFilesFromPaths(projectId, file.id, allFiles.map(p => (p as any).filepath)).then(p => {
                            setCreateFolderDialogOpen(false);
                            onRefresh();
                            for (let i = 0; i < allFiles.length; i++) {
                                addFile(new UploadFileDC(allFiles[i], false, false, file.id, true, (allFiles[i] as any).filepath, undefined));
                            }
                            uploadFiles();
                        });
                    }
                    else {
                        for (let i = 0; i < files.length; i++) {
                            addFile(new UploadFileDC(files[i], false, false, file.id, true, '', undefined));
                        }
                        uploadFiles();
                    }
                    /* let upFiles: UploadFileDC[] = [];
                    for (let i = 0; i < files.length; i++) {
                        upFiles.push(new UploadFileDC(files[i], false, false, file.id));
                    } */
                    /* loadUploadingFiles(upFiles);
                    for (let i = 0; i < files.length; i++) {
                        await uploadFile(files[i], file.id);
                        setUploadingFiles((prev) => prev ? prev.map((item, ind) =>
                            i === ind ? { ...item, isDone: true } : item
                        ) : prev);
                    } */
                }

                /* onRefresh();
                delay(3000).then(p => loadUploadingFiles([])); */
            },
            []
        );

        if (file)
            return (
                <div key={file.id} onClick={(e) => { e.preventDefault(); e.stopPropagation(); onSelect(file) }} onContextMenu={(e) => { handleContextMenu(e, file) }} >
                    <div style={{
                        display: 'flex',
                        border: isDragging ? '2px dashed' : '0px solid',
                        borderColor: isDragging ? theme.palette.text.primary : 'white',
                        cursor: 'pointer',
                    }}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        className={`selectable ${selected && selected.id === file.id ? 'selected' : ''}`} >
                        {hasChildren() && <div style={{ color: 'black' }}><Button size='small' onClick={(e) => handleToggleExpand(e, file.id)}>
                            {!file.isExpanded && <ArrowRight htmlColor='black' />}
                            {file.isExpanded && <ArrowDropDown htmlColor='black' />}
                        </Button></div>}
                        {!hasChildren() && <div style={{ width: '64px', background: 'white' }}>

                        </div>}
                        <div style={{ display: 'flex', marginTop: '4px', paddingTop: '2px', cursor: 'context-menu'/* , marginLeft: '-16px' */ }}>
                            {file.isDirectory ? <Folder color='primary' /> : <FolderSpecial color='primary' />}
                            <Typography sx={{ marginLeft: '8px' }}>{file.name}</Typography>
                        </div >

                        <Menu
                            open={contextMenu !== null}
                            onClose={handleCloseContextMenu}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                contextMenu !== null
                                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                    : undefined
                            }
                        >
                            <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); setCreateDialogOpen(true); handleCloseContextMenu(); }}>{t('files.createFolder')}</MenuItem>
                            <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); setDeleteDialogOpen(true); handleCloseContextMenu(); }}>{t('files.deleteFolder')}</MenuItem>
                            <hr></hr>
                            <MenuItem onClick={(e) => {
                                e.preventDefault(); e.stopPropagation();
                                contextValue?.setCopyPasteId(selected?.id!);
                                contextValue?.setCopyCutType(CopyCutPasteType.Copy);
                                handleCloseContextMenu();
                            }}>{t('files.copyFolder')}</MenuItem>
                            <MenuItem onClick={(e) => {
                                e.preventDefault(); e.stopPropagation();
                                contextValue?.setCopyPasteId(selected?.id!);
                                contextValue?.setCopyCutType(CopyCutPasteType.Cut);
                                handleCloseContextMenu();
                            }}>{t('files.cutFolder')}</MenuItem>
                            <MenuItem disabled={contextValue === null || contextValue.copyPasteId === null || selected === undefined} onClick={(e) => {
                                e.preventDefault(); e.stopPropagation();
                                if (selected && selected?.id && contextValue?.copyPasteId)
                                    handlePaste(selected.id);
                                console.log(selected, contextValue?.copyPasteId);
                                handleCloseContextMenu();
                            }}>{t('files.pasteFolder')}</MenuItem>
                            <MenuItem disabled={contextValue === null || contextValue.copyPasteFileIds === null || contextValue.copyPasteFileIds.length === 0 || selected === undefined} onClick={(e) => {
                                e.preventDefault(); e.stopPropagation();
                                if (selected && selected?.id)
                                    handlePasteFiles(selected.id);
                                handleCloseContextMenu();
                            }}>{t('files.pasteFile')}</MenuItem>
                        </Menu>
                    </div>
                    {file.isExpanded && (
                        <div style={{ marginLeft: 20 + 'px' }}>
                            {file.children &&
                                file.children.map((child, i) => (
                                    <FileNode key={i} file={child} setFiles={setFiles} setCopyCutFolderId={setCopyCutFolderId} setCopyCutPasteType={setCopyCutPasteType} index={i} level={level + 1} />
                                ))}
                        </div>
                    )}
                </div>
            );
        else
            return <div></div>
    };

    if (files && files.length > 0)
        return <div>
            <FileTreeView files={files} />
            <DeleteDialog data={selected} onDeleteAgree={onDelete} onClose={() => setDeleteDialogOpen(false)} open={deleteDialogOpen} />
            <CreateFolderDialog open={createDialogOpen} buildingId={projectId} parentId={selected?.id} onClose={() => { setCreateDialogOpen(false); onRefresh(); }} />
            <CircularProgressDialog open={createFolderDialogOpen} onClose={() => setCreateFolderDialogOpen(true)} title={t('creatingFolderStructure')} />
            {/* <UploadSnackbar onClose={() => { setUploadingFiles(undefined) }} isOpen={uploadingFiles !== undefined} files={uploadingFiles} /> */}
        </div>;
    else
        return <div></div>;
}

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface DeleteDialogProps {
    onDeleteAgree: (event: any) => void;
    onClose: (event: any) => void;
    open: boolean;
    data?: any;
}
export default function DeleteDialog(props: DeleteDialogProps, ref: any) {
    const { onDeleteAgree, onClose, open, data } = props;

    // const [open, setOpen] = React.useState(false);
    const handleClose = (e: any) => {
        onClose(e);
    };

    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={() => { handleClose(data) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('confirmDelete')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('confirmDelete')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { handleClose(data) }}>{t('noL')}</Button>
                <Button onClick={() => { onDeleteAgree(data) }} autoFocus>
                    {t('yesL')}
                </Button>
            </DialogActions>
        </Dialog>);
}
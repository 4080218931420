import { AttachFile, Check, Clear, Close, CloseFullscreen, ColorLens, Delete, DoneAll, EventRepeat, Fullscreen, History, MoreHorizOutlined, Reply, Save, SaveAs, Troubleshoot, Watch } from '@mui/icons-material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, makeStyles, MenuItem, Popover, Select, Slider, styled, Switch, Tab, Tabs, TextareaAutosize, TextField, TextFieldProps, Tooltip, Typography, useTheme } from '@mui/material';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { ChangeEventArgs, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import { useNavigate, useParams } from 'react-router-dom';
import { AssignedUserDTO, BuildingEmployeeDTO, EmployeeDetailDTO } from '../../../../ApiOld/ApiServerVov';
import GroupSelect from '../../../Shared/GroupSelect';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EmployeeSignatureScene from './EmployeeSignatureScene';
import { groupBy } from '../../../Shared/GroupBy';
import { EmployeeDTO } from '../../Sales/OldApi/ApiServer';

export interface EmployeeBuildingsSceneProps {
    employee: EmployeeDetailDTO | undefined;
    setEmployee: (value: EmployeeDetailDTO | undefined) => void;
}


export default function EmployeeBuildingsScene(props: EmployeeBuildingsSceneProps) {
    const { employee, setEmployee } = props;
    const navigate = useNavigate();
    const theme = useTheme();

    const { t, i18n } = useTranslation();

    const checkAll = useCallback((isAssigned: boolean) => {
        if (!employee) return;

        const updatedAssignedUsers: BuildingEmployeeDTO[] = employee.buildings!.map((p) =>
            new BuildingEmployeeDTO({ id: p.id, isAssigned: isAssigned, name: p.name })
        );
        setEmployee(new EmployeeDetailDTO({ ...employee, buildings: updatedAssignedUsers }));
    }, [setEmployee]);

    if (employee !== undefined && employee.buildings !== undefined) {
        return (
            <div>
                <h4>{t('visibleForUsers')}</h4><div style={{ boxShadow: '1px 3px 3px 3px lightgray', margin: '16px', padding: '8px' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex' }}>
                            <Tooltip title={t('chooseAll')} >
                                <IconButton color="primary" onClick={(e) => { checkAll(true); }}>
                                    <DoneAll sx={{ height: '16px' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('unchooseAll')} >
                                <IconButton color="primary" onClick={(e) => { checkAll(false); }}>
                                    <Clear sx={{ height: '16px' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            employee.buildings.map((item2, ii) => {
                                return (<div key={ii} style={{ margin: '10px', marginLeft: '20px' }}> <FormControlLabel
                                    key={item2.id}
                                    control={
                                        <Checkbox
                                            id={item2.id?.toString()}
                                            checked={item2.isAssigned}
                                            onChange={(e) => {
                                                if (!employee) return;

                                                const updatedAssignedUsers: BuildingEmployeeDTO[] = employee.buildings!.map((p) =>
                                                    p.id === item2.id ? new BuildingEmployeeDTO({ id: p.id, isAssigned: !p.isAssigned, name: p.name }) : p
                                                );
                                                setEmployee(new EmployeeDetailDTO({ ...employee, buildings: updatedAssignedUsers }));
                                            }}
                                        />
                                    }
                                    label={item2.name}
                                /> </div>);
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
    else return (<div></div>);
};

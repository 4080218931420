import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker, Libraries } from "@react-google-maps/api";
/* import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox"; */
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { GetBuildingDataDetailResponse } from '../../../../ApiOld/ApiServerVov';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, Grid, radioClasses, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ExpandMore, GpsFixed, Map, RateReview } from '@mui/icons-material';
import GoogleMaps from './GoogleMap';
import GoogleMap1Scene from './GoogleMap1Scene';
import GoogleMap2Scene from './GoogleMap2Scene';
import GoogleMap3Scene from './GoogleMap3Scene';
import GoogleMap4Scene from './GoogleMap4Scene';
import { useBuildingsPermissionContext } from '../../../../Contexts/Permissions/BuildingsPermissionContext';
import { Validate, ValidationGroup } from 'mui-validate';
import { libraries } from './LibrariesPlaces';


interface ProjectPositionProps {
    projectData: Partial<GetBuildingDataDetailResponse> | null;
    setProjectData: (data: Partial<GetBuildingDataDetailResponse> | null) => void;
}


export default function ProjectPosition(props: ProjectPositionProps) {
    const { projectData, setProjectData } = props;

    const { ready, value, setValue, suggestions: { status, data }, clearSuggestions } = usePlacesAutocomplete();
    const [selected, setSelected] = useState<any>({ lat: 0, lng: 0, radius: 1 });
    const [selected2, setSelected2] = useState<any>(null);
    const [selected3, setSelected3] = useState<any>(null);
    const [selected4, setSelected4] = useState<any>(null);
    const { t, i18n } = useTranslation();


    const { isLoaded, loadError } = useLoadScript({
        id: `google-map`,
        googleMapsApiKey: "AIzaSyAUicHcR0cgVPe3nY0bjbrUHMlwk3EVYPU",
        libraries: libraries
    });

    if (loadError) {
        console.log('Error loading maps', loadError);
    }

    const { permissions, reloadPermissions } = useBuildingsPermissionContext();

    useEffect(() => {
        if (!permissions)
            reloadPermissions();
    }, [reloadPermissions]);

    useEffect(() => {
        if (projectData?.address1 !== undefined && projectData?.gpsX1 !== undefined && projectData?.gpsY1 !== undefined) {
            setValue(projectData.address1, false);
            setSelected({ lat: projectData.gpsX1, lng: projectData.gpsY1, radius: projectData.gpsDiff1 });
        }
        if (projectData?.address2 !== undefined && projectData?.gpsX2 !== undefined && projectData?.gpsY2 !== undefined) {
            setValue(projectData.address2, false);
            setSelected2({ lat: projectData.gpsX2, lng: projectData.gpsY2, radius: projectData.gpsDiff2 });
        }
        if (projectData?.address3 !== undefined && projectData?.gpsX3 !== undefined && projectData?.gpsY3 !== undefined) {
            setValue(projectData.address3, false);
            setSelected3({ lat: projectData.gpsX3, lng: projectData.gpsY3, radius: projectData.gpsDiff3 });
        }
        if (projectData?.address4 !== undefined && projectData?.gpsX4 !== undefined && projectData?.gpsY4 !== undefined) {
            setValue(projectData.address4, false);
            setSelected4({ lat: projectData.gpsX4, lng: projectData.gpsY4, radius: projectData.gpsDiff4 });
        }
        setExpanded([...expanded, 1]);
    }, []);

    const handleSelect = async (address: string) => {
        setValue(address, false);
        clearSuggestions();

        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setSelected({ lat: lat, lng: lng, radius: projectData && projectData.gpsDiff1 ? projectData.gpsDiff1 : 1 });

        setProjectData({ ...projectData, /* address1: address, */ gpsX1: lat, gpsY1: lng });
    }

    const handleSelect2 = async (address: string) => {
        setValue(address, false);
        clearSuggestions();

        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setSelected2({ lat: lat, lng: lng, radius: projectData && projectData.gpsDiff2 ? projectData.gpsDiff2 : 1 });

        setProjectData({ ...projectData, address2: address, gpsX2: lat, gpsY2: lng });
    }

    const handleSelect3 = async (address: string) => {
        setValue(address, false);
        clearSuggestions();

        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setSelected3({ lat, lng, radius: projectData && projectData.gpsDiff3 ? projectData.gpsDiff3 : 1 });

        setProjectData({ ...projectData, address3: address, gpsX3: lat, gpsY3: lng });
    }

    const handleSelect4 = async (address: string) => {
        setValue(address, false);
        clearSuggestions();

        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setSelected4({ lat, lng, radioClasses: projectData && projectData.gpsDiff4 ? projectData.gpsDiff4 : 1 });

        setProjectData({ ...projectData, address4: address, gpsX4: lat, gpsY4: lng });
    }

    const handleGetUserLocation = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setSelected({ lat: latitude, lng: longitude, radius: projectData && projectData.gpsDiff1 ? projectData.gpsDiff1 : 1 });

                    setProjectData({ ...projectData, gpsX1: latitude, gpsY1: longitude });
                },
                (error) => {
                    alert('Error getting user location: ' + error.message);
                }
            );
        } else {
            alert('Geolocation API is not available in this browser.');
        }
    };

    const handleGetUserLocation2 = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setSelected2({ lat: latitude, lng: longitude, radius: projectData && projectData.gpsDiff2 ? projectData.gpsDiff2 : 1 });

                    setProjectData({ ...projectData, gpsX2: latitude, gpsY2: longitude });
                },
                (error) => {
                    alert('Error getting user location: ' + error.message);
                }
            );
        } else {
            alert('Geolocation API is not available in this browser.');
        }
    };

    const handleGetUserLocation3 = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setSelected3({ lat: latitude, lng: longitude, radius: projectData && projectData.gpsDiff3 ? projectData.gpsDiff3 : 1 });

                    setProjectData({ ...projectData, gpsX3: latitude, gpsY3: longitude });
                },
                (error) => {
                    alert('Error getting user location: ' + error.message);
                }
            );
        } else {
            alert('Geolocation API is not available in this browser.');
        }
    };

    const handleGetUserLocation4 = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setSelected4({ lat: latitude, lng: longitude, radius: projectData && projectData.gpsDiff4 ? projectData.gpsDiff4 : 1 });

                    setProjectData({ ...projectData, gpsX4: latitude, gpsY4: longitude });
                },
                (error) => {
                    alert('Error getting user location: ' + error.message);
                }
            );
        } else {
            alert('Geolocation API is not available in this browser.');
        }
    };

    const [expanded, setExpanded] = React.useState<number[]>([]);
    const handleChange =
        (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            if (newExpanded)
                setExpanded([...expanded, panel]);
            else {
                setExpanded(expanded.filter(t => t !== panel));
            }

        };
    const theme = useTheme();
    return (
        <div>
            <Accordion className='expander' expanded={expanded.indexOf(1) > -1} onChange={handleChange(1)}>
                <AccordionSummary className='expander-header' aria-controls="panel6d-content" id="panel6d-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex' }}>
                            <Map color='secondary' className='accordion-icon' />
                            <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('buildings.projectPosition')}{' #1'}</Typography>
                        </div>
                        {expanded.indexOf(1) <= -1 && <ChevronLeft />}
                        {expanded.indexOf(1) > -1 && <ExpandMore />}
                    </div>

                    {/*  {(notes !== undefined && notes !== '') && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />} */}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '8px' }}>
                        <ValidationGroup>
                            <Grid container spacing={3} sx={{ pointerEvents: permissions && permissions.buildingsEdit ? 'all' : 'none' }}>
                                <Grid item xs={12}>
                                    <GoogleMap1Scene isLoaded={isLoaded} projectData={projectData} selected={selected} setSelected={setSelected} setProjectData={setProjectData} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Validate name='gps1-x-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                                <TextField label={t('buildings.gpsCoordinatesX')} variant="outlined" fullWidth size='small'
                                                    name='gpsCoordinatesX'
                                                    value={projectData?.gpsX1 !== undefined ? projectData?.gpsX1 : ""}
                                                    onChange={(e) => { setProjectData({ ...projectData, gpsX1: Number(e.target.value) ? Number(e.target.value) : undefined }) }}
                                                />
                                            </Validate>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Validate name='gps1-y-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                                <TextField label={t('buildings.gpsCoordinatesY')} variant="outlined" fullWidth size='small'
                                                    name='gpsCoordinatesY'
                                                    value={projectData?.gpsY1 !== undefined ? projectData?.gpsY1 : ""}
                                                    onChange={(e) => { setProjectData({ ...projectData, gpsY1: Number(e.target.value) ? Number(e.target.value) : undefined }) }}
                                                />
                                            </Validate>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Validate name='gps1-diff-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                                <TextField label={t('buildings.gpsDeviationInMetres')} variant="outlined" fullWidth size='small'
                                                    name='gpsDeviationInMetres'
                                                    value={projectData?.gpsDiff1 !== undefined ? projectData?.gpsDiff1 : 0}
                                                    onChange={(e) => { setProjectData({ ...projectData, gpsDiff1: Number(e.target.value) ? Number(e.target.value) : 0 }) }}
                                                />
                                            </Validate>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {isLoaded && <GoogleMaps isLoaded={isLoaded} valueSelected={(e) => { if (e && e.description) handleSelect(e.description) }} />}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button startIcon={<GpsFixed />} fullWidth variant="contained" onClick={handleGetUserLocation}>{t('MyGPSLocation')}</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ValidationGroup>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className='expander' expanded={expanded.indexOf(2) > -1} onChange={handleChange(2)}>
                <AccordionSummary className='expander-header' aria-controls="panel6d-content" id="panel6d-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex' }}>
                            <Map color='secondary' className='accordion-icon' />
                            <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('buildings.projectPosition')}{' #2'}</Typography>
                        </div>
                        {expanded.indexOf(2) <= -1 && <ChevronLeft />}
                        {expanded.indexOf(2) > -1 && <ExpandMore />}
                    </div>
                    {/*  {(notes !== undefined && notes !== '') && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />} */}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '8px' }}>
                        <Grid container spacing={3} sx={{ pointerEvents: permissions && permissions.buildingsEdit ? 'all' : 'none' }}>
                            <Grid item xs={12}>
                                <GoogleMap2Scene isLoaded={isLoaded} projectData={projectData} selected={selected2} setSelected={setSelected2} setProjectData={setProjectData} />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField label={t('buildings.gpsCoordinatesX')} variant="outlined" fullWidth size='small'
                                            name='gpsCoordinatesX'
                                            value={projectData?.gpsX2 !== undefined ? projectData?.gpsX2 : ""}
                                            onChange={(e) => { setProjectData({ ...projectData, gpsX2: Number(e.target.value) ? Number(e.target.value) : undefined }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label={t('buildings.gpsCoordinatesY')} variant="outlined" fullWidth size='small'
                                            name='gpsCoordinatesY'
                                            value={projectData?.gpsY2 !== undefined ? projectData?.gpsY2 : ""}
                                            onChange={(e) => { setProjectData({ ...projectData, gpsY2: Number(e.target.value) ? Number(e.target.value) : undefined }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label={t('buildings.gpsDeviationInMetres')} variant="outlined" fullWidth size='small'
                                            name='gpsDeviationInMetres'
                                            value={projectData?.gpsDiff2 !== undefined ? projectData?.gpsDiff2 : 0}
                                            onChange={(e) => { setProjectData({ ...projectData, gpsDiff2: Number(e.target.value) ? Number(e.target.value) : 0 }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox size='small' sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={projectData?.gpsActive2} onChange={(e, checked) => { setProjectData({ ...projectData, gpsActive2: checked }) }} />}
                                            label={<Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{t('active')}</Typography>}
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {isLoaded && <GoogleMaps isLoaded={isLoaded} valueSelected={(e) => { if (e && e.description) handleSelect2(e.description) }} />}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button startIcon={<GpsFixed />} fullWidth variant="contained" onClick={handleGetUserLocation2}>{t('MyGPSLocation')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className='expander' expanded={expanded.indexOf(3) > -1} onChange={handleChange(3)}>
                <AccordionSummary className='expander-header' aria-controls="panel6d-content" id="panel6d-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex' }}>
                            <Map color='secondary' className='accordion-icon' />
                            <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('buildings.projectPosition')}{' #3'}</Typography>
                        </div>
                        {expanded.indexOf(3) <= -1 && <ChevronLeft />}
                        {expanded.indexOf(3) > -1 && <ExpandMore />}
                    </div>
                    {/* <Map color='secondary' className='accordion-icon' />
                    <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('buildings.projectPosition')}{' #3'}</Typography> */}
                    {/*  {(notes !== undefined && notes !== '') && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />} */}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '8px' }}>
                        <Grid container spacing={3} sx={{ pointerEvents: permissions && permissions.buildingsEdit ? 'all' : 'none' }}>
                            <Grid item xs={12}>
                                <GoogleMap3Scene isLoaded={isLoaded} projectData={projectData} selected={selected3} setSelected={setSelected3} setProjectData={setProjectData} />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField label={t('buildings.gpsCoordinatesX')} variant="outlined" fullWidth size='small'
                                            name='gpsCoordinatesX'
                                            value={projectData?.gpsX3 !== undefined ? projectData?.gpsX3 : ""}
                                            onChange={(e) => { setProjectData({ ...projectData, gpsX2: Number(e.target.value) ? Number(e.target.value) : undefined }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label={t('buildings.gpsCoordinatesY')} variant="outlined" fullWidth size='small'
                                            name='gpsCoordinatesY'
                                            value={projectData?.gpsY3 !== undefined ? projectData?.gpsY3 : ""}
                                            onChange={(e) => { setProjectData({ ...projectData, gpsY3: Number(e.target.value) ? Number(e.target.value) : undefined }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label={t('buildings.gpsDeviationInMetres')} variant="outlined" fullWidth size='small'
                                            name='gpsDeviationInMetres'
                                            value={projectData?.gpsDiff3 !== undefined ? projectData?.gpsDiff3 : 0}
                                            onChange={(e) => { setProjectData({ ...projectData, gpsDiff3: Number(e.target.value) ? Number(e.target.value) : 0 }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox size='small' sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={projectData?.gpsActive3} onChange={(e, checked) => { setProjectData({ ...projectData, gpsActive3: checked }) }} />}
                                            label={<Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{t('active')}</Typography>}
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {isLoaded && <GoogleMaps isLoaded={isLoaded} valueSelected={(e) => { if (e && e.description) handleSelect3(e.description) }} />}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button startIcon={<GpsFixed />} fullWidth variant="contained" onClick={handleGetUserLocation3}>{t('MyGPSLocation')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className='expander' expanded={expanded.indexOf(4) > -1} onChange={handleChange(4)}>
                <AccordionSummary className='expander-header' aria-controls="panel6d-content" id="panel6d-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex' }}>
                            <Map color='secondary' className='accordion-icon' />
                            <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('buildings.projectPosition')}{' #4'}</Typography>
                        </div>
                        {expanded.indexOf(4) <= -1 && <ChevronLeft />}
                        {expanded.indexOf(4) > -1 && <ExpandMore />}
                    </div>
                    {/* <Map color='secondary' className='accordion-icon' />
                    <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('buildings.projectPosition')}{' #4'}</Typography> */}
                    {/*  {(notes !== undefined && notes !== '') && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />} */}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '8px' }}>
                        <Grid container spacing={3} sx={{ pointerEvents: permissions && permissions.buildingsEdit ? 'all' : 'none' }}>
                            <Grid item xs={12}>
                                <GoogleMap4Scene isLoaded={isLoaded} projectData={projectData} selected={selected4} setSelected={setSelected4} setProjectData={setProjectData} />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField label={t('buildings.gpsCoordinatesX')} variant="outlined" fullWidth size='small'
                                            name='gpsCoordinatesX'
                                            value={projectData?.gpsX4 !== undefined ? projectData?.gpsX4 : ""}
                                            onChange={(e) => { setProjectData({ ...projectData, gpsX4: Number(e.target.value) ? Number(e.target.value) : undefined }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label={t('buildings.gpsCoordinatesY')} variant="outlined" fullWidth size='small'
                                            name='gpsCoordinatesY'
                                            value={projectData?.gpsY4 !== undefined ? projectData?.gpsY4 : ""}
                                            onChange={(e) => { setProjectData({ ...projectData, gpsY2: Number(e.target.value) ? Number(e.target.value) : undefined }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label={t('buildings.gpsDeviationInMetres')} variant="outlined" fullWidth size='small'
                                            name='gpsDeviationInMetres'
                                            value={projectData?.gpsDiff4 !== undefined ? projectData?.gpsDiff4 : 0}
                                            onChange={(e) => { setProjectData({ ...projectData, gpsDiff4: Number(e.target.value) ? Number(e.target.value) : 0 }) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox size='small' sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={projectData?.gpsActive4} onChange={(e, checked) => { setProjectData({ ...projectData, gpsActive4: checked }) }} />}
                                            label={<Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>{t('active')}</Typography>}
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {isLoaded && <GoogleMaps isLoaded={isLoaded} valueSelected={(e) => { if (e && e.description) handleSelect4(e.description) }} />}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button startIcon={<GpsFixed />} fullWidth variant="contained" onClick={handleGetUserLocation4}>{t('MyGPSLocation')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
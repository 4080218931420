import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AddCircleOutline, Check, Forum, RateReview } from '@mui/icons-material';
import './Styles/DiaryMainScene.css'
import { useLocation, useNavigate, useParams } from 'react-router';
import WeatherWidget from './DiaryComponents/Widgets/WeatherWidget';
import WorkersWidget from './DiaryComponents/Widgets/WorkersWidget';
import CompletedWorksWidget from './DiaryComponents/Widgets/CompletedWorksWidget';
import { GetDailyRecordsForUserAndDiaryByRecordIdResponse, GetDiaryRecordDataNotTablesResponse, GroupAdminDTO, GroupDTO, RequestsDTO, SubDiaryDTO } from '../../ApiOld/ApiServerVov';
import { Alert, AlertTitle, Box, Button, Grid, Slide, Snackbar, } from '@mui/material';
import { ChangeEventArgs, HtmlEditor, Image as RTImage, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { useTranslation } from 'react-i18next';
import '../Scenes/DiaryComponents/Styles/DiaryMainScene.css';
import { useMediaPredicate } from 'react-media-hook';
import { unstable_usePrompt } from 'react-router-dom';
import { Timeline } from './DiaryComponents/Widgets/Timeline';
import { DiaryTimelineModel } from './DiaryComponents/Models/DiaryTimelineModel';
import moment from 'moment';
import withLoading, { WithLoadingProps } from '../Shared/Loader';
import MaterialsWidget from './DiaryComponents/Widgets/MaterialsWidget';
import MachinesWidget from './DiaryComponents/Widgets/MachinesWidget';
import NoDataImageHolder from '../Shared/Layouts/NoDataImageHolder';
import DiarySearchDialog from './DiaryComponents/DiarySearchDialog';
import DiaryRecordCopyDialog from './DiaryComponents/DiaryRecordCopyDialog';
import ReallyLockUnlockDialog from './DiaryComponents/Widgets/ReallyLockUnlockDiaryRecord';
import SignaturesWidget from './DiaryComponents/Widgets/SignaturesWidget';
import { Accordion, AccordionDetails, AccordionSummary } from './DiaryComponents/Styles/AccordionStyles';
import PhotoWidget from './DiaryComponents/Widgets/PhotoWidget';
import DiaryPrintDialog from './DiaryComponents/DiaryPrints/DiaryPrintDialog';
import InvestorChatWidget from './DiaryComponents/Widgets/InvestorChatWidget';
import SelectGroupDialog from './DiaryComponents/Widgets/SelectGroupDialog';
import TasksInDiaryWidget from './DiaryComponents/Widgets/TasksInDiaryWidget';
import AttachmentsWidget from './DiaryComponents/Widgets/AttachmentsWidget';
import { ApiDiaryBaseHelper } from '../../Api/ApiDiaryBaseHelper';
import SubcontractorChatWidget from './DiaryComponents/Widgets/SubcontractorChatWidget';
import MainContractorChatWidget from './DiaryComponents/Widgets/MainContractorChatWidget';
import CreateUserDialog from './DiaryComponents/Widgets/CreateUserDialog';
import CompletedWorksWidgetOld from './DiaryComponents/Widgets/CompletedWorksWidgetOld';
import LogWidget from './DiaryComponents/Widgets/LogWidget';
import saveBlob from '../Shared/Files/SaveBlob';
import InvestorTaskCreateDialog from '../Investor/Components/InvestorTaskCreateDialog';
import MessageDialog from '../Shared/MessageDialog';
import SignaturesUserInfoDialog from './DiaryComponents/Signatures/SignaturesUserInfoDialog';
import DiaryUsersPrintDialog from './DiaryComponents/DiaryPrints/DiaryUsersPrintDialog';
import { useUserLayoutContext } from '../../Contexts/Providers/UserLayoutMenuProvider';
import DailyRecordMenuButtons from './DiaryComponents/Menu/DailyRecordMenuButtons';
import GridImageGallery from './DiaryComponents/GridImageGallery';
import { useApi } from '../../ApiOld/Api/useApi';
import { DiaryCommonVovClient, SubcontractorVovClient } from '../../ApiOld/Api/ApiServerVov';
import { useDiaryTabsContext } from '../../Contexts/Providers/DiaryTabsProvider';


interface DiaryMainSceneProps extends WithLoadingProps {
    buildingDiaryRecordIdProps?: number;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    fromExternal?: boolean;
    displayAsTabs?: boolean;
    setDisplayAsTabs?: (e: boolean) => void;
}

function DiaryMainScene(props: DiaryMainSceneProps) {
    const [photosUrl, setPhotosUrl] = useState<string[]>([]);
    const [expanded, setExpanded] = React.useState<number[]>([]);
    const completedWorks = React.useRef<string | undefined>();
    const [completedWorksConcurrency, setCompletedWorksConcurrency] = React.useState<string | undefined>();
    const [materials, setMaterials] = React.useState<string | undefined>();
    const [machines, setMachines] = React.useState<string | undefined>();
    const [weatherFallback, setWeatherFallback] = React.useState<string | undefined>();
    const [workersFallback, setWorkersFallback] = React.useState<string | undefined>();
    const [notes, setNotes] = React.useState<string | undefined>();
    const [buildingName, setBuildingName] = React.useState<string | undefined>();
    const [buildingId, setBuildingId] = React.useState<number | undefined>();
    const [diaryName, setDiaryName] = React.useState<string | undefined>();
    const [recordId, setRecordId] = React.useState<number | undefined>(undefined);
    const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(undefined);
    const [diaryId, setDiaryId] = React.useState<number | undefined>(undefined);
    const [subcontractorPrintId, setSubcontractorPrintId] = React.useState<number | undefined>(undefined);
    const [saveGrids, setSaveGrids] = React.useState<boolean>(false);
    const [printOpen, setPrintOpen] = React.useState<boolean>(false);
    const [refreshLog, setRefreshLog] = React.useState<boolean>(false);
    const [saveButonDisabled, setSaveButonDisabled] = React.useState<boolean>(false);
    const [refreshSignatures, setRefreshSignatures] = React.useState<boolean>(false);
    const [reloadTasks, setReloadTasks] = React.useState<boolean>(false);
    const [dataChanged, setDataChanged] = React.useState<boolean>(false);
    const [dataToSaveChanged, setDataToSaveChanged] = React.useState<boolean>(false);
    const [workersChanged, setWorkersChanged] = React.useState<boolean>(false);
    const [weathersChanged, setWeathersChanged] = React.useState<boolean>(false);
    const [materialsChanged, setMaterialsChanged] = React.useState<boolean>(false);
    const [machinesChanged, setMachinesChanged] = React.useState<boolean>(false);
    const [signDigitally, setSignDigitally] = React.useState<boolean>(false);
    const [printOnlyMine, setPrintOnlyMine] = React.useState<boolean>(false);
    const [subcontractorDialogOpen, setSubcontractorDialogOpen] = React.useState<boolean>(false);
    const [isDataValid, setIsDataValid] = React.useState<boolean>(true);
    const [showDashboard, setShowDashboard] = React.useState<boolean>(false);
    const [subdiaries, setSubdiaries] = React.useState<SubDiaryDTO[]>([]);
    const groups = React.useRef<GroupDTO[]>([]);
    const privateContents = React.useRef<GroupAdminDTO[]>([]);
    const [showGroups, setShowGroups] = React.useState<boolean>(true);
    const [showWorkHours, setShowWorkHours] = React.useState<boolean>(true);
    const [saveTimeStamp, setSaveTimeStamp] = React.useState<number | undefined>(undefined);
    const [searchText, setSearchText] = React.useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const [copyOpen, setCopyOpen] = useState(false);
    const [investorNewTaskOpen, setInvestorNewTaskOpen] = useState(false);
    const [createUserOpen, setCreateUserOpen] = useState(false);
    const [lockDialogOpen, setLockDialogOpen] = useState(false);
    const [locked, setLocked] = useState(false);
    const [readOnlySubcontractor, setReadOnlySubcontractor] = useState(false);
    const [records, setRecords] = useState<DiaryTimelineModel[]>([]);
    const [currentDate, setCurrentDate] = useState<Date>();
    const [refresh, setRefresh] = useState(false);
    const [messageOpen, setMessageOpen] = useState<boolean>(false);
    const [printOthersDiaryOpen, setPrintOthersDiaryOpen] = useState<boolean>(false);
    const [copyCompletedWork, setCopyCompletedWork] = useState<string>('');
    const [printing, setPrinting] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [isSigniDialogOpen, setIsSigniDialogOpen] = useState(false);
    const { buildingDiaryRecordIdProps, fireLoading, stopLoading, isInvestor, fromExternal, isSubdodavatel, displayAsTabs, setDisplayAsTabs } = props;
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const { setBackMenuFunction, setMenuButtons } = useUserLayoutContext();
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const { authorizedApi } = useApi(DiaryCommonVovClient);
    const { authorizedSubdodavatelApi } = useApi(SubcontractorVovClient);
    const handleChange = useCallback(
        (panel: number, newExpanded: boolean) => {
            if (newExpanded)
                setExpanded([...expanded, panel]);
            else {
                setExpanded(expanded.filter(t => t !== panel));
            }
        }, [expanded, setExpanded]);

    const beforeUnLoad = (e: any) => {
        if (dataChanged) {
            return e.returnValue = 'Really want to leave?';
        }
    }

    useEffect(() => {
        if (dataToSaveChanged || workersChanged || weathersChanged || materialsChanged || machinesChanged)
            setDataChanged(true);
        else setDataChanged(false);
    }, [dataToSaveChanged, workersChanged, weathersChanged, machinesChanged, materialsChanged]);

    useEffect(() => {
        if (!dataChanged || locked || readOnlySubcontractor) {
            setWorkersChanged(false);
            setWeathersChanged(false);
            setMaterialsChanged(false);
            setMachinesChanged(false);
            setDataToSaveChanged(false);
        }
        else {
            window.addEventListener('beforeunload', beforeUnLoad);
            return () => {
                window.removeEventListener('beforeunload', beforeUnLoad);
            };
        }

    }, [dataChanged]);

    unstable_usePrompt({ message: 'Opravdu chcete opustit stránku bez uložení?', when: dataChanged });

    function handleStateFullLoad(): DiaryTimelineModel[] | undefined {
        setRecordId(loc.state["id"]);
        setBuildingName(loc.state["buildingName"]);
        setDiaryName(loc.state["diaryName"]);
        setDiaryId(loc.state["diaryId"]);
        if (loc.state["inputRecords"] !== undefined && loc.state["inputRecords"] !== null) {
            var newRecords: DiaryTimelineModel[] = [];
            loc.state["inputRecords"].reverse().forEach((element: any) => {
                if (element.diaryId === loc.state["diaryId"]) {
                    newRecords.push({
                        date: moment(element.date).format('MM-DD-YYYY'), dateOrigin: element.date, isFilled: element.isFilled,
                        hasCompletedWorks: element.hasCompletedWorksRecords, hasPhotos: element.hasPhotos, hasWeathers: element.hasWeatherRecords,
                        hasWorkers: element.hasWorkerRecords, signatureType: element.signatureType, recordId: element.id, isLocked: element.isLocked
                    });
                }
            });
            return newRecords;
        }
    }
    const loadDataStatelessTimeline = (id: number, d: GetDailyRecordsForUserAndDiaryByRecordIdResponse) => {
        setRecordId(id);
        setBuildingName(d.buildingName);
        setDiaryId(d.diaryId);
        setDiaryName(d.diaryName);
        setCurrentDate(d.diaryDate);
        if (d.viewerRecords !== undefined && d.viewerRecords !== null) {
            var newRecords: DiaryTimelineModel[] = [];
            d.viewerRecords.reverse().forEach((element: any) => {
                newRecords.push({
                    date: moment(element.date).format('MM-DD-YYYY'), dateOrigin: element.date, isFilled: element.isFilled,
                    hasCompletedWorks: element.hasCompletedWorksRecords, hasPhotos: element.hasPhotos, hasWeathers: element.hasWeatherRecords,
                    hasWorkers: element.hasWorkerRecords, signatureType: element.signatureType, recordId: element.id, isLocked: element.isLocked
                });
            });
            setRecords(newRecords);
        }
    }
    const handleStateLessLoad = useCallback((id: number) => {
        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetRecordsForViewingStateless(id).then((d) => {
            loadDataStatelessTimeline(id, d);
        });
    }, [isInvestor, isSubdodavatel]);

    useEffect(() => {
        if (saveGrids)
            setSaveGrids(false);
    }, [saveGrids]);

    useEffect(() => {
        if (subcontractorPrintId) {
            setSignDigitally(false);
            setPrintOnlyMine(false);
            setPrintOpen(true);
        }
    }, [subcontractorPrintId]);

    const navigateBack = () => {
        if (isSubdodavatel === true) {
            navigate('/' + i18n.language + '/' + params['domain']! + '/subcontractor/main/diaries');
        } else if (isInvestor === undefined || isInvestor === false) {
            navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/diary/diaryvov');
        } else {
            navigate('/' + i18n.language + '/' + params['domain']! + '/investor/main/tabs/diary');
        }
    }
    const { refreshDailyRecords, permissions, refreshPermissions, investorPermissions, refreshInvestorPermissions } = useDiaryTabsContext();
    useEffect(() => {
        setBackMenuFunction(() => () => { navigateBack(); })
        fireLoading!();

        if (buildingDiaryRecordIdProps !== undefined) {
            setRecordId(buildingDiaryRecordIdProps);
        }
        else if (loc.state !== undefined && loc.state !== null) {
            var stateLoaded = handleStateFullLoad();
            if (stateLoaded)
                setRecords(stateLoaded);

        }
        else if (params['recordId'] !== undefined) {
            setRecordId(parseInt(params['recordId']));
            handleStateLessLoad(parseInt(params['recordId']));

        }

        if (permissions === null && (isInvestor === undefined || isInvestor === false))
            refreshPermissions(isSubdodavatel === undefined ? false : isSubdodavatel);
        else if (investorPermissions === null && (isInvestor === true))
            refreshInvestorPermissions();
    }, []);

    useEffect(() => {
        if (buildingDiaryRecordIdProps !== undefined)
            setRecordId(buildingDiaryRecordIdProps);
        else if (loc.state !== undefined && loc.state !== null) {
            var stateLoaded = handleStateFullLoad();
            if (stateLoaded)
                setRecords(stateLoaded);
        }
        else if (params['recordId'] !== undefined) {
            setRecordId(parseInt(params['recordId']));
            handleStateLessLoad(parseInt(params['recordId']));
        }
    }, [buildingDiaryRecordIdProps, loc, params]);


    function reloadData() {
        fireLoading!();
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }
        if (buildingDiaryRecordIdProps !== undefined) {
            setRecordId(buildingDiaryRecordIdProps);
        }
        else if (loc.state !== undefined && loc.state !== null) {
            var stateLoaded = handleStateFullLoad();
            if (stateLoaded)
                setRecords(stateLoaded);
        }
        else if (params['recordId'] !== undefined) {
            setRecordId(parseInt(params['recordId']));
            handleStateLessLoad(parseInt(params['recordId']));

        }
        if (permissions === null && (isInvestor === undefined || isInvestor === false))
            refreshPermissions(isSubdodavatel === undefined ? false : isSubdodavatel);
        else if (investorPermissions && (isInvestor === true))
            refreshInvestorPermissions();
    }

    function setDefaultState() {
        setSaveTimeStamp(0);
        completedWorks.current = undefined;
        setMaterials('');
        setMachines('');
        setWeatherFallback('');
        setWorkersFallback('');
        setNotes('');
        //setSubdiaries([]);
        groups.current = [];
        privateContents.current = [];
    }
    const getContentsToSave = useCallback((): SubDiaryDTO[] | GroupAdminDTO[] | undefined => {
        if (isSubdodavatel !== true && (subdiaries === null || subdiaries.length === 0)) {
            let admins = groups.current.filter(a => a !== undefined).flatMap(a => a.members!);
            return admins.filter(x => x !== undefined && x.changed);
        } else {
            let admins = privateContents.current.filter(a => a !== undefined).map(a => a);
            return admins.filter(x => x !== undefined && x.changed);
        }
    }, [isSubdodavatel, subdiaries, groups, privateContents]);

    function setDataAfterReload(data: GetDiaryRecordDataNotTablesResponse) {
        setLocked(data.isLocked!);
        setReadOnlySubcontractor(data.isReadOnly ? data.isReadOnly : false);
        setSaveTimeStamp(data.timeStamp);
        setBuildingId(data.buildingId);
        completedWorks.current = data.completedWork;
        setMaterials(data.materials);
        setMachines(data.machines);
        setWeatherFallback(data.weather);
        setWorkersFallback(data.workers);
        setNotes(data.additionalNotes);
        if (data.subdiaries)
            setSubdiaries(data.subdiaries);
        if (data.groups)
            groups.current = data.groups;
        if (data.privateContents)
            privateContents.current = data.privateContents;
        if (data.showWorkHours !== undefined)
            setShowWorkHours(data.showWorkHours);
        else setShowWorkHours(false);
        if (data.showEmployeesGroup !== undefined)
            setShowGroups(data.showEmployeesGroup);
        else setShowGroups(false);

        setRefreshSignatures(true);
    }

    const refreshDiary = useCallback(() => {
        setIsDataValid(true);
        setCopyCompletedWork('');
        fireLoading!();
        setDefaultState();
        if (recordId) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetFullDailyRecordNotTables(recordId, false).then((data) => {
                stopLoading!();
                setDataAfterReload(data);
            }).catch(() => { stopLoading!(); setIsDataValid(false); });
        }
        else {
            stopLoading!();
        }
    }, [fireLoading, isInvestor, isSubdodavatel, recordId, stopLoading]);

    const loadTodayPhotos = useCallback(() => {
        if (recordId) {
            isSubdodavatel !== true ?
                authorizedApi.getPhotosBuildingToChoose(recordId, true).then((imgs) => {
                    if (imgs.images) {
                        const filteredPhotos: string[] = imgs.images
                            .map((e) => e.url) as string[];

                        setPhotosUrl(filteredPhotos);
                    }
                })
                    .catch((e) => {
                        setPhotosUrl([]);
                    })
                :
                authorizedSubdodavatelApi.getPhotosBuildingToChooseSubcontractor(recordId, true).then((imgs) => {
                    if (imgs.images) {
                        const filteredPhotos: string[] = imgs.images
                            .map((e) => e.url) as string[];

                        setPhotosUrl(filteredPhotos);
                    }
                })
                    .catch((e) => {
                        setPhotosUrl([]);
                    });
        }
    }, [recordId, isSubdodavatel, authorizedApi, authorizedSubdodavatelApi]);

    useEffect(() => {
        if (recordId)
            refreshDiary();
    }, [recordId]);

    useEffect(() => {
        if (recordId)
            loadTodayPhotos();
    }, [recordId, loadTodayPhotos]);

    const saveDiary = useCallback((navigateAfter: boolean) => {
        fireLoading!();
        setDataChanged(false);
        setDataToSaveChanged(false);
        setSaveGrids(true);
        if (recordId && saveTimeStamp !== undefined) {
            setLoading(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).saveDiaryContents(recordId, saveTimeStamp, completedWorks.current, machines, materials, notes, getContentsToSave()).then((e) => {
                if (isSubdodavatel === true) {
                    if (e && e.ok) {
                        refreshDiary();
                        setCompletedWorksConcurrency(undefined);
                        handleStateLessLoad(recordId);
                        setDataChanged(false);
                        setDataToSaveChanged(false);
                        if (navigateAfter)
                            navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/main/diaries');
                    }
                    else if (e && e.ok === false && e.errors === 'update concurrency') {
                        setCompletedWorksConcurrency(e.actualCompletedWorks);
                        if (e.actualTimeStamp)
                            setSaveTimeStamp(e.actualTimeStamp);
                    }
                    refreshDailyRecords();
                }
                else {
                    if (e && e.ok) {
                        refreshDiary();
                        setCompletedWorksConcurrency(undefined);
                        handleStateLessLoad(recordId);
                        setDataChanged(false);
                        setDataToSaveChanged(false);
                        if (navigateAfter) {
                            if (fromExternal === undefined || fromExternal === false)
                                navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryvov');
                            else
                                navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryolderpnew');
                        }
                    }
                    else if (e && e.ok === false && e.errors === 'update concurrency') {
                        setCompletedWorksConcurrency(e.actualCompletedWorks);
                        if (e.actualTimeStamp)
                            setSaveTimeStamp(e.actualTimeStamp);
                    }
                    refreshDailyRecords();
                }
                setRefreshLog(refreshLog == true ? false : true);
                setSaveButonDisabled(false);
                setLoading(false);
            }).catch(() => { setSaveButonDisabled(false); setLoading(false); });
        }
    }, [fromExternal, getContentsToSave, handleStateLessLoad, isInvestor, isSubdodavatel, machines, materials, navigate, notes, params, recordId, refreshDiary, refreshLog, saveTimeStamp, refreshDailyRecords]);

    const theme = useTheme();

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            setSearchOpen(true);
        }
    }

    const handleMenuItemClick = useCallback((event: number) => {
        if (event === 0) {
            setLockDialogOpen(true);
        }
        else if (event === 1) {
            setCopyOpen(true);
        }
        else if (event === 2) {
            setExpanded([]);
        }
        else if (event === 3) {
            setExpanded([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
        }
        else if (event === 4) {
            if (isSubdodavatel === true) {
                setCreateUserOpen(true);
            }
            else if (recordId) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).RingOthersDiary(recordId).then(() => { setSnackBarOpen(true) });
            }
        }
        else if (event === 5) {
            setCreateUserOpen(true);
        }
    }, [recordId, isSubdodavatel, isInvestor]);

    function searchClosed() { setSearchOpen(false); }
    function createUserClosed() { setCreateUserOpen(false); }
    function copyClosed(refresh: boolean) { setCopyOpen(false); if (refresh) { reloadData(); } setRefresh(true); }
    useEffect(() => {
        if (refresh)
            setRefresh(false);
    }, [refresh]);

    const onTiskInvestorClick = useCallback((e: number) => {
        if (!recordId)
            return;
        let formattedDate = "";
        if (selectedDate) {
            formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
        }
        // TISK SDILENEHO ZAZNAMU
        if (e === 0) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, false, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK PRO DIGITALNI PODPIS
        else if (e === 1) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, true, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK CELÉHO DENÍKU
        else if (e === 2) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, false, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, false, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, false, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
        // TISK CELEHO DENIKU PRO DIG PODPIS
        else if (e === 3) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, true, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, true, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, true, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
        // TISK CELEHO DENIKU S PŘÍLOHAMI
        else if (e === 4) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, false, true).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, false, true, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, false, true, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
        else if (e === 5) {
            setLoading(true);
            if (diaryId) {
                new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, false, true, 0).then((d) => {
                    if (d) {
                        saveBlob(d.data, diaryName + ".pdf");
                    }
                    setPrinting(false);
                });
            }
        }
    }, [diaryId, diaryName, isInvestor, isSubdodavatel, recordId, selectedDate]);

    const onTiskUserClick = useCallback((e: number) => {
        if (!recordId)
            return;

        let formattedDate = "";
        if (selectedDate) {
            formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
        }

        // TISK SDILENEHO ZAZNAMU
        if (e === 0) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, false, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK PRO DIGITALNI PODPIS
        else if (e === 1) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, true, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK MYCH ZAZNAMU
        else if (e === 2) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiaryRecord(recordId, true, false, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK MYCH ZAZNAMU
        else if (e === 3) {
            setPrintOthersDiaryOpen(true);
        }
        // TISK CELEHO DENIKU
        else if (e === 4) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, false, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, false, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, false, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }

        }
        else if (e === 5) {
            setIsSigniDialogOpen(true);
        }
        else if (e === 6) {
            setPrinting(true);
            if (diaryId)
                new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintTitlePage(diaryId, false, false, false, 0).then((d) => {
                    if (d) {
                        saveBlob(d.data, diaryName + ".pdf");
                    }
                    setPrinting(false);
                });
        }
    }, [recordId, diaryId, diaryName, isInvestor, isSubdodavatel, selectedDate]);

    const onTiskClick = useCallback((e: number) => {
        if (isInvestor === true)
            onTiskInvestorClick(e);
        else onTiskUserClick(e);
    }, [isInvestor, onTiskInvestorClick, onTiskUserClick]);

    const handleBuildingItemClick = useCallback(() => {
        navigate(`/${params['lang']!}/${params['domain']!}/userlayout/projectDetail/${buildingId}`)
    }, [buildingId, navigate, params])

    useEffect(() => {
        setMenuButtons(<DailyRecordMenuButtons
            handleMenuItemClick={handleMenuItemClick}
            isInvestor={isInvestor ?? false}
            saveDiary={saveDiary}
            locked={locked}
            readOnlySubcontractor={readOnlySubcontractor}
            loading={loading}
            saveButtonDisabled={saveButonDisabled}
            isSubdodavatel={isSubdodavatel ?? false}
            onTiskClick={onTiskClick}
            printing={printing}
            buildingName={buildingName}
            onBuildingNameClick={handleBuildingItemClick}
            diaryName={diaryName}
            setSearchOpen={setSearchOpen}
            setSearchText={setSearchText}
            handleKeyDown={handleKeyDown}
            permissions={permissions}
            investorPerm={investorPermissions}
            displayAsTabs={displayAsTabs ?? false}
            setDisplayAsTabs={setDisplayAsTabs ?? (() => { })} />);
    }, [permissions, buildingName, diaryName, isInvestor, isSubdodavatel, loading, locked, printing, displayAsTabs, readOnlySubcontractor, saveButonDisabled, investorPermissions, setDisplayAsTabs, setMenuButtons, handleMenuItemClick, onTiskClick, saveDiary, handleBuildingItemClick]);
    var toolbarSettings: object = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|', 'Undo', 'Redo'
        ],
        type: 'Expand'
    }
    return (
        <div>
            <Grid sx={{ background: 'white' }} container spacing={0} direction={'row'}>
                <Grid sx={{ maxWidth: '100vw' }} item xs={12} md={7} lg={8}>
                    <Box sx={{
                        minHeight: '40px', zIndex: 5000, marginY: 2, pl: 2, pr: 4
                    }}>
                        <Timeline disabled={dataChanged} inputRecordId={recordId}
                            isInvestor={isInvestor !== undefined && isInvestor === true}
                            isSubdodavatel={isSubdodavatel === undefined ? false : isSubdodavatel}
                            onSelected={(e, d) => {
                                setSelectedDate(d);
                                fireLoading!();
                                if (params['recordId'] === e?.toString()) {
                                    stopLoading!();
                                }

                                if (e) {
                                    if (isSubdodavatel)
                                        navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/main/diaries/' + e);
                                    else
                                        navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryvovdetail/' + e);
                                }
                                else {
                                    setRecordId(e);
                                    stopLoading!();
                                }
                            }}
                            inputRecords={records}
                            fromExternal={fromExternal}
                        />
                    </Box>
                    {recordId !== undefined &&
                        <Box sx={{ px: 2 }}>
                            <div>
                                {isDataValid && !showDashboard
                                    && <Box sx={{ marginX: '12px', mb: 4 }}>

                                        <SignaturesWidget
                                            onExpandChange={(ex) => { { handleChange(0, ex) } }}
                                            onSigniClicked={() => { setIsSigniDialogOpen(true) }}
                                            refresh={refreshSignatures}
                                            onRefreshed={() => setRefreshSignatures(false)}
                                            isInvestor={isInvestor}
                                            isSubdodavatel={isSubdodavatel}
                                            isExpanded={expanded.indexOf(0) > -1}
                                            recordId={recordId}
                                            isLocked={locked}
                                            onSigned={() => { handleStateLessLoad(recordId); setRefreshLog(refreshLog === true ? false : true); }}
                                        />
                                        {isSubdodavatel !== true && (subdiaries === null || subdiaries.length === 0) ? <CompletedWorksWidgetOld
                                            onExpandChange={(ex) => handleChange(1, ex)}
                                            copyCompletedWork={copyCompletedWork}
                                            concurrency={completedWorksConcurrency}
                                            isInvestor={isInvestor}
                                            isCopying={false}
                                            showGroups={true}
                                            buildingDiaryRecordId={recordId} groups={groups.current}
                                            onHtmlChanged={(text, group, member) => {
                                                if (group === null || group === undefined || member === null || member === undefined) {
                                                    if (text !== completedWorks.current)
                                                        setDataToSaveChanged(true);
                                                    completedWorks.current = text;
                                                }
                                                else {
                                                    let groupNew = groups.current.find(x => x.groupId === group.groupId);
                                                    if (groupNew && groupNew.members) {
                                                        let memberRemove = groupNew.members.find(x => x.userId === member.userId);

                                                        if (memberRemove) {
                                                            if (text !== memberRemove.content)
                                                                setDataToSaveChanged(true);
                                                            let gruopToUse = new GroupDTO({
                                                                groupId: groupNew.groupId, groupName: groupNew.groupName,
                                                                members: [...groupNew.members!.filter(x => x.userId !== member.userId), new GroupAdminDTO({ content: text, name: memberRemove!.name, userId: memberRemove!.userId, contentId: memberRemove!.contentId, changed: true })]
                                                            })
                                                            groups.current = [...groups.current.filter(x => x.groupId !== group.groupId), gruopToUse];
                                                        }
                                                    }

                                                }
                                            }}
                                            completedWorksInput={completedWorks.current}
                                            isExpanded={expanded.indexOf(1) > -1} />
                                            :
                                            <CompletedWorksWidget
                                                onExpandChange={(ex) => handleChange(1, ex)}
                                                copyCompletedWork={copyCompletedWork}
                                                concurrency={completedWorksConcurrency}
                                                isInvestor={isInvestor}
                                                isSubdodavatel={isSubdodavatel}
                                                isCopying={false}
                                                showSubdiaries={true}
                                                buildingDiaryRecordId={recordId} subdiaries={subdiaries} privateContents={privateContents.current}
                                                onHtmlChanged={(text, privContent) => {
                                                    if (privContent === null || privContent === undefined) {
                                                        if (text !== completedWorks.current)
                                                            setDataToSaveChanged(true);
                                                        completedWorks.current = text;
                                                    }
                                                    else if (privContent !== null && privContent !== undefined) {
                                                        let contNew = privateContents.current.find(x => x.contentId === privContent.contentId);
                                                        if (contNew) {
                                                            if (text !== contNew.content)
                                                                setDataToSaveChanged(true);

                                                            let contToUse = new GroupAdminDTO({
                                                                contentId: contNew.contentId, name: contNew.name,
                                                                content: text ? text : '', userId: contNew.userId,
                                                                changed: true
                                                            })
                                                            privateContents.current = [...privateContents.current.filter(x => x.contentId !== privContent.contentId), contToUse]
                                                        }
                                                    }
                                                }}
                                                completedWorksInput={completedWorks.current}
                                                isExpanded={expanded.indexOf(1) > -1}
                                            />
                                        }
                                        <PhotoWidget
                                            onExpandChange={(ex) => handleChange(2, ex)}
                                            isInvestor={isInvestor}
                                            isSubdodavatel={isSubdodavatel}
                                            isLocked={locked || readOnlySubcontractor}
                                            recordId={recordId}
                                            isExpanded={expanded.indexOf(2) > -1}
                                            domain={params['domain']}
                                            recordDate={currentDate}
                                            onCopy={(text) => { setCopyCompletedWork(text); }}
                                        />
                                        <AttachmentsWidget
                                            onExpandChange={(ex) => handleChange(3, ex)}
                                            isInvestor={isInvestor}
                                            isSubdodavatel={isSubdodavatel}
                                            isLocked={locked || readOnlySubcontractor}
                                            isExpanded={expanded.indexOf(3) > -1}
                                            recordId={recordId}
                                        />
                                        <WeatherWidget
                                            onExpandChange={(ex) => handleChange(4, ex)}
                                            date={currentDate}
                                            isInvestor={isInvestor}
                                            isSubdodavatel={isSubdodavatel}
                                            onSaved={() => { setWeathersChanged(false); setRefreshLog(refreshLog === true ? false : true); }}
                                            onSaveChanged={() => { setWeathersChanged(true) }}
                                            isLocked={locked || readOnlySubcontractor}
                                            saveChanged={saveGrids}
                                            isExpanded={expanded.indexOf(4) > -1}
                                            buildingDiaryRecordId={recordId}
                                            weatherNote={weatherFallback}
                                            noteChanged={(e) => {
                                                setDataToSaveChanged(true);
                                                setWeatherFallback(e);
                                            }}
                                        />
                                        <WorkersWidget
                                            onExpandChange={(ex) => handleChange(5, ex)}
                                            isInvestor={isInvestor}
                                            isSubdodavatel={isSubdodavatel}
                                            buildingName={buildingName}
                                            date={selectedDate}
                                            isLocked={locked || readOnlySubcontractor}
                                            isCopying={false}
                                            workersNote={workersFallback}
                                            noteChanged={(e) => {
                                                setDataToSaveChanged(true);
                                                setWorkersFallback(e);
                                            }}
                                            onChanged={() => { setWorkersChanged(false); }}
                                            onChangeToSave={() => { setWorkersChanged(true); }}
                                            onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                                            saveChanged={saveGrids}
                                            isExpanded={expanded.indexOf(5) > -1}
                                            buildingDiaryRecordId={recordId}
                                            showGroups={showGroups}
                                            showWorkHours={showWorkHours}
                                            refresh={refresh}
                                        />
                                        <MaterialsWidget
                                            onExpandChange={(ex) => handleChange(6, ex)}
                                            currentDate={currentDate ? currentDate : new Date()}
                                            buildingDiaryId={diaryId}
                                            isInvestor={isInvestor}
                                            isSubdodavatel={isSubdodavatel}
                                            isLocked={locked || readOnlySubcontractor}
                                            saveChanged={saveGrids}
                                            isCopying={false}
                                            materialsNote={materials}
                                            onChanged={() => { setMaterialsChanged(false); }}
                                            onChangeToSave={() => { setMaterialsChanged(true) }}
                                            onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                                            noteChanged={(e) => {
                                                setDataToSaveChanged(true);
                                                setMaterials(e);
                                            }}
                                            buildingDiaryRecordId={recordId}
                                            isExpanded={expanded.indexOf(6) > -1}
                                            refresh={refresh}
                                        />
                                        <MachinesWidget
                                            onExpandChange={(ex) => handleChange(7, ex)}
                                            isInvestor={isInvestor}
                                            isSubdodavatel={isSubdodavatel}
                                            isLocked={locked || readOnlySubcontractor}
                                            noteChanged={(e) => {
                                                setDataToSaveChanged(true);
                                                setMachines(e);
                                            }}
                                            onChanged={() => { setMachinesChanged(false); }}
                                            onChangeToSave={() => { setMachinesChanged(true) }}
                                            onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                                            saveChanged={saveGrids}
                                            isCopying={false}
                                            machinesNote={machines}
                                            buildingDiaryRecordId={recordId}
                                            isExpanded={expanded.indexOf(7) > -1}
                                            refresh={refresh}
                                        />


                                        <Accordion style={{ margin: '0px' }} className='expander' expanded={expanded.indexOf(8) > -1} onChange={(e, ex) => handleChange(8, ex)}>
                                            <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header' aria-controls="panel6d-content" id="panel6d-header">
                                                <RateReview color='secondary' className='accordion-icon' />
                                                <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('diary.anotherNotes')}</Typography>
                                                {(notes !== undefined && notes !== '') && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />}
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ p: 0 }}>
                                                {(!isInvestor || (isInvestor && investorPermissions && investorPermissions.buildingDiaryRecordsEdit === true)) && <RichTextEditorComponent toolbarSettings={toolbarSettings} saveInterval={100} height={400} locale={i18n.language} value={notes} change={(eve: ChangeEventArgs) => {
                                                    if (notes !== eve.value) {
                                                        setNotes(eve.value);
                                                        setDataToSaveChanged(true);
                                                    }
                                                }} >
                                                    <Inject services={[Toolbar, RTImage, Link, HtmlEditor, QuickToolbar]} />
                                                </RichTextEditorComponent>}
                                                {(isInvestor && investorPermissions && investorPermissions.buildingDiaryRecordsEdit !== true) && <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: notes ? notes : "" }} />}
                                            </AccordionDetails>
                                        </Accordion>

                                        {isSubdodavatel !== true && <Accordion style={{ margin: '0px' }} className='expander' expanded={expanded.indexOf(9) > -1} onChange={(e, ex) => handleChange(9, ex)}>
                                            <AccordionSummary sx={{ boxShadow: 'none' }} className='expander-header'>
                                                <Forum color='secondary' className='accordion-icon' />
                                                <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('dashboard.tasks')}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ p: 0 }}>
                                                <Box sx={{ pt: 2 }}>

                                                    {isInvestor === true && <Button sx={{ marginLeft: '32px', marginBottom: '4px', width: '150px' }} startIcon={<AddCircleOutline />} aria-label="create" variant='contained' size='small' onClick={(event) => {
                                                        setReloadTasks(false); setInvestorNewTaskOpen(true);
                                                    }} disabled={false} color="primary">
                                                        {t('Add')}
                                                    </Button>
                                                    }
                                                    {isInvestor !== true && <Button sx={{ marginLeft: '32px', marginBottom: '4px', width: '150px' }} startIcon={<AddCircleOutline />} aria-label="create" variant='contained' size='small' onClick={(event) => {
                                                        window.open('https://' + params['domain']! + '.vimovsem.cz/admin/requests/new', '_blank', 'noreferrer');
                                                    }} disabled={false} color="primary">
                                                        {t('Add')}
                                                    </Button>
                                                    }
                                                </Box>
                                                {recordId && <TasksInDiaryWidget isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} domain={params['domain']!} recordId={recordId} reloadData={reloadTasks} />}
                                            </AccordionDetails>
                                        </Accordion>}
                                        {((permissions && permissions.investorsComments) || (isInvestor)) && isSubdodavatel !== true && <InvestorChatWidget recordIdInput={recordId} isExpanded={expanded.indexOf(10) > -1} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} onExpandChange={(ex) => handleChange(10, ex)} />}
                                        {isSubdodavatel !== true && isInvestor !== true && (subdiaries === null || subdiaries.length > 0) && <SubcontractorChatWidget recordIdInput={recordId} isExpanded={expanded.indexOf(11) > -1} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} onExpandChange={(ex) => handleChange(11, ex)} />}
                                        {isSubdodavatel === true && <MainContractorChatWidget recordIdInput={recordId} isExpanded={expanded.indexOf(12) > -1} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} onExpandChange={(ex) => handleChange(12, ex)} />}
                                        {isInvestor !== true && isSubdodavatel !== true && permissions && permissions.canSeeChangelog && <LogWidget isExpanded={expanded.indexOf(13) > -1} refresh={refreshLog} buildingDiaryRecordId={recordId} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} onExpandChange={(ex) => handleChange(13, ex)} />}
                                    </Box>}

                            </div >
                        </Box >}
                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                    {recordId !== undefined &&
                        <Box my={3}>
                            <GridImageGallery imageUrls={photosUrl} />
                        </Box>
                    }
                </Grid>
            </Grid>

            {
                recordId === undefined &&
                <div style={{
                    display: 'flex', justifyContent: 'center', marginTop: '120px',
                    flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '300', left: 'calc(50% - 150px)'
                }}>
                    <NoDataImageHolder title={t('diary.noRecordForChosenDate')} />
                    <div style={{ display: 'flex' }}><Typography>{t('diary.site')}</Typography><Typography sx={{ fontWeight: 'bold', marginLeft: '4px' }}> {buildingName}</Typography></div>
                    <div style={{ display: 'flex' }}><Typography>{t('diary.diary')}</Typography><Typography sx={{ fontWeight: 'bold', marginLeft: '4px' }}> {diaryName}</Typography></div>

                    {permissions && permissions.canCreateDiaryRecord && <Button sx={{ margin: '20px' }} startIcon={<AddCircleOutline />} onClick={(e) => {
                        if (diaryId && selectedDate)
                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).createDiaryRecord(diaryId, selectedDate).then((d) => {
                                if (typeof d === "string") {
                                }
                                else if (typeof d === "number") {
                                    if (isSubdodavatel === true)
                                        navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/main/diaries/' + d);
                                    else
                                        navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diaryvovdetail/' + d);
                                    /* setRecordId(d);
                                    handleStateLessLoad(d); */
                                }
                            })
                    }} variant='contained'>{t('Create')}</Button>}
                </div>
            }
            {
                !isDataValid && <div style={{
                    display: 'flex', justifyContent: 'center', marginTop: '120px',
                    flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '300', left: 'calc(50% - 150px)'
                }}><NoDataImageHolder title={t('dataIncorrect')} /></div>
            }
            {recordId && <SignaturesUserInfoDialog isInvestor={isInvestor === undefined ? false : isInvestor} diaryRecordId={recordId} isOpen={isSigniDialogOpen} onClose={() => { setIsSigniDialogOpen(false) }} />}
            {recordId && <DiaryPrintDialog isInvestor={isInvestor !== undefined && isInvestor === true} isSubdodavatel={isSubdodavatel} subcontractorPrintId={subcontractorPrintId}
                signDigitally={signDigitally} onlyMine={printOnlyMine}
                recordId={recordId} open={printOpen} onClose={() => { setPrintOpen(false); setSubcontractorPrintId(undefined); }} />}
            <DiarySearchDialog isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} onRefresh={() => { }} searchText={searchText} open={searchOpen} onClose={searchClosed} diaryId={diaryId} diaryDate={undefined} fromExternal={fromExternal === true ? true : false} />
            <SelectGroupDialog groups={groups.current} open={subcontractorDialogOpen} onClose={(e) => {
                setSubcontractorDialogOpen(false);
                if (e)
                    setSubcontractorPrintId(e);
            }} />
            <DiaryRecordCopyDialog isSubdodavatel={isSubdodavatel} open={copyOpen} diaryId={diaryId} onClose={copyClosed} onRefresh={() => { }} diaryRecordId={recordId} />
            <CreateUserDialog showGroups={true} diaryId={diaryId} isSubdodavatel={isSubdodavatel} open={createUserOpen} onClose={createUserClosed} />
            <ReallyLockUnlockDialog open={lockDialogOpen} locked={locked} onClose={(e) => {
                setLockDialogOpen(false);
                if (recordId && e === true) {
                    new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).lockUnlockDiaryRecord(recordId, !locked).then((d) => { setLockDialogOpen(false); refreshDiary(); });
                }
            }} />
            <InvestorTaskCreateDialog topMargin={200} onRefresh={() => { }} todo={new RequestsDTO({ buildingId: buildingId, buildingDiaryId: diaryId })} open={investorNewTaskOpen} selectedValue={t('singleTodo.newTask')} onClose={() => { setInvestorNewTaskOpen(false); refreshDiary(); setReloadTasks(true); }} />
            <MessageDialog onClose={() => setMessageOpen(false)} text={t('diary.diaryPrintLargeText')} title={t('diary.diaryPrintLargeTitle')} open={messageOpen} />
            {diaryId && <DiaryUsersPrintDialog diaryId={diaryId} onClose={(e) => {
                setPrintOthersDiaryOpen(false);
                if (e !== undefined && e !== null && recordId) {
                    setPrinting(true);
                    new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiaryRecord(recordId, true, false, e.id!).then((d) => {
                        if (d) {
                            let formattedDate = "";
                            if (selectedDate) {
                                formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
                            }
                            saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                        }
                        setPrinting(false);
                    });
                }
            }
            } open={printOthersDiaryOpen} />}
            <Snackbar
                TransitionComponent={(propsy) => { return <Slide {...propsy} direction='left' /> }}
                open={snackBarOpen}
                autoHideDuration={5000}
                onClose={(e) => { setSnackBarOpen(false) }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }} >
                <Alert onClose={(e) => { setSnackBarOpen(false) }} severity="success">
                    <AlertTitle>{t('sales.sent')}</AlertTitle>
                    <span>{t('notificationSent')}</span>
                </Alert>
            </Snackbar>
        </div>
    );
}
export default withLoading(DiaryMainScene);

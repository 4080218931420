import { BookOnlineOutlined, ConnectWithoutContact, ConstructionOutlined, Dashboard, FileOpenOutlined, FolderOpenOutlined, FolderOutlined, Group, GroupOutlined, HelpOutline, Home, LogoutOutlined, MailOutline, Outbound, PeopleOutline, PrecisionManufacturingOutlined, QueryStatsOutlined, Refresh, Report, Settings, SettingsOutlined, Task } from '@mui/icons-material';
import {
  Avatar, Box, Button, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem
  , Tooltip, Typography, useMediaQuery, useTheme, Stack
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Drawer, DrawerHeader } from './SideMenuStyles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useLocation, useNavigate, useParams } from 'react-router';
import { StavarioModule } from './StavarioModule';
import ModuleMenuItem from './ModuleMenuItem';
import { StavarioGlobalContext } from '../../../Contexts/LoginMainContext';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { useTranslation } from 'react-i18next';
import LanguagePicker from '../LanguagePicker';
import LayoutUserMenu from './LayoutUserMenu';
import UserAvatar from './UserAvatar';
interface DrawerHeaderProps {

  onModuleChange?: (module: StavarioModule) => void;
  selectedModule: StavarioModule;
  onChangeView: () => void;
}

export default function UserLayoutModuleTopMenu(props: Readonly<DrawerHeaderProps>) {
  const { onChangeView, onModuleChange, selectedModule } = props;
  const biggerThan1250 = useMediaQuery('(min-width:1250px)');
  const navigate = useNavigate();
  // const params = useParams();
  const { modulesVisibility } = useUserLayoutContext();
  // const [selectedModule, setSelectedModule] = useState<StavarioModule>(Stava);
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;

    if (path.toLowerCase().includes('projects')) {
      if (selectedModule !== StavarioModule.ProjectList)
        onModuleChange?.(StavarioModule.ProjectList);
    }
    else if (path.toLowerCase().includes('projectdetail')) {
      if (selectedModule !== StavarioModule.ProjectDetail)
        onModuleChange?.(StavarioModule.ProjectDetail);
    } else if (path.toLowerCase().includes('diaryvov') && !path.toLowerCase().includes('diaryvovdetail')) {
      if (selectedModule !== StavarioModule.DiaryRecordsList)
        onModuleChange?.(StavarioModule.DiaryRecordsList);
    }
    else if (path.toLowerCase().includes('diaryvovdetail')) {
      if (selectedModule !== StavarioModule.DiaryRecordDetail)
        onModuleChange?.(StavarioModule.DiaryRecordDetail);
    }
    else if (path.toLowerCase().includes('diaries')) {
      if (selectedModule !== StavarioModule.DiariesList)
        onModuleChange?.(StavarioModule.DiariesList);
    }
    else if (path.toLowerCase().includes('diarydetail')) {
      if (selectedModule !== StavarioModule.DiaryDetail)
        onModuleChange?.(StavarioModule.DiaryDetail);
    }
    else if (path.toLowerCase().includes('wealthdetail')) {
      if (selectedModule !== StavarioModule.WealthDetail)
        onModuleChange?.(StavarioModule.WealthDetail);
    }
    else if (path.toLowerCase().includes('wealths')) {
      if (selectedModule !== StavarioModule.WealthList)
        onModuleChange?.(StavarioModule.WealthList);
    }
  }, [location.pathname, onModuleChange, selectedModule]);

  const params = useParams();
  // const location = useLocation();
  const { t } = useTranslation();

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (

    <Box pl={2} sx={{ bgcolor: 'primary.main' }} display='flex' flexDirection='row' maxHeight='36px' justifyContent='space-between' >
      <Box>
        <List sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', my: '-4px' }}>
          {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => ( */}
          <ListItem sx={{ height: '28px', p: -1 }} disablePadding>
            <ListItemButton
              href={'https://' + params['domain'] + '.vimovsem.cz/admin/dashboard/employees'}


            >
              <ListItemIcon sx={{ mr: -3 }}>
                <Dashboard fontSize='small' />
              </ListItemIcon>
              {biggerThan1250 && <Typography fontSize={'small'} >{t('Dashboard')}</Typography>}
            </ListItemButton>
          </ListItem >
          <ModuleMenuItem topMenu label={t('Projekty')} route='projects' moduleType={StavarioModule.ProjectList} selectedModule={selectedModule} selectableModules={[StavarioModule.ProjectDetail, StavarioModule.ProjectList]} />
          {modulesVisibility?.diary && <ModuleMenuItem minWidth={'164px'} topMenu label={t('todo.log.building_diary_id')} route='diary/diaryvov' moduleType={StavarioModule.DiaryRecordsList} selectedModule={selectedModule} selectableModules={[StavarioModule.DiaryRecordsList, StavarioModule.DiaryRecordDetail, StavarioModule.DiariesList, StavarioModule.DiaryDetail]} />}
          {/* <ModuleMenuItem topMenu label={t('Majetek')} route='wealths' moduleType={StavarioModule.WealthList} selectedModule={selectedModule} selectableModules={[StavarioModule.WealthList, StavarioModule.WealthDetail]} /> */}

          {modulesVisibility?.wealth && <ListItem key={'wealths'} sx={{ height: '28px', p: -1 }} disablePadding>
            <ListItemButton href={'https://' + params['domain'] + '.vimovsem.cz/admin/wealth'} >
              <ListItemIcon sx={{ mr: -3 }}>
                <PrecisionManufacturingOutlined fontSize='small' />
              </ListItemIcon>
              {biggerThan1250 && <Typography fontSize={'small'} >{t('Majetek')}</Typography>}
            </ListItemButton>
          </ListItem>}
          {modulesVisibility?.employees && <ListItem sx={{ height: '28px', p: -1 }} disablePadding>
            <ListItemButton
              href={'https://' + params['domain'] + '.vimovsem.cz/admin/employees'}


            >
              <ListItemIcon sx={{ mr: -3 }}>
                <GroupOutlined fontSize='small' />
              </ListItemIcon>
              {biggerThan1250 && <Typography fontSize={'small'} >{t('employees')}</Typography>}
            </ListItemButton>
          </ListItem >}
          {modulesVisibility?.warehouse && <ListItem sx={{ height: '28px', p: -1 }} disablePadding>
            <ListItemButton
              href={'https://' + params['domain'] + '.vimovsem.cz/admin/stock-warehouses'}


            >
              <ListItemIcon sx={{ mr: -3 }}>
                <Home fontSize='small' />
              </ListItemIcon>
              {biggerThan1250 && <Typography fontSize={'small'} >{t('dashboard.warehouse')}</Typography>}
            </ListItemButton>
          </ListItem >}
          {modulesVisibility?.requests && <ListItem sx={{ height: '28px', p: -1 }} disablePadding>
            <ListItemButton
              href={'https://' + params['domain'] + '.vimovsem.cz/admin/requests'}


            >
              <ListItemIcon sx={{ mr: -3 }}>
                <Task fontSize='small' />
              </ListItemIcon>
              {biggerThan1250 && <Typography fontSize={'small'} >{t('todo.tasks')}</Typography>}
            </ListItemButton>
          </ListItem >}
          {modulesVisibility?.reports && <ListItem sx={{ height: '28px', p: -1 }} disablePadding>
            <ListItemButton
              href={'https://' + params['domain'] + '.vimovsem.cz/admin/building-hours'}


            >
              <ListItemIcon sx={{ mr: -3 }}>
                <QueryStatsOutlined fontSize='small' />
              </ListItemIcon>
              {biggerThan1250 && <Typography fontSize={'small'} >{t('menu.reports')}</Typography>}
            </ListItemButton>
          </ListItem >}
          {modulesVisibility?.documents && <ListItem sx={{ height: '28px', p: -1 }} disablePadding>
            <ListItemButton
              href={'https://' + params['domain'] + '.vimovsem.cz/admin/document-templates/'}


            >
              <ListItemIcon sx={{ mr: -3 }}>
                <FolderOutlined fontSize='small' />
              </ListItemIcon>
              {biggerThan1250 && <Typography fontSize={'small'} >{t('Documents')}</Typography>}
            </ListItemButton>
          </ListItem >}
          {modulesVisibility?.settings && <ListItem sx={{ height: '28px', p: -1 }} disablePadding>
            <ListItemButton
              href={'https://' + params['domain'] + '.vimovsem.cz/admin/settings'}


            >
              <ListItemIcon sx={{ mr: -3 }}>
                <SettingsOutlined fontSize='small' />
              </ListItemIcon>
              {biggerThan1250 && <Typography fontSize={'small'} >{t('Settings')}</Typography>}
            </ListItemButton>
          </ListItem >}


          {/* ))} */}
        </List>
      </Box>
      {/* {globalContext.initials} */}
      <Box alignSelf='start' mt={'4px'} mr={2}>

        <UserAvatar handleAvatarClick={handleAvatarClick} />
        <LayoutUserMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          onChangeView={onChangeView}

        />
      </Box>
    </Box>
  );

}

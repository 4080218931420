import { StavarioModule } from "./StavarioModule";

export const getModuleName = (module: StavarioModule, biggerThan700: boolean) => {
    switch (module) {
        case StavarioModule.ProjectList:
            return 'Projekty';
        case StavarioModule.ProjectDetail:
            return 'ProjectDetail';
        case StavarioModule.DiaryRecordsList:
            return 'diary.dailyRecord';
        case StavarioModule.DiaryRecordDetail:
            return biggerThan700 ? 'DailyRecord' : '';
        case StavarioModule.DiariesList:
            return 'Diaries';
        case StavarioModule.DiaryDetail:
            return 'diary.diary';
        case StavarioModule.WealthList:
            return 'Majetek';
        case StavarioModule.WealthDetail:
            return 'WealthCard';
        default:
            return '';
    }
}
import React, { useReducer, useRef, useState } from 'react';
import { CriticalPath, Edit, ExcelExport, Filter, GanttComponent, Inject, IQueryTaskbarInfoEventArgs, Selection as GanttSelection, Sort, Toolbar } from "@syncfusion/ej2-react-gantt";
import delay from "../../Shared/Functions/Delay";
import { ToggleButton } from "@mui/material";
import { TodoChildPropsOld } from './TodoChildPropsOld';
import { TodoGanttDTO, TodoState } from '../../../ApiOld/ApiServerVov';
export interface TodoGanttPropsOld extends TodoChildPropsOld {
    tasksGantt: TodoGanttDTO[] | undefined;
}
export default function TodoGanttOld(props: TodoGanttPropsOld) {
    const { tasksGantt } = props;
    const ganttEditSettings = {

        allowAdding: true,
        allowEditing: true,
        allowDeleting: true,
        allowTaskbarEditing: true,
        showDeleteConfirmDialog: true

    };
    function ganttToolbarClick(e: any) {

        if (e.item.id.toString().endsWith('excelexport') && ganttInstance !== undefined) {
            ganttInstance.current!.excelExport();
        }
        else if (e.item.id === 'GanttExport_csvexport' && ganttInstance !== undefined) {
            ganttInstance.current!.csvExport();
        }

    }
    function queryTaskbarInfo(args: IQueryTaskbarInfoEventArgs | undefined) {
        if (args !== undefined) {
            // console.log("args neni und", args);
            if ((args.data.taskData instanceof TodoGanttDTO) && (args.data.taskData as TodoGanttDTO).state === TodoState.RESOLVED) {
                // console.log("nastavuji");
                args.taskbarBgColor = "#91D05D";
                args.taskbarBorderColor = "#33B37B";
                args.progressBarBgColor = "#33B37B";
            }
            else if ((args.data.taskData instanceof TodoGanttDTO) && (args.data.taskData as TodoGanttDTO).state === TodoState.ACTIVE) {
                args.taskbarBgColor = "#f5e342";
                args.taskbarBorderColor = "#fccf03";
                args.progressBarBgColor = "#FFC600";
            }
        }
    }
    const taskFields = {
        id: 'id',
        name: 'name',
        startDate: 'createdDate',
        duration: 'duration',
        progress: 'progress',
        child: 'subtasks',
    };
    const ref = useRef<HTMLDivElement>(null);
    //mměli by tu být jiný data
    let toolbarGanttOptions = ['ExcelExport', 'Add', 'Edit', 'Update', 'Delete', 'Cancel', 'ExpandAll', 'CollapseAll'];
    const ganttInstance = useRef<GanttComponent>(null);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [criticalPath, setCriticalPath] = useState(true);
    React.useEffect(() => {
        ganttInstance.current!.height = window.innerHeight - ref.current!.parentElement!.offsetHeight - 24;
        ganttInstance.current!.width = ref.current!.parentElement!.offsetWidth;
        ganttInstance.current!.splitterSettings = { position: '40%' };
        delay(360).then(() => {
            ganttInstance.current!.refresh();
        });

    }, []);
    function handleOpenTodo(e: any) {
        if (e.requestType == 'beforeOpenEditDialog')

            e.cancel = true;
    }
    return (
        <div ref={ref}>

            <GanttComponent ref={ganttInstance}
                actionBegin={handleOpenTodo}

                editSettings={ganttEditSettings} allowSelection={true} allowExcelExport={true}
                toolbar={toolbarGanttOptions} toolbarClick={ganttToolbarClick}
                allowFiltering={true} allowSorting={true}
                enableCriticalPath={true}
                // style={{ maxWidth: maxWidthState > 0 ? maxWidthState : '100%' }}
                timelineSettings={{
                    weekStartDay: 1

                }}
                queryTaskbarInfo={queryTaskbarInfo}
                filterSettings={{ ignoreAccent: true }} dataSource={tasksGantt} taskFields={taskFields} >
                <Inject services={[GanttSelection, CriticalPath, Filter, Sort, ExcelExport, Toolbar, Edit]} />
            </GanttComponent>
        </div>
    );
    // else return <></>;
}
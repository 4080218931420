
import { AddCircleOutline, Check, CheckBox, NotInterested, Print, Reply, Save } from '@mui/icons-material';
import { Autocomplete, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, Stack, TextareaAutosize, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { GetDiaryDataDetailResponse, UsersGroupDiaryDTO } from '../../../../ApiOld/ApiServerVov';
import BuildingSelect from '../../../Shared/BuildingSelect';
import downloadByLink from '../../../Shared/Files/DownloadByLink';
import delay from '../../../Shared/Functions/Delay';
import SplitButton from '../../../Shared/SplitButton';
import SplitButtonNoPush from '../../../Shared/SplitButtonNoPush';
import DiaryPrintInRangeDialog from '../DiaryPrints/DiaryPrintInRangeDialog';
import GroupSelect from '../../../Shared/GroupSelect';
import DiarySelect from './DiarySelect';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import saveBlob from '../../../Shared/Files/SaveBlob';
import CreateUserDialog from './CreateUserDialog';
import MessageDialog from '../../../Shared/MessageDialog';
import DiaryUsersPrintDialog from '../DiaryPrints/DiaryUsersPrintDialog';
import { useUserLayoutContext } from '../../../../Contexts/Providers/UserLayoutMenuProvider';
import { useDiaryTabsContext } from '../../../../Contexts/Providers/DiaryTabsProvider';


interface DiaryDetailSceneProps {
    fromExternal?: boolean;
    isSubdodavatel?: boolean;
}

export default function DiaryDetailScene(props: DiaryDetailSceneProps) {
    const { t, i18n } = useTranslation();
    const { fromExternal, isSubdodavatel } = props;
    const projekty = ['jedna', 'dva'];
    const [data, setData] = useState<Partial<GetDiaryDataDetailResponse> | null>(null);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const [printing, setPrinting] = useState<boolean>(false);
    const [subd, setSubd] = useState<boolean>(false);
    const [createUserOpen, setCreateUserOpen] = useState(false);
    const [diaryId, setDiaryId] = useState<number>();
    const [messageOpen, setMessageOpen] = useState<boolean>(false);
    const [printOthersDiaryOpen, setPrintOthersDiaryOpen] = useState<boolean>(false);
    /*  const [messageData, setMessageData] = useState<{ title: string, text: string }>(); */

    const handleIsPrinting = (isPrinting: boolean) => {
        setPrinting(isPrinting);
    }
    const [diaryPrintInRangeDialog, setDiaryPrintInRangeDialogOpen] = useState<boolean>(false);
    const { permissions, refreshPermissions } = useDiaryTabsContext();
    const theme = useTheme();
    const navigate = useNavigate();
    // const { diaryId } = props;
    const params = useParams();
    useEffect(() => {
        if (isSubdodavatel !== undefined && isSubdodavatel === true && params['id']) {

            setDiaryId(parseInt(params['id']));
        }
        else if ((isSubdodavatel === undefined || isSubdodavatel === false) && params['id']) {
            setDiaryId(parseInt(params['id']));
            setSubd(false);
        }
        if (params['id']) {
            if (Number.isNaN(parseInt(params['id']))) {
                if (params['id'].toString() === 'new') {
                    setSubd(false);
                }
                else if (params['id'].toString() === 'newSubd') {
                    setSubd(true);
                }

                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetCompanyNameForDiary().then((d) => {
                    setData(new GetDiaryDataDetailResponse({ responsibleCompany: d, generalContractor: d }));
                    setIsCreating(true);
                    console.log('creating');
                });

            }
            else {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetDiaryDataDetail(parseInt(params['id'])).then((d) => {
                    setSubd(d.groupId !== undefined && d.groupId !== null);
                    setData(d);

                    if (isSubdodavatel === true) {
                        if (d.groupId) {
                            if (d.buildingId) {
                                var users = new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetDiaryUsersForGroup(d.groupId, d.buildingId!).then((e) => {
                                    setData({ ...d, users: e });

                                })
                            }
                            else setData({ ...d, users: [] });
                        }
                    }

                });
                console.log('updating');
            }

        }
        else setData(new GetDiaryDataDetailResponse());

        if (permissions === null)
            refreshPermissions(isSubdodavatel === undefined ? false : isSubdodavatel);
    }, []);
    const { setBackMenuFunction, setMenuButtons } = useUserLayoutContext();
    useEffect(() => {
        setBackMenuFunction(() => () => { redirectToList() });
        setMenuButtons(data &&
            <Stack direction='row' mr={2}>
                {saveDone && <Check color='success' sx={{ marginRight: '20px' }} />}
                {isSubdodavatel !== true && permissions && permissions.canEditDiary &&
                    <div style={{ marginRight: '10px' }}>
                        <SplitButton selectedIndexInput={0} disabled={data.buildingId === undefined || data.diaryName === undefined || data.diaryName === '' || (subd === true && data.groupId === undefined)} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                            startIconSplitButton={<Save />}
                            onMenuClick={(e) => {
                                if (e === 0) {
                                    saveDiary(false);
                                }
                                else if (e === 1) {
                                    saveDiary(true);

                                }
                            }}></SplitButton></div>}
                <div>
                    {printing && <CircularProgress />}
                    {!printing && !isCreating && <SplitButtonNoPush variant='outlined' buttonText={t('Print')} selectedIndexInput={0} selectOnClick={true}
                        options={[
                            { text: t('Print'), isEnabled: permissions && permissions.canPrintDiary !== undefined && permissions.canPrintDiary === true ? true : false },
                            { text: t('diary.printForDigitalSignature'), isEnabled: permissions && permissions.canPrintDiary !== undefined && permissions.canPrintDiary === true ? true : false },
                            { text: t('diary.printInDateRange'), isEnabled: permissions && permissions.canPrintDiary !== undefined && permissions.canPrintDiary === true ? true : false },
                            { text: t('diary.printMyRecords'), isEnabled: permissions && permissions.canPrintDiaryOwnRecords !== undefined && permissions.canPrintDiaryOwnRecords === true ? true : false },
                            { text: t('PrintOthers'), isEnabled: permissions && permissions.canPrintDiaryRecordOthers !== undefined && permissions.canPrintDiaryRecordOthers ? true : false },
                            { text: t('diary.printWithAttachments'), isEnabled: permissions && permissions.canPrintDiary !== undefined && permissions.canPrintDiary === true ? true : false },
                            { text: t('diary.printToZIP'), isEnabled: permissions && permissions.canPrintDiary !== undefined && permissions.canPrintDiary === true ? true : false },
                        ]}
                        startIconSplitButton={<Print />}
                        onMenuClick={(e) => {
                            if ((e === 0 || e === 1 || e === 2 || e === 3 || e === 4 || e === 5 || e === 6) && data.id) {
                                if (e === 6) {
                                    new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiaryZIP(data.id);
                                    setMessageOpen(true);
                                }
                                else new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintStatus(data.id, false, false, false).then((d) => {
                                    if (d && d.canDirectlyPrint && data.id) {
                                        if (e === 0) {
                                            setPrinting(true);
                                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiary(data.id, false, false, false, 0).then((d) => {
                                                setPrinting(false);
                                                if (d)
                                                    saveBlob(d.data, data.diaryName + ".pdf");
                                            });
                                        }
                                        else if (e === 1) {
                                            setPrinting(true);
                                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiary(data.id, false, true, false, 0).then((d) => {
                                                setPrinting(false);
                                                if (d)
                                                    saveBlob(d.data, data.diaryName + ".pdf");
                                            });
                                        }
                                        else if (e === 2) {
                                            setDiaryPrintInRangeDialogOpen(true);
                                        }
                                        else if (e === 3) {
                                            setPrinting(true);
                                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiary(data.id, true, false, false, 0).then((d) => {
                                                setPrinting(false);
                                                if (d)
                                                    saveBlob(d.data, data.diaryName + ".pdf");
                                            });
                                        }
                                        else if (e === 4) {
                                            setPrintOthersDiaryOpen(true);
                                        }
                                        else if (e === 5) {
                                            setPrinting(true);
                                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiary(data.id, false, false, true, 0).then((d) => {
                                                setPrinting(false);
                                                if (d)
                                                    saveBlob(d.data, data.diaryName + ".pdf");
                                            });
                                        }
                                    }
                                    else if (data.id) {
                                        if (e === 0) {
                                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiaryBackground(data.id, false, false, false, undefined, undefined);
                                        }
                                        else if (e === 1) {
                                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiaryBackground(data.id, false, true, false, undefined, undefined);
                                        }
                                        else if (e === 2) {
                                            setDiaryPrintInRangeDialogOpen(true);
                                        }
                                        else if (e === 3) {
                                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiaryBackground(data.id, true, false, false, undefined, undefined);
                                        }
                                        else if (e === 5) {
                                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiaryBackground(data.id, false, false, true, undefined, undefined);;
                                        }
                                        setMessageOpen(true);
                                    }
                                });

                            }
                        }}></SplitButtonNoPush>}</div>
                {(permissions === undefined || permissions?.canEditDiary === undefined || permissions?.canEditDiary === false) && <div><NotInterested sx={{ marginRight: '12px', marginBottom: '4px' }} />{t('noEditPermissions')}</div>}
            </Stack>);
    }, [setMenuButtons, permissions, data, saveDone, printing, isCreating, isSubdodavatel, t, setBackMenuFunction, saveDiary, subd, setPrinting, setDiaryPrintInRangeDialogOpen, setPrintOthersDiaryOpen, messageOpen]);
    useEffect(() => {
        if (saveDone === true)
            delay(2000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    function saveDiary(close: boolean) {
        if (isCreating && data) {
            // zakládat deník může pouze nesubdodavatel?
            if (isSubdodavatel === undefined || isSubdodavatel === false)
                new APIVovHelper().CreateDiary(data).then((d) => {
                    setIsCreating(false);
                    setSaveDone(true);

                    if (close)
                        redirectToList();
                    else {
                        if (fromExternal === undefined || fromExternal === false)
                            navigate('/' + params['lang']! + '/' + params['domain']! + '/userlayout/diary/diarydetail/' + d);
                        else navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryolderpnewdetail/' + d);
                    }
                });
        }
        else {
            // a ukládat změny?
            if (data)
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).SaveDiary(data).then((d) => {
                    if (d)
                        setSaveDone(true);
                    if (close)
                        redirectToList();
                });
        }

    }
    const redirectToList = () => {
        if (isSubdodavatel !== undefined && isSubdodavatel === true) {
            navigate('/' + i18n.language + '/' + params['domain']! + '/subcontractor/main/diariesDiary')
        }
        else {
            // if (fromExternal === undefined || fromExternal === false)
            navigate('/' + i18n.language + '/' + params['domain']! + '/userlayout/diary/diaries')
            // else navigate('/' + i18n.language + '/' + params['domain']! + '/diaryolderpnew');
        }
    }
    function createUserClosed() { setCreateUserOpen(false); }
    if (data)
        return (
            <ValidationGroup>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                    {/* <div style={{ position: 'absolute', left: '40px', top: fromExternal || isSubdodavatel ? '112px' : '60px', zIndex: 30 }}>
                        <IconButton color='primary' onClick={redirectToList}>
                            <Reply />
                        </IconButton>
                    </div> */}

                    <div >
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '10px', margin: '10px', marginTop: '32px' }}>
                            <h4>1. {t('diary.basicInfo')}</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                <Validate name='email-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                    <TextField sx={{ margin: '0px 20px' }} label={t('diary.diaryName')}
                                        size='small'
                                        name='diaryName'
                                        variant='outlined'
                                        value={data.diaryName}
                                        onChange={(e) => { setData({ ...data, diaryName: e.target.value }) }} />
                                </Validate>
                                <TextField
                                    variant='outlined' sx={{ margin: '20px' }} size='small' value={data.responsibleCompany}
                                    onChange={(e) => { setData({ ...data, responsibleCompany: e.target.value }) }}
                                    label={t('diary.companyResponsibleForProject')} />
                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '10px', margin: '10px' }}>
                            <h4>2. {t('diary.siteDataAccordingToDocumentation')}</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                {/* <Validate name='email-signup-var' initialValidation={'noisy'} required={[true, t('FieldRequired')]}> */}
                                <div style={{ margin: '20px', fontFamily: 'Roboto', fontSize: '12px' }}>

                                    <BuildingSelect subcontractorDiaryId={diaryId} label={t('Projekt')} isSubdodavatel={isSubdodavatel} isMandatory onChange={async (e) => {
                                        if (e) {
                                            if (e.id && e.id !== data.buildingId) {
                                                if (data.groupId) {
                                                    var users = await new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetDiaryUsersForGroup(data.groupId, e.id!);
                                                    setData({ ...data, buildingId: e!.id, users: users, parentDiaryId: undefined });
                                                }
                                                else setData({ ...data, buildingId: e!.id, users: [], parentDiaryId: undefined });
                                            }
                                            else setData({ ...data, buildingId: e!.id, parentDiaryId: undefined });
                                        }
                                        else setData({ ...data, buildingId: undefined, parentDiaryId: undefined });
                                    }} selectedProjectId={data.buildingId} />

                                </div>
                                {/* </Validate> */}
                                <TextField label={t('diary.phase')} variant='outlined' sx={{ margin: '0px 20px' }} size='small' value={data.section}
                                    onChange={(e) => { setData({ ...data, section: e.target.value }) }} />
                                <TextField label={t('diary.district')} variant='outlined' sx={{ margin: '0px 20px', marginTop: '20px' }} size='small' value={data.district}
                                    onChange={(e) => { setData({ ...data, district: e.target.value }) }} />
                                <TextField label={t('diary.municipality')} variant='outlined' sx={{ margin: '0px 20px', marginTop: '20px' }} size='small' value={data.city}
                                    onChange={(e) => { setData({ ...data, city: e.target.value }) }} />
                                <TextField label={t('diary.place')} variant='outlined' sx={{ margin: '20px' }} size='small' value={data.place}
                                    onChange={(e) => { setData({ ...data, place: e.target.value }) }} />
                            </div>
                        </div>
                        {subd && <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '10px', margin: '10px' }}>
                            <h4>3. {t('diary.subcontractor')}</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                <div style={{ margin: '20px', fontFamily: 'Roboto', fontSize: '12px' }}>
                                    <GroupSelect isMandatory={subd} readOnly={/* data.hasRecords || */ isSubdodavatel === true} isSubdodavatel={isSubdodavatel} onChange={async (e) => {
                                        if (e) {
                                            if (e.id && e.id !== data.groupId) {
                                                if (data.buildingId) {
                                                    var users = await new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetDiaryUsersForGroup(e.id!, data.buildingId!);
                                                    setData({ ...data, groupId: e!.id, users: users });
                                                }
                                                else setData({ ...data, groupId: e!.id, users: [] });
                                            }
                                            else setData({ ...data, groupId: e!.id });
                                        }
                                        else setData({ ...data, groupId: undefined, parentDiaryId: undefined, users: [] });
                                    }} selectedGroupId={data.groupId} />
                                </div>
                                {((data.groupId && isSubdodavatel !== true) || (isSubdodavatel === true && data.parentDiaryId)) && <div style={{ margin: '20px', fontFamily: 'Roboto', fontSize: '12px' }}>
                                    <DiarySelect readOnly={/* data.hasRecords || */ isSubdodavatel === true} isSelecteMain={true} isSubdodavatel={isSubdodavatel} onChange={(e) => {
                                        if (e)
                                            setData({ ...data, parentDiaryId: e.id! });
                                        else setData({ ...data, parentDiaryId: undefined });
                                    }} selectedDiaryId={data.parentDiaryId} isCreating={true} buildingId={data.buildingId} />
                                </div>
                                }
                                {!isCreating && data.groupId && data.buildingId && <Tooltip title={t('createSubcontractor')}>
                                    <Button sx={{ marginLeft: '32px', width: '240px' }} startIcon={<AddCircleOutline />} aria-label="create" variant='contained' size='small' onClick={(event) => {
                                        setCreateUserOpen(true);
                                    }} disabled={false} color="primary">
                                        {t('createSubcontractor')}
                                    </Button>
                                </Tooltip>
                                }
                                {!isCreating && data.groupId && data.buildingId && <h6 style={{ margin: '16px', marginLeft: '20px', marginBottom: '0px' }}>{t('usersInGroup')}</h6>}
                                {!isCreating && data.groupId && data.buildingId && <div style={{ display: 'flex', flexWrap: 'wrap', margin: '16px', maxWidth: 520 }}>
                                    {data.users != undefined && data.users.map((v, i) => {
                                        return <div key={i} style={{
                                            background: /* v.hasRight ? theme.palette.primary.main : */ 'white',
                                            borderRadius: '10px', margin: '8px', boxShadow: 'rgb(0 0 0 / 40%) 1px 1px 3px'
                                        }}> <FormControlLabel
                                                value="end"
                                                sx={{ marginLeft: '6px' }}
                                                control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={v.hasRight}
                                                    onChange={(e, checked) => { let d = { ...data }; d.users!.filter(p => p.userId == v.userId).at(0)!.hasRight = checked; setData(d); }} checkedIcon={<CheckBox color='secondary' />} />}
                                                label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{v.userName}</Typography>}
                                                labelPlacement="end"
                                            /></div>;
                                    })
                                    }
                                </div>
                                }
                            </div>
                        </div>}
                    </div>
                    <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '10px', margin: '10px', marginTop: '32px' }}>
                        {!subd && <h4>3. {t('diary.stakeholders')}</h4>}
                        {subd && <h4>4. {t('diary.stakeholders')}</h4>}
                        {/* <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}> */}
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '60px', marginLeft: '10px', paddingLeft: '4px' }}>{data.investor !== '' && t('investor.investor')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('investor.investor')}
                                minRows={2}
                                value={data.investor}
                                onChange={(e) => { setData({ ...data, investor: e.target.value }) }}
                                style={{ width: 400, padding: '8px', marginTop: data.investor === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '160px', marginLeft: '10px', paddingLeft: '4px' }}>{data.tdsPermament !== '' && t('diary.clarkOfWorksPermanent')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.clarkOfWorksPermanent')}
                                minRows={2}
                                value={data.tdsPermament}
                                onChange={(e) => { setData({ ...data, tdsPermament: e.target.value }) }}
                                style={{ width: 400, padding: '8px', marginTop: data.tdsPermament === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '160px', marginLeft: '10px', paddingLeft: '4px' }}>{data.tdsTemporary !== '' && t('diary.clarkOfWorksVisiting')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.clarkOfWorksVisiting')}
                                value={data.tdsTemporary}
                                onChange={(e) => { setData({ ...data, tdsTemporary: e.target.value }) }}
                                minRows={2}
                                style={{ width: 400, padding: '8px', marginTop: data.tdsTemporary === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '110px', marginLeft: '10px', paddingLeft: '4px' }}>{data.authorWatch !== '' && t('diary.clarkOfWorksAuthorial')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.clarkOfWorksAuthorial')}
                                value={data.authorWatch}
                                onChange={(e) => { setData({ ...data, authorWatch: e.target.value }) }}
                                minRows={2}
                                style={{ width: 400, padding: '8px', marginTop: data.authorWatch === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '140px', marginLeft: '10px', paddingLeft: '4px' }}>{data.generalContractor !== '' && t('diary.mainContractor')}</p>
                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.mainContractor')}
                                value={data.generalContractor}
                                onChange={(e) => { setData({ ...data, generalContractor: e.target.value }) }}
                                minRows={2}
                                style={{ width: 400, padding: '8px', marginTop: data.generalContractor === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '160px', marginLeft: '10px', paddingLeft: '4px' }}>{data.constructionContractor !== '' && t('diary.buildingSectionContractor')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.buildingSectionContractor')}
                                value={data.constructionContractor}
                                onChange={(e) => { setData({ ...data, constructionContractor: e.target.value }) }}
                                minRows={2}
                                style={{ width: 400, padding: '8px', marginTop: data.constructionContractor === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '200px', marginLeft: '10px', paddingLeft: '4px' }}>{data.technologicallContractor !== '' && t('diary.technologicalSectionContractor')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.technologicalSectionContractor')}
                                value={data.technologicallContractor}
                                onChange={(e) => { setData({ ...data, technologicallContractor: e.target.value }) }}
                                minRows={2}
                                style={{ width: 400, padding: '8px', marginTop: data.technologicallContractor === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '100px', marginLeft: '10px', paddingLeft: '4px' }}>{data.subcontractors !== '' && t('diary.subcontractors')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.subcontractors')}
                                value={data.subcontractors}
                                onChange={(e) => { setData({ ...data, subcontractors: e.target.value }) }}
                                minRows={2}
                                style={{ width: 400, padding: '8px', marginTop: data.subcontractors === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '120px', marginLeft: '10px', paddingLeft: '4px' }}>{data.projectManager !== '' && t('diary.projectManager')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.projectManager')}
                                value={data.projectManager}
                                onChange={(e) => { setData({ ...data, projectManager: e.target.value }) }}
                                minRows={2}
                                style={{ width: 400, padding: '8px', marginTop: data.projectManager === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '100px', marginLeft: '10px', paddingLeft: '4px' }}> {data.constructionManager !== '' && t('diary.siteManager')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.siteManager')}
                                value={data.constructionManager}
                                onChange={(e) => { setData({ ...data, constructionManager: e.target.value }) }}
                                minRows={2}
                                style={{ width: 400, padding: '8px', marginTop: data.constructionManager === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '150px', marginLeft: '10px', paddingLeft: '4px' }}>{data.generalArchitect !== '' && t('diary.projectDesigner')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.projectDesigner')}
                                value={data.generalArchitect}
                                onChange={(e) => { setData({ ...data, generalArchitect: e.target.value }) }}
                                minRows={2}
                                style={{ width: 400, padding: '8px', marginTop: data.generalArchitect === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '160px', marginLeft: '10px', paddingLeft: '4px' }}>{data.responsibleArchitect !== '' && t('diary.responsibleDesigner')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.responsibleDesigner')}
                                value={data.responsibleArchitect}
                                onChange={(e) => { setData({ ...data, responsibleArchitect: e.target.value }) }}
                                minRows={2}
                                style={{ width: 400, padding: '8px', marginTop: data.responsibleArchitect === '' ? '20px' : '0px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '160px', marginLeft: '10px', paddingLeft: '4px' }}>{data.authorizedPerson !== '' && t('diary.authorizedPerson')}</p>

                            <TextareaAutosize aria-label="empty textarea"
                                placeholder={t('diary.authorizedPerson')}
                                value={data.authorizedPerson}
                                onChange={(e) => { setData({ ...data, authorizedPerson: e.target.value }) }}
                                minRows={2}
                                style={{ width: 400, padding: '8px', marginTop: data.authorizedPerson === '' ? '20px' : '0px' }} />
                        </div>
                        {/* </div> */}
                    </div>
                    <DiaryPrintInRangeDialog
                        data={data}
                        open={diaryPrintInRangeDialog}
                        handleIsPrinting={handleIsPrinting}
                        onClose={(e) => {
                            setDiaryPrintInRangeDialogOpen(false);
                        }} />
                    {!isCreating && <CreateUserDialog showGroups={false} groupId={data.groupId} diaryId={data.id} isSubdodavatel={isSubdodavatel} open={createUserOpen} onClose={createUserClosed} onCreated={(id, name) => { setData({ ...data, users: [...data.users!, new UsersGroupDiaryDTO({ userId: id, userName: name, hasRight: true })] }) }} />}
                    <MessageDialog onClose={() => setMessageOpen(false)} text={t('diary.diaryPrintLargeText')} title={t('diary.diaryPrintLargeTitle')} open={messageOpen} />
                    {diaryId !== undefined && <DiaryUsersPrintDialog diaryId={diaryId} onClose={(e) => {
                        setPrintOthersDiaryOpen(false);
                        if (e !== undefined && e !== null && e.id && data.id) {
                            setPrinting(true);
                            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiary(data.id, true, false, false, e.id).then((d) => {
                                setPrinting(false);
                                if (d)
                                    saveBlob(d.data, data.diaryName + ".pdf");
                            });

                        }
                    }
                    } open={printOthersDiaryOpen} />}
                </div>

            </ValidationGroup >
        );
    return <div>{t('loading...')}</div>
}
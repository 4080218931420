import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DiaryLogActionType, DiaryLogDTO } from "../../../../../ApiOld/ApiServerVov";
import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import { ColumnChooser, ColumnDirective, ColumnsDirective, ExcelExport, Filter, GridComponent, Group, Inject, Page, RecordDoubleClickEventArgs, Reorder, Resize, Search, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { ApiDiaryBaseHelper } from "../../../../../Api/ApiDiaryBaseHelper";
import { Add, Compare, Delete, Edit, Save, Upload } from "@mui/icons-material";
import CompareLogDiaryDialog from "../CompareLogDiaryDialog";

interface LogWidgetSceneProps {
    buildingDiaryRecordId: number | undefined;
    isSubdodavatel?: boolean;
    isInvestor?: boolean;
    refresh?: boolean;
}

export default function LogWidgetScene(props: LogWidgetSceneProps) {
    const { t, i18n } = useTranslation();
    const { buildingDiaryRecordId, isSubdodavatel, isInvestor, refresh } = props;
    const [logs, setLogs] = useState<DiaryLogDTO[]>();
    const [compareOpen, setCompareOpen] = useState(false);
    const [oldValue, setOldValue] = useState<string | undefined>();
    const [newValue, setNewValue] = useState<string | undefined>();

    const theme = useTheme();
    const grid = useRef<GridComponent>(null);

    useEffect(() => {
        if (buildingDiaryRecordId) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetDiaryRecordLog(buildingDiaryRecordId).then((r) => {
                setLogs(r);
            });
        }
        else if (!buildingDiaryRecordId)
            setLogs(undefined);
    }, [buildingDiaryRecordId, refresh]);

    function getCompareValueVisual(props: DiaryLogDTO) {
        if ((props.oldValue || !props.oldValue) && props.columnName === 'completed_work') {
            return <div>
                <Tooltip title='Porovnat'>
                    <IconButton sx={{ color: 'black', fontSize: 'small' }} onClick={() => {
                        setOldValue(props.oldValue ? props.oldValue : '');
                        setNewValue(props.value ? props.value : '');
                        setCompareOpen(true);
                    }}><Compare sx={{ fontSize: '14px' }} />
                    </IconButton>
                </Tooltip>
            </div>;
        }
        return <div></div>;
    };

    function getPropertyVisual(props: DiaryLogDTO) {
        if (props.columnName) {
            return <div>{t('log.' + props.columnName)}</div>;
        }
        return <div></div>;
    };

    function getLogTypeVisual(props: DiaryLogDTO) {
        if (props.tableName) {
            return <div style={{ display: 'flex' }}>{getTypeString(props.tableName)} {getTypeIcon(props.actionType)}</div>;
            /* if (types.length === 0)
                return <div></div>;
            else if (types.length === 1) {
                return <div>{getTypeIcon(types[0])}</div>;
            }
            else if (types.length === 2) {
                return <div style={{ display: 'flex' }}>{getTypeString(types[0])}{getTypeIcon(types[1])}</div>;
            }
            else
                return <div>{props.columnName}</div>; */
        }
        return <div></div>;
    };

    function getTypeString(type: string): JSX.Element {
        switch (type) {
            case 'diary': {
                return <div style={{ margin: '2px' }}>{t('diary.diary')}</div>;
            }
            case 'image': {
                return <div style={{ margin: '2px' }}>{t('image')}</div>;
            }
            case 'file': {
                return <div style={{ margin: '2px' }}>{t('file')}</div>;
            }
            case 'weather': {
                return <div style={{ margin: '2px' }}>{t('dashboard.weather')}</div>;
            }
            case 'worker': {
                return <div style={{ margin: '2px' }}>{t('worker')}</div>;
            }
            case 'material': {
                return <div style={{ margin: '2px' }}>{t('material')}</div>;
            }
            case 'machine': {
                return <div style={{ margin: '2px' }}>{t('machine')}</div>;
            }
            case 'signature': {
                return <div style={{ margin: '2px' }}>{t('signatures')}</div>;
            }
            case 'record': {
                return <div></div>;
            }
            case 'unknown': {
                return <div></div>;
            }
            default: {
                return <div style={{ margin: '2px' }}>{type}</div>;
            }
        }
    }

    function getTypeIcon(type: DiaryLogActionType | undefined) {
        switch (type) {
            // Save
            case DiaryLogActionType.Save: {
                return <div style={{ marginLeft: '10px' }}><Save sx={{ fontSize: '18px' }} /></div>;
            }
            // Add
            case DiaryLogActionType.Add: {
                return <div style={{ marginLeft: '10px' }}><Add sx={{ fontSize: '18px' }} /></div>;
            }
            // Delete
            case DiaryLogActionType.Delete: {
                return <div style={{ marginLeft: '10px' }}><Delete sx={{ fontSize: '18px' }} /></div>;
            }
            // Update
            case DiaryLogActionType.Update: {
                return <div style={{ marginLeft: '10px' }}><Edit sx={{ fontSize: '18px' }} /> </div>;
            }
            // upload
            case DiaryLogActionType.Upload: {
                return <div style={{ marginLeft: '10px' }}><Upload sx={{ fontSize: '18px' }} /></div>;
            }
            // Unknown
            case DiaryLogActionType.Unknown: {
                return <div></div>;
            }
            default: {
                return <div>{type}</div>;
            }
        }
    }

    return (
        <Box sx={{ marginX: '40px', background: 'white', borderRadius: '15px', overflow: 'hidden', minHeight: 500 }}>
            {logs && <GridComponent ref={grid} id="grid-component-daily-records-log-scene" allowSorting={true}
                allowReordering={true} allowResizing={true}
                allowMultiSorting={true} allowGrouping={false}
                locale={i18n.language} enablePersistence={false}
                recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                    let data = e.rowData as DiaryLogDTO;
                    if (data) {
                        setOldValue(data.oldValue ? data.oldValue : '');
                        setNewValue(data.value ? data.value : '');
                        setCompareOpen(true);
                    }
                }}
                allowPaging={true}
                style={{ backgroundColor: '#F5F5F5' }}
                pageSettings={{ pageSize: 10, pageSizes: [5, 10, 20, 30, 50, 100] }}
                //  columnDataStateChange={(e) => { console.log(e) }}
                //allowFiltering={true}
                dataSource={logs}>
                <ColumnsDirective>
                    <ColumnDirective field='userName' headerText={t('user')} />
                    <ColumnDirective field='ip' headerText='IP' />
                    <ColumnDirective field='tableName' headerText={t('updated')} template={getLogTypeVisual} />
                    <ColumnDirective field='columnName' headerText={t('property')} template={getPropertyVisual} />
                    <ColumnDirective disableHtmlEncode={false} field='value' headerText={t('value')} />
                    <ColumnDirective disableHtmlEncode={false} field='oldValue' headerText={t('oldValue')} />
                    <ColumnDirective headerText={t('log.compare')} template={getCompareValueVisual} width={'100px'} />
                    <ColumnDirective field='date' type='date' format={i18n.language === 'en' ? 'MM/dd/yyyy HH:mm' : 'dd.MM.yyyy HH:mm'} headerText={t('Date')} />
                </ColumnsDirective>

                <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
            </GridComponent>
            }
            <CompareLogDiaryDialog open={compareOpen} onClose={() => { setCompareOpen(false); }} oldValue={oldValue} newValue={newValue} />
        </Box>
    );
}
import { AccessTimeOutlined, AttachFile, Close, CloseFullscreen, ColorLens, Delete, EventRepeat, Fullscreen, History, MoreHorizOutlined, PersonOutline, Save, SaveAs, Settings, Troubleshoot, Watch } from '@mui/icons-material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogTitle, IconButton, Popover, styled, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from '@mui/material';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { ChangeEventArgs, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import React, { useEffect, useRef, useState } from 'react';
import SplitButton from '../../Shared/SplitButton';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { APITodoHelper } from '../../../Api/ApiTodoHelper';
import { ConversationDto, FileParameter, RequestsDTO, UpdateTodoConversationRequest } from '../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { Marker } from 'react-image-marker';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import { BeforeUploadEventArgs, FileInfo, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { APIVovInvestorHelper } from '../../../ApiOld/ApiInvestorOldClient';
import InitSfUploaderLocale from '../../../Localization/SfUploaderInitializer';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { APITodoOldHelper } from '../../../ApiOld/ApiToDoOldHelper';

InitSfUploaderLocale();

export interface ChatMessageDetailDialogProps {
    open: boolean;
    conversation: ConversationDto;
    onClose: () => void;
    onSave: (conversation: ConversationDto) => void;
}

export default function ChatMessageDetailDialog(props: ChatMessageDetailDialogProps) {
    const theme = useTheme();
    const { onClose, open, conversation, onSave } = props;
    const { t, i18n } = useTranslation();
    const biggerThan600 = useMediaPredicate("(min-width: 600px)");

    const upload = useRef<UploaderComponent>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [conversationLocal, setConversationLocal] = useState<Partial<ConversationDto>>(conversation);

    useEffect(() => {
        setLoading(false);

    }, []);
    useEffect(() => {
        setConversationLocal({ ...conversation, toAdmin: false, toEmployee: !conversation.toEmployee, toInvestor: !conversation.toInvestor });

    }, [conversation]);
    useEffect(() => {
        console.log('conversation', conversationLocal);

    }, [conversationLocal]);

    function onSaveClicked() {
        setLoading(true);

        new APITodoOldHelper().UpdateTodoConversation(new UpdateTodoConversationRequest({
            id: conversationLocal.id!,
            text: conversationLocal.text!,
            toEmployee: conversationLocal.toEmployee === true ? false : true,
            toInvestor: conversationLocal.toInvestor === true ? false : true,
        })).then((data) => {
            setLoading(false);
            if (data.ok) {
                onSave(conversationLocal as ConversationDto);
            }
        }).catch(() => { setLoading(false); });
    }

    const [fullScreen, setFullScreen] = useState(false);

    function handleKeyDown(event: any): void {
    }

    return (

        <Dialog onKeyDown={handleKeyDown} maxWidth="lg" fullScreen={fullScreen} open={open} onClose={onClose} style={{ margin: '80px' }}>
            <DialogTitle height='40px' sx={{ backgroundColor: '#F5F5F5', zIndex: "28" }}>
                <div style={{ backgroundColor: '#F5F5F5', zIndex: "200" }}>
                    <div style={{ float: 'left', display: 'flex' }}>
                        <Typography variant='h1' fontWeight='bold' fontSize={20} sx={{ marginTop: '-5px', marginRight: '5px' }}>{t('investor.taskDetail')}</Typography>
                    </div>
                    <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>

                        {biggerThan600 && conversationLocal.datetime && <div>
                            <Typography variant='body1' sx={{ marginRight: '12px', marginTop: '2px' }} fontSize={11}>{conversationLocal.datetime!.toLocaleString(i18n.language)} </Typography>
                        </div>}
                        <div style={{ marginTop: '-14px' }}>
                            <IconButton onClick={() => {
                                setFullScreen(!fullScreen);
                            }} >
                                {fullScreen ? <CloseFullscreen /> : <Fullscreen />}
                            </IconButton>
                            <IconButton onClick={() => { onClose(); }} >
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </DialogTitle>
            {loading && <div style={{ margin: 'auto', minHeight: '500px', minWidth: '400px' }}>
                <div style={{ fontSize: '20px', marginBottom: '10px', marginTop: '80px', marginLeft: '150px' }}>
                    {t('savingDots')}
                </div>
                <div>
                    <CircularProgress sx={{ opacity: '1', width: '16px', height: '16px', margin: 'auto', marginLeft: '165px' }} />
                </div>
            </div>
            }
            {!loading && <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', minHeight: '500px', minWidth: '450px', height: '100%' }} >
                <div style={{ fontSize: '14px', margin: '16px', width: 'calc(100% - 32px)', background: 'lightgray', border: '1px solid white', borderRadius: '4px', padding: '4px' }} >
                    <div style={{ display: 'flex' }}>
                        <div style={{ fontWeight: '500', width: 'calc(100% - 30px)' }}>{conversationLocal.text}</div>
                    </div>
                    <hr style={{ height: '2px', marginTop: '4px', marginBottom: '2px' }} />
                    <div style={{ display: 'flex', fontSize: '14px' }}>
                        <PersonOutline fontSize='small' sx={{ float: 'left', color: 'black' }} color='primary' />
                        <div>
                            {conversationLocal.employee ? conversationLocal.employee : ''}
                        </div>
                    </div>
                    <div style={{ display: 'flex', fontSize: '12px' }}>
                        <AccessTimeOutlined fontSize='small' sx={{ height: '16px', float: 'left', color: 'black' }} color='primary' />
                        <div>
                            {conversationLocal.datetime ? conversationLocal.datetime.toLocaleString() : ''}
                        </div>
                    </div>
                </div>
                <div style={{ width: 'calc(100% - 16px)' }}>
                    <ToggleButtonGroup
                        size='small'
                        color="primary"

                        /* value={selectedChatGroups}
                        onChange={handleChatGroupSelected} */
                        aria-label="Platform"
                        style={{ width: '100%', marginLeft: '10px' }}
                    >
                        <div style={{ width: '33%', justifyContent: 'center', display: 'flex' }}>
                            <ToggleButton selected={conversationLocal.toEmployee} sx={{ backgroundColor: conversationLocal.toEmployee ? theme.palette.secondary.main : theme.palette.primary.main, width: '80%' }} size='small' value="0" onChange={() => setConversationLocal({ ...conversationLocal, toEmployee: !conversationLocal.toEmployee })}>{t('workers')}</ToggleButton>
                        </div>
                        <div style={{ width: '33%', justifyContent: 'center', display: 'flex' }}>
                            <ToggleButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} selected={conversationLocal.toAdmin} sx={{ backgroundColor: conversationLocal.toAdmin ? theme.palette.secondary.main : theme.palette.primary.main, width: '80%', cursor: 'not-allowed !important' }} size='small' value="1" onChange={() => setConversationLocal({ ...conversationLocal, toEmployee: !conversationLocal.toAdmin })}>{t('office')}</ToggleButton>
                        </div>
                        <div style={{ width: '33%', justifyContent: 'center', display: 'flex' }}>
                            <ToggleButton selected={conversationLocal.toInvestor} sx={{ backgroundColor: conversationLocal.toInvestor ? theme.palette.secondary.main : theme.palette.primary.main, width: '80%' }} size='small' value="2" onChange={() => setConversationLocal({ ...conversationLocal, toInvestor: !conversationLocal.toInvestor })}>{t('Investor')}</ToggleButton>
                        </div>
                    </ToggleButtonGroup>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '16px', marginTop: 'auto' }}>
                    <Button size='small' variant='contained'
                        onClick={() => onSaveClicked()}
                    >{t('save')}</Button>

                    <Button size='small' variant='outlined'
                        onClick={() => onClose()}
                    >{t('close')}</Button>
                </div>
            </div>
            }
        </Dialog >
    );
};

import { Check, AddCircleOutline, AttachFile, Cloud, Construction, Engineering, Grading, Lock, Photo, PhotoCamera } from '@mui/icons-material';
import { Box, Button, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { ColumnsDirective, GridComponent, Group, RecordDoubleClickEventArgs, Sort, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, ColumnDirective, Inject, FilterSettingsModel, Page, ExcelQueryCellInfoEventArgs, Column } from '@syncfusion/ej2-react-grids';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import CreateDiaryRecord from './Widgets/CreateDiaryRecord';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';
import { useLoginAdminStore } from '../../../Contexts/LoginAdminZContext';
import { useLoginSubcontractorStore } from '../../../Contexts/LoginSubcontractorZContext';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useUserLayoutContext } from '../../../Contexts/Providers/UserLayoutMenuProvider';
import { useDiaryTabsContext } from '../../../Contexts/Providers/DiaryTabsProvider';
import { GetDailyRecordsForUserDTO } from '../../../ApiOld/Api/ApiServerVov';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

InitSfGridLocale();
interface DiaryRecordsProps {
    fromExternal?: boolean;
    isSubdodavatel?: boolean;
}

export default function DiaryRecords(props: DiaryRecordsProps) {

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    const navigate = useNavigate();
    const [newOpen, setNewOpen] = useState(false);
    const [trashOpen, setTrashOpen] = useState(false);
    const { records, refreshDailyRecords, loadingDailyRecords, permissions, refreshPermissions } = useDiaryTabsContext();
    // const [records, setRecords] = useState<GetDailyRecordsForUserDTO[] | null>(null);
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const params = useParams();
    const { userName, serverLoad } = useLoginAdminStore();
    const { subcontractorName, serverLoadSub } = useLoginSubcontractorStore();
    const { setMenuButtons, setBackMenuFunction } = useUserLayoutContext();
    const grid = useRef<GridComponent>(null);
    useEffect(() => {
        if (permissions === null)
            refreshPermissions(props.isSubdodavatel === undefined ? false : props.isSubdodavatel);
    }, [permissions, refreshPermissions, props.isSubdodavatel]);
    useEffect(() => {

        if (props.isSubdodavatel) {
            if (subcontractorName === undefined) {
                serverLoadSub();
            }
        }
        else {
            if (userName === undefined) {
                serverLoad();
            }
        }

    }, [userName, subcontractorName, props.isSubdodavatel]);

    useEffect(() => {
        if (records === null)
            refreshDailyRecords();
        else if (grid.current) {
            grid.current.dataSource = records ?? [];
            grid.current.refresh();
        }

        return () => {
            if (grid.current) {
                grid.current.dataSource = [];
            }
        }
    }, []);

    useEffect(() => {
        console.log('records', records);
        if (grid.current && records) {
            grid.current.dataSource = records ?? [];
            grid.current.refresh();
        }

    }, [records, grid]);

    function GridTemplate(props: GetDailyRecordsForUserDTO) {
        const [hover, setHover] = useState(false);
        if (permissions && permissions.canViewDiaryRecordsDetail)
            return (<div style={{ cursor: 'pointer' }} onClick={() => {
                navigateToDetail(props);
            }} onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <a style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                >{props.date!.toLocaleDateString(i18n.language)}</a>
            </div >);
        else return <div>{props.date!.toLocaleDateString(i18n.language)}</div>
        // return (<div>
        //     <a style={{ color: theme.palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { navigateToDetail(props) }}>{props.date!.toLocaleDateString(/* i18n.language */)}</a>

        // </div >);
    }
    // function gridTemplate(props: GetDailyRecordsForUserDTO) {
    //     if (permissions && permissions.canViewDiaryRecordsDetail)
    //         return (<div>
    //             <a style={{ color: theme.palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { navigateToDetail(props) }}>{props.date!.toLocaleDateString(i18n.language)}</a>

    //         </div >);
    //     else return <div>{props.date!.toLocaleDateString(i18n.language)}</div>
    // }
    function cellTemplatePhotos(props: GetDailyRecordsForUserDTO) {
        if (props.hasPhotos !== undefined && props.hasPhotos === true)
            return (<PhotoCamera />);
        else return (<div />);
        //else return (<CloseIcon />);

    }
    function headerTemplateLock(props: GetDailyRecordsForUserDTO) {
        return (<Lock />);

    }
    function cellTemplateLock(props: GetDailyRecordsForUserDTO) {
        if (props.isLocked !== undefined && props.isLocked === true)
            return (<Lock color='action' />);
        else return (<div></div>);

    }
    function cellTemplateWorkers(props: GetDailyRecordsForUserDTO) {
        if (props.hasWorkerRecords !== undefined && props.hasWorkerRecords === true)
            return (<Engineering />);
        else return (<div />);
        //else return (<CloseIcon />);

    }
    function cellTemplateWorks(props: GetDailyRecordsForUserDTO) {
        if (props.hasCompletedWorksRecords !== undefined && props.hasCompletedWorksRecords === true)
            return (<Construction />);
        else return (<div />);
        //else return (<CloseIcon />);

    }
    function cellTemplateWeathers(props: GetDailyRecordsForUserDTO) {
        if (props.hasWeatherRecords !== undefined && props.hasWeatherRecords === true)
            return (<Cloud />);
        else return (<div />);
        //else return (<CloseIcon />);
    }
    function cellTemplateAttachment(props: GetDailyRecordsForUserDTO) {
        if (props.hasAttachment !== undefined && props.hasAttachment === true)
            return (<AttachFile />);
        else return (<div />);
        //else return (<CloseIcon />);
    }
    function pdfTemplate(props: GetDailyRecordsForUserDTO) {
        if (props.signedPdfPath === undefined || props.signedPdfPath === null || props.signedPdfPath === '') {

            return <div></div>;
        }
        else {
            return <Tooltip enterTouchDelay={0} title={t('diary.DigitallySigned')}>
                <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const link = document.createElement('a');
                    link.href = props.signedPdfPath!;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    try {
                        document.body.removeChild(link);
                    } catch (e) {
                        console.log(e);
                    }
                }}
                    style={{
                        cursor: 'pointer', width: '20px', height: '20px', background: theme.palette.success.main, borderRadius: '20px', boxShadow: 'rgb(0 0 0 / 40%) 1px 1px 10px'
                    }
                    }>
                    <Check style={{ width: '16px', height: '16px', color: 'white', marginLeft: '1px', marginTop: '2px' }} />
                </div>
            </Tooltip>
        }
    }
    function gridTemplateSignatures(props: GetDailyRecordsForUserDTO) {
        if (props.signatures)
            return <div > {props.signatures!.map((item) => {
                return <div key={item.id?.toString()} style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}>
                    <div style={{
                        backgroundColor: item.isInvestor !== undefined && item.isInvestor === true ? theme.palette.success.main : ((item.isSubcontractor === undefined || item.isSubcontractor === false) ? theme.palette.primary.main : '#F5F5F5'),
                        zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 1px 3px 1px', margin: '0px 4px'
                    }} >
                        <Typography style={{ fontSize: '10px', fontWeight: 'bold', margin: '0px 6px' }}>{item.name}</Typography>
                    </div>
                </div>
            })}</div>;
        else
            return (<div>

            </div>);
    }
    function gridTemplateSignedPdfAuthor(props: GetDailyRecordsForUserDTO) {
        if (props.signedPdfAuthor)
            return (<div style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}><div style={{
                backgroundColor: props.isSignedPdfAuthorInvestor ? theme.palette.success.main : theme.palette.primary.main,
                zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 1px 3px 1px', margin: '0px 4px'
            }} >
                <Typography style={{ fontSize: '10px', fontWeight: 'bold', margin: '0px 6px' }}>{props.signedPdfAuthor}</Typography>
            </div></div>);
        else
            return (<div>

            </div>);
    }
    function navigateToDetail(data: GetDailyRecordsForUserDTO) {
        if (props.isSubdodavatel) {
            navigate(
                { pathname: '/' + i18n.language + '/' + params['domain']! + '/subcontractor/main/diaries/' + data.id!.toString(), },
                { state: { id: data.id, inputRecords: records, diaryId: data.diaryId, diaryName: data.diaryName, buildingName: data.buildingName } }
            );
        }
        else {
            if (props.fromExternal === true) {
                navigate(
                    { pathname: '/' + i18n.language + '/' + params['domain']! + '/diaryolderpnew/' + data.id!.toString(), },
                    { state: { id: data.id, inputRecords: records, diaryId: data.diaryId, diaryName: data.diaryName, buildingName: data.buildingName } }
                );
            }
            else {
                navigate(
                    { pathname: '/' + i18n.language + '/' + params['domain']! + '/userlayout/diary/diaryvovdetail/' + data.id!.toString(), },
                    // { state: { id: data.id, inputRecords: records, diaryId: data.diaryId, diaryName: data.diaryName, buildingName: data.buildingName } }
                );
            }
        }
    }

    // useEffect(() => {

    //     if (records === null)
    //         refresh();
    // }, []);
    const navigateBack = useCallback(() => {
        navigate(`/${i18n.language}/${params['domain']!}/userlayout/diary/diarymobile`);
    }, [i18n.language, navigate, params]);

    useEffect(() => {
        setBackMenuFunction(undefined);
        setMenuButtons(
            <Stack direction='row' mr={2} alignItems={'center'} spacing={2}>
                <Tooltip title={t('SwitchView')} enterTouchDelay={0}>
                    <Button size='small' onClick={navigateBack}><SmartphoneIcon sx={{ color: 'black' }} /></Button>
                </Tooltip>
                {permissions && permissions.canCreateDiaryRecord && <Button
                    size='small'
                    sx={{ mr: 2 }}
                    startIcon={<AddCircleOutline />}
                    onClick={() => setNewOpen(true)} variant='contained'>{t('Add')}</Button>}
            </Stack>
        );

    }, [setBackMenuFunction, setMenuButtons, permissions, t]);
    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }
    const excelQueryCellInfo = (args: ExcelQueryCellInfoEventArgs): void => {
        if ((args.column as Column).field === 'hasPhotos') {
            console.log(args.data);
            if ((args.data as GetDailyRecordsForUserDTO).hasPhotos) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'isLocked') {
            if ((args.data as GetDailyRecordsForUserDTO).isLocked) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'hasCompletedWorksRecords') {
            if ((args.data as GetDailyRecordsForUserDTO).hasCompletedWorksRecords) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'hasWeatherRecords') {
            if ((args.data as GetDailyRecordsForUserDTO).hasWeatherRecords) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'hasWorkerRecords') {
            if ((args.data as GetDailyRecordsForUserDTO).hasWorkerRecords) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'hasClientSigned') {
            if ((args.data as GetDailyRecordsForUserDTO).hasClientSigned) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'signatures') {
            var striny = (args.data as GetDailyRecordsForUserDTO).signatures!.map((item) => {
                return item.name;
            });
            args.value = striny.join(', ');
        }
    }
    // function refresh() {
    //     // setRecords(null);
    //     // setLoading(true);
    //     // new ApiDiaryBaseHelper(props.isSubdodavatel == undefined ? false : props.isSubdodavatel, false).GetDiaryRecords(false).then((r) => {
    //     //     setRecords(r);
    //     //     setLoading(false);
    //     // });
    // }

    if (records !== null)
        return <Box sx={{ backgroundColor: '#FFFFFF', padding: '20px', borderRadius: '10px', borderTopLeftRadius: '0px', maxWidth: '88vw', boxShadow: 3 }}>
            <UpperMenu moduleName={t('diary.dailyRecord')}
                gridType={GridType.Users}
                gridId='grid-component-daily-recordstest-new'
                canTrash
                // canAdd={permissions ? permissions.canCreateDiaryRecord : false}
                canDelete={permissions ? permissions.canDeleteDiaryRecord : false}
                // onCreate={(e) => {  }}
                onDelete={(e) => { new ApiDiaryBaseHelper(props.isSubdodavatel === undefined ? false : props.isSubdodavatel, false).DeleteDiaryRecords(e as number[]).then((e) => { if (e) refreshDailyRecords(); }) }}
                canChangeEyeView={false}
                onThrashClicked={() => { setTrashOpen(true); }}
                grid={grid} canChangeGroup canToolbarGrid
                menuName={t('View')}
                isInvestor={false}
                isSubdodavatel={props.isSubdodavatel}
                fromExternal={props.fromExternal}
                canDiarySearch={true}
                searchLabel={t('SearchInRecords')}
                canExcelExport
            />
            <div>
                <GridComponent ref={grid} id="grid-component-daily-recordstest-new" allowSorting={true}
                    allowReordering={true} allowResizing={true} showColumnChooser={true}
                    allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                    locale={i18n.language} enablePersistence={true}
                    excelQueryCellInfo={excelQueryCellInfo}
                    recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                        if (permissions)
                            navigateToDetail(e.rowData as GetDailyRecordsForUserDTO)
                    }}
                    filterSettings={filterOptions}
                    allowPaging={true}
                    style={{ backgroundColor: '#F5F5F5' }}
                    pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                    toolbarClick={toolbarClickExcel}
                    //  columnDataStateChange={(e) => { console.log(e) }}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                    rowHeight={30}
                    allowFiltering={true} groupSettings={groupOptions}
                    /* dataSource={records} */>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='50' minWidth='50' showInColumnChooser={false} />
                        <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} showInColumnChooser={false} />
                        <ColumnDirective field='date' type='date' format={i18n.language === 'en' ? 'MM/dd/yyyy' : 'dd.MM.yyyy'} template={GridTemplate} headerText={t('Date')} width='100' minWidth='100' />
                        <ColumnDirective field='buildingName' headerText={t('Projekt')} width='100' minWidth='100' />
                        <ColumnDirective field='diaryName' headerText={t('diary.diary')} width='100' minWidth='100' />
                        <ColumnDirective field='mainDiaryName' headerText={t('diary.mainDiary')} width='100' minWidth='100' />
                        {/*  <ColumnDirective field='signedPdfPath' headerText={'Pdf'} template={pdfTemplate} width='60' minWidth='60' /> */}
                        <ColumnDirective field='signedPdfAuthor' headerText={t('digitalSignature')} template={gridTemplateSignedPdfAuthor} width='160' minWidth='160' />
                        <ColumnDirective field='hasClientSigned' visible={false} displayAsCheckBox headerText={t('diary.signByInvestor')} width='80' minWidth='80' />
                        <ColumnDirective field='signatures' template={gridTemplateSignatures} headerText={t('diary.signatures')} width='120' minWidth='120' />
                        <ColumnDirective field='hasCompletedWorksRecords' type='boolean' template={cellTemplateWorks} headerText={t('WorksRecord')} width='85' minWidth='85' />
                        <ColumnDirective field='hasPhotos' type='boolean' template={cellTemplatePhotos} headerText={t('dashboard.photos')} width='85' minWidth='85' />
                        <ColumnDirective field='HasAttachment' type='boolean' template={cellTemplateAttachment} headerText={t('Attachments')} width='85' minWidth='85' />
                        <ColumnDirective field='hasWeatherRecords' type='boolean' template={cellTemplateWeathers} headerText={t('dashboard.weather')} width='85' minWidth='85' />
                        <ColumnDirective field='hasWorkerRecords' type='boolean' template={cellTemplateWorkers} headerText={t('diary.humans')} width='85' minWidth='85' />
                        <ColumnDirective field='isLocked' headerTemplate={headerTemplateLock} template={cellTemplateLock} headerText={t('diary.locked')} width='110' minWidth='110' />

                    </ColumnsDirective>
                    <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                </GridComponent>
            </div>
            <div>
                <CreateDiaryRecord isSubdodavatel={props.isSubdodavatel} diaryRecords={records} open={newOpen} onClose={(e, newId) => {
                    setNewOpen(false);
                    // if (e) {
                    // if (newId === undefined) příprava na refreshe, které jsou víc "lightweight"
                    refreshDailyRecords();
                    // else
                    // }
                }} />
                <TrashGridDialog isSubdodavatel={props.isSubdodavatel} trashType={TrashType.DailyRecords} open={trashOpen} onClose={(e) => { console.log(e); setTrashOpen(false); if (e) refreshDailyRecords(); }} />

            </div></Box>

    return <Box sx={{ backgroundColor: '#FFFFFF', padding: '20px', borderRadius: '10px', borderTopLeftRadius: '0px', display: "flex", flexDirection: 'column' }}>{loadingDailyRecords ? t('loading...') : t('noRecords')}</Box>;
}